import React, { useCallback, useMemo, useState } from 'react';
import moment from 'moment';
import {
  Button,
  Center,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Select,
  Textarea,
} from '@chakra-ui/react';
import { Field, Form, Formik } from 'formik';

import Header from '../../layout/Header/Header';
import { showNotification, Messages } from '../../components/Notification';
import { sendTestGiftApi } from '../../apis';
import Container from '../../components/Container';
import SectionContainer from '../../components/SectionContainer';
import FormHeading from '../../components/FormHeading';
import FormStack from '../../components/FormStack';

function TestGiftCard(): React.ReactElement {
  const [timestamp, setTimestamp] = useState<number>(moment().unix());

  const initialValues = useMemo(
    () => ({
      wooCommerceId: `woo_id-test-${timestamp}`,
      stripeTransactionId: `stripe_id-test-${timestamp}`,
      senderName: 'Test Sender',
      recipientName: 'Test Recipient',
      senderEmail: '',
      recipientEmail: '',
      giftCardTypeId: 8,
      giftCardAmount: 1000,
      giftCardCurrency: 'GBP',
      giftCardExperienceLink: `https://wealthie.works/exeperience/${timestamp}`,
      message: `This is a test message when creating gift card - ${timestamp}`,
      transactionTimestamp: timestamp,
      childName: 'Child Name One',
      parentPhoneNumber: '+15551231256',
      giftCardGameName: 'dino-egg',
    }),
    [timestamp],
  );

  const onSubmit = useCallback(
    async (values) => {
      try {
        const typedValues = Object.assign({}, { ...values });
        typedValues.giftCardTypeId = Number(typedValues.giftCardTypeId);
        typedValues.giftCardAmount = Number(typedValues.giftCardAmount);
        await sendTestGiftApi(typedValues);
        showNotification({
          type: 'success',
          message: 'Successfully sent test giftcard!',
        });
        setTimestamp(moment().unix());
      } catch (error) {
        showNotification({
          type: 'error',
          message: Messages.somethingWentWrong,
        });
      }
    },
    [setTimestamp],
  );

  return (
    <Container>
      <Header showNav={false} />
      <SectionContainer>
        <FormHeading title="Test gift card" />
        <p>
          Fill out the form below to send a test gift card into the
          system.&nbsp;
          <strong>
            Please make sure that the recipient email address is valid otherwise
            it will negatively impact the rating of the email system.
          </strong>
        </p>
        <br />
        <p>
          The default values are restored after each send and random values are
          added to transaction and wooCommerceIds ensure uniqueness.
        </p>
        <br />
      </SectionContainer>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {(formik) => (
          <Form>
            <FormStack>
              <Field name="wooCommerceId">
                {({ field, form }) => (
                  <FormControl
                    isInvalid={
                      form.errors.wooCommerceId && form.touched.wooCommerceId
                    }
                  >
                    <FormLabel htmlFor="wooCommerceId">wooCommerceId</FormLabel>
                    <Input
                      {...field}
                      id="wooCommerceId-id"
                      type="text"
                      placeholder="wooCommerceId"
                      required
                    />
                    <FormErrorMessage>
                      {form.errors.wooCommerceId}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="stripeTransactionId">
                {({ field, form }) => (
                  <FormControl
                    isInvalid={
                      form.errors.stripeTransactionId &&
                      form.touched.stripeTransactionId
                    }
                  >
                    <FormLabel htmlFor="stripeTransactionId">
                      stripeTransactionId
                    </FormLabel>
                    <Input
                      {...field}
                      id="stripeTransactionId-id"
                      type="text"
                      placeholder="stripeTransactionId"
                      required
                    />
                    <FormErrorMessage>
                      {form.errors.stripeTransactionId}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="senderName">
                {({ field, form }) => (
                  <FormControl
                    isInvalid={
                      form.errors.senderName && form.touched.senderName
                    }
                  >
                    <FormLabel htmlFor="senderName">senderName</FormLabel>
                    <Input
                      {...field}
                      id="senderName-id"
                      type="text"
                      placeholder="senderName"
                      required
                    />
                    <FormErrorMessage>
                      {form.errors.senderName}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="recipientName">
                {({ field, form }) => (
                  <FormControl
                    isInvalid={
                      form.errors.recipientName && form.touched.recipientName
                    }
                  >
                    <FormLabel htmlFor="recipientName">recipientName</FormLabel>
                    <Input
                      {...field}
                      id="recipientName-id"
                      type="text"
                      placeholder="recipientName"
                      required
                    />
                    <FormErrorMessage>
                      {form.errors.recipientName}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Field name="senderEmail">
                {({ field, form }) => (
                  <FormControl
                    isInvalid={
                      form.errors.senderEmail && form.touched.senderEmail
                    }
                  >
                    <FormLabel htmlFor="senderEmail">senderEmail</FormLabel>
                    <Input
                      {...field}
                      id="senderEmail-id"
                      type="email"
                      autoComplete="email"
                      placeholder="senderEmail"
                      required
                    />
                    <FormErrorMessage>
                      {form.errors.senderEmail}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Field name="recipientEmail">
                {({ field, form }) => (
                  <FormControl
                    isInvalid={
                      form.errors.recipientEmail && form.touched.recipientEmail
                    }
                  >
                    <FormLabel htmlFor="recipientEmail">
                      recipientEmail
                    </FormLabel>
                    <Input
                      {...field}
                      id="recipientEmail-id"
                      type="email"
                      autoComplete="email"
                      placeholder="recipientEmail"
                      required
                    />
                    <FormErrorMessage>
                      {form.errors.recipientEmail}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Field name="giftCardTypeId">
                {({ field, form }) => (
                  <FormControl
                    isInvalid={
                      form.errors.giftCardTypeId && form.touched.giftCardTypeId
                    }
                  >
                    <FormLabel htmlFor="giftCardTypeId">
                      giftCardTypeId
                    </FormLabel>
                    <Input
                      {...field}
                      id="giftCardTypeId-id"
                      type="number"
                      placeholder="giftCardTypeId"
                      required
                    />
                    <FormErrorMessage>
                      {form.errors.giftCardTypeId}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="giftCardAmount">
                {({ field, form }) => (
                  <FormControl
                    isInvalid={
                      form.errors.giftCardAmount && form.touched.giftCardAmount
                    }
                  >
                    <FormLabel htmlFor="giftCardAmount">
                      giftCardAmount
                    </FormLabel>
                    <Input
                      {...field}
                      id="giftCardAmount-id"
                      type="number"
                      placeholder="giftCardAmount"
                      required
                    />
                    <FormErrorMessage>
                      {form.errors.giftCardAmount}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="giftCardCurrency">
                {({ field, form }) => (
                  <FormControl
                    isInvalid={
                      form.errors.giftCardCurrency &&
                      form.touched.giftCardCurrency
                    }
                  >
                    <FormLabel htmlFor="giftCardCurrency">
                      giftCardCurrency
                    </FormLabel>
                    <Select {...field} id="giftCardCurrency-id" required>
                      <option value="GBP">GBP</option>
                      <option value="EUR">EUR</option>
                      <option value="CAD">CAD</option>
                      <option value="USD">USD</option>
                    </Select>
                    <FormErrorMessage>
                      {form.errors.giftCardAmount}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="giftCardExperienceLink">
                {({ field, form }) => (
                  <FormControl
                    isInvalid={
                      form.errors.giftCardExperienceLink &&
                      form.touched.giftCardExperienceLink
                    }
                  >
                    <FormLabel htmlFor="giftCardExperienceLink">
                      giftCardExperienceLink
                    </FormLabel>
                    <Input
                      {...field}
                      id="giftCardExperienceLink-id"
                      type="text"
                      placeholder="giftCardExperienceLink"
                      required
                    />
                    <FormErrorMessage>
                      {form.errors.giftCardExperienceLink}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="giftCardGameName">
                {({ field, form }) => (
                  <FormControl
                    isInvalid={
                      form.errors.giftCardGameName &&
                      form.touched.giftCardGameName
                    }
                  >
                    <FormLabel htmlFor="giftCardGameName">
                      giftCardGameName
                    </FormLabel>
                    <Input
                      {...field}
                      id="giftCardGameName-id"
                      type="text"
                      placeholder="giftCardGameName"
                      required
                    />
                    <FormErrorMessage>
                      {form.errors.giftCardExperienceLink}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="message">
                {({ field, form }) => (
                  <FormControl
                    isInvalid={form.errors.message && form.touched.message}
                  >
                    <FormLabel htmlFor="message">message</FormLabel>
                    <Textarea
                      {...field}
                      id="message-id"
                      type="text"
                      placeholder="message"
                      required
                    />
                    <FormErrorMessage>{form.errors.message}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="childName">
                {({ field, form }) => (
                  <FormControl
                    isInvalid={form.errors.childName && form.touched.childName}
                  >
                    <FormLabel htmlFor="childName">childName</FormLabel>
                    <Input
                      {...field}
                      id="childName-id"
                      type="text"
                      placeholder="childName"
                      required
                    />
                    <FormErrorMessage>{form.errors.childName}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="transactionTimestamp">
                {({ field, form }) => (
                  <FormControl
                    isInvalid={
                      form.errors.transactionTimestamp &&
                      form.touched.transactionTimestamp
                    }
                  >
                    <FormLabel htmlFor="transactionTimestamp">
                      transactionTimestamp
                    </FormLabel>
                    <Input
                      {...field}
                      id="transactionTimestamp-id"
                      type="text"
                      placeholder="transactionTimestamp"
                      required
                    />
                    <FormErrorMessage>
                      {form.errors.transactionTimestamp}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Center>
                <Button
                  isLoading={formik.isSubmitting}
                  disabled={!formik.isValid}
                  type="submit"
                >
                  Send Test Giftcard
                </Button>
              </Center>
            </FormStack>
          </Form>
        )}
      </Formik>
    </Container>
    // <div data-testid="test_gift_card" className={styles.testWrapper}>
    //   <HeaderLanding showNav={false} />
    //   <div className={styles.centerWrapper}>
    //   </div>
    //   <div className={styles.gift_cards_wrapper}>
    //     <Form
    //       initialValues={initialValues}
    //       onFinish={onFinish}
    //       onFinishFailed={onFinishFailed}
    //       form={form}
    //       validateMessages={VALIDATE_MESSAGES}
    //       {...LAYOUT_0_24}
    //       data-testid="test_gift_card"
    //     >
    //       <Form.Item
    //         label="Woocommerce Id"
    //         rules={[{ required: true }]}
    //         name="wooCommerceId"
    //       >
    //         <Input
    //           label="wooCommerceId"
    //           aria-label="woocommercerId"
    //           placeholder="Woocommerce Id"
    //         />
    //       </Form.Item>
    //       <Form.Item
    //         label="Stripe TransactionId"
    //         name="stripeTransactionId"
    //         rules={[{ required: true }]}
    //       >
    //         <Input
    //           label="stripeTransactionId"
    //           aria-label="fName-input"
    //           placeholder="Stripe TransactionId"
    //         />
    //       </Form.Item>
    //       <Form.Item
    //         label="Sender Name"
    //         name="senderName"
    //         rules={[{ required: true }]}
    //       >
    //         <Input
    //           aria-label="lName-input"
    //           placeholder="Sender Name"
    //           label="senderName"
    //         />
    //       </Form.Item>
    //       <Form.Item
    //         label="Sender Name"
    //         name="recipientName"
    //         rules={[{ required: true }]}
    //       >
    //         <Input
    //           aria-label="lName-input"
    //           placeholder="Sender Name"
    //           label="recipientName"
    //         />
    //       </Form.Item>
    //       <Form.Item
    //         label="Sender Email"
    //         name="senderEmail"
    //         rules={[{ required: true }]}
    //       >
    //         <Input
    //           aria-label="lName-input"
    //           placeholder="Sender Email"
    //           label="senderEmail"
    //         />
    //       </Form.Item>
    //       <Form.Item
    //         label="Recipient Email"
    //         name="recipientEmail"
    //         rules={[{ required: true }]}
    //       >
    //         <Input
    //           aria-label="lName-input"
    //           placeholder="Recipient Email"
    //           label="recipientEmail"
    //         />
    //       </Form.Item>

    //       <Form.Item
    //         label="Gift Card Type Id"
    //         name="giftCardTypeId"
    //         rules={[{ required: true }]}
    //       >
    //         <Input
    //           type="number"
    //           aria-label="lName-input"
    //           placeholder="Gift Card Type Id"
    //           label="giftCardTypeId"
    //         />
    //       </Form.Item>

    //       <Form.Item
    //         label="Gift Card Amount"
    //         name="giftCardAmount"
    //         rules={[{ required: true }]}
    //       >
    //         <Input
    //           type="number"
    //           aria-label="lName-input"
    //           placeholder="Gift Card Amount"
    //           label="giftCardAmount"
    //         />
    //       </Form.Item>

    //       <label>giftCardCurrency</label>
    //       <Form.Item
    //         label="Gift Card Currency"
    //         name="giftCardCurrency"
    //         rules={[{ required: true }]}
    //       >
    //         <Select defaultValue="GBP">
    //           <option value="GBP">GBP</option>
    //           <option value="EUR">EUR</option>
    //           <option value="CAD">CAD</option>
    //           <option value="USD">USD</option>
    //         </Select>
    //       </Form.Item>

    //       <Form.Item
    //         label="Gift Card Experience Link"
    //         name="giftCardExperienceLink"
    //         rules={[{ required: true }]}
    //       >
    //         <Input
    //           aria-label="lName-input"
    //           placeholder="Gift Card Experience Link"
    //           label="giftCardExperienceLink"
    //         />
    //       </Form.Item>

    //       <Form.Item
    //         name="message"
    //         label="Message"
    //         rules={[{ required: true }]}
    //       >
    //         <TextAreaInput
    //           aria-label="lName-input"
    //           placeholder="Message"
    //           label="message"
    //         />
    //       </Form.Item>
    //       <Form.Item
    //         label="Child Name"
    //         name="childName"
    //         rules={[{ required: true }]}
    //       >
    //         <Input
    //           aria-label="lName-input"
    //           placeholder="ChildName"
    //           label="childName"
    //         />
    //       </Form.Item>

    //       <Form.Item name="transactionTimestamp" rules={[{ required: true }]}>
    //         <Input
    //           label="transactionTimestamp"
    //           disabled
    //           aria-label="lName-input"
    //         />
    //       </Form.Item>
    //       <Form.Item name="parentPhoneNumber" rules={[{ required: true }]}>
    //         <Input
    //           aria-label="lName-input"
    //           placeholder="Parent Phone Number"
    //           label="parentPhoneNumber"
    //         />
    //       </Form.Item>

    //       <Form.Item className={styles.sub_button}>
    //         <Button htmlType="submit" shape="circle" data-testid="save_button">
    //           SEND TEST GIFTCARD
    //         </Button>
    //       </Form.Item>
    //     </Form>
    //   </div>
    // </div>
  );
}

export default TestGiftCard;
