import avatar0 from '../../assets/images/avatars/AvatarKids-01.png';
import avatar1 from '../../assets/images/avatars/AvatarKids-02.png';
import avatar2 from '../../assets/images/avatars/AvatarKids-03.png';
import avatar3 from '../../assets/images/avatars/AvatarKids-04.png';
import avatar4 from '../../assets/images/avatars/AvatarKids-05.png';
import avatar5 from '../../assets/images/avatars/AvatarKids-06.png';
import avatar6 from '../../assets/images/avatars/AvatarKids-07.png';
import avatar7 from '../../assets/images/avatars/AvatarKids-08.png';
import avatar8 from '../../assets/images/avatars/AvatarKids-09.png';
import avatar9 from '../../assets/images/avatars/AvatarKids-10.png';
import avatar10 from '../../assets/images/avatars/AvatarKids-11.png';
import avatar11 from '../../assets/images/avatars/AvatarKids-12.png';
import avatar12 from '../../assets/images/avatars/AvatarKids-13.png';
import avatar13 from '../../assets/images/avatars/AvatarKids-14.png';
import avatar14 from '../../assets/images/avatars/AvatarKids-15.png';
import avatar15 from '../../assets/images/avatars/AvatarKids-16.png';
import avatar16 from '../../assets/images/avatars/AvatarKids-17.png';
import avatar17 from '../../assets/images/avatars/AvatarKids-18.png';
import avatar18 from '../../assets/images/avatars/AvatarKids-19.png';
import avatar19 from '../../assets/images/avatars/AvatarKids-20.png';

const AvatarIcon = {
  avatar0,
  avatar1,
  avatar2,
  avatar3,
  avatar4,
  avatar5,
  avatar6,
  avatar7,
  avatar8,
  avatar9,
  avatar10,
  avatar11,
  avatar12,
  avatar13,
  avatar14,
  avatar15,
  avatar16,
  avatar17,
  avatar18,
  avatar19,
  length: 20,
  resolveAvatar: (id: number): string =>
    id <= AvatarIcon.length
      ? AvatarIcon[`avatar${id - 1}`]
      : AvatarIcon.avatar0,
};

export default AvatarIcon;
