import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { get } from 'lodash';
import * as Yup from 'yup';
import {
  Button,
  Center,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Image,
  Heading,
} from '@chakra-ui/react';
import { Formik, Form, Field } from 'formik';
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from 'react-google-recaptcha-v3';

import Header from '../../layout/Header/Header';
import {
  Messages,
  showNotification,
  ERROR_TYPE,
} from '../../components/Notification';
import Container from '../../components/Container';
import { parentSignupApi, reCaptchaKey } from '../../apis';
import PrivacyPolicyLink from '../../components/PrivacyPolicyLink/PrivacyPolicyLink';
import FormStack from '../../components/FormStack';
import ActivityIndicator from '../../components/ActivityIndicator';
import { useDispatch } from 'react-redux';
import { loginWealthie } from '../../store/reducers/authSlice';
import { passwordValidationRequired } from '../../lib/passwordValidation';
import FormDOBInput from '../../components/FormDOBInput';
import { dobFieldsValidation, dobFromFields } from '../../lib/dobValidation';
import { emailValidationRequired } from '../../lib/emailValidation';
import PasswordInput from '../../components/PasswordInput';
import WelcomeImage from '../../assets/images/badges/welcome.png';
import BodyContainer from '../../components/BodyContainer';
import InfoPasswordLabel from '../../components/InfoPasswordLabel/InfoPasswordLabel';
import { trackParentSignup } from '../../lib/analytics';
import WealthieHelmet from '../../components/WealthieHelmet';

const SignupSchema = Yup.object().shape({
  firstName: Yup.string().trim().required('Required'),
  lastName: Yup.string().trim().required('Required'),
  ...emailValidationRequired,
  ...passwordValidationRequired,
  ...dobFieldsValidation,
});

function Register(): React.ReactElement {
  const history = useHistory();
  const [isSaving, setIsSaving] = useState(false);
  const dispatch = useDispatch();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const onSubmit = async (values: any, actions: any) => {
    try {
      const dob = dobFromFields(values.day, values.month, values.year);
      if (!dob) {
        actions.setErrors({
          day: ' ',
          month: 'must be a valid date',
          year: ' ',
        });
        return false;
      }

      if (!executeRecaptcha) {
        actions.setErrors({
          firstName: 'Error with form.  Please reload and try again',
        });
        setIsSaving(false);
        return false;
      }
      const recaptchaToken = await executeRecaptcha('parentSignup');

      setIsSaving(true);
      const response = await parentSignupApi({
        ...values,
        dob,
        token: recaptchaToken,
      });
      setIsSaving(false);
      if (response) {
        showNotification({
          type: 'success',
          message: Messages.registeredSuccessfully,
        });
        trackParentSignup(response.data.parentId);
        // we now automatically log the user in, instead of asking them to
        dispatch(loginWealthie(values.email, values.password, history));
      } else {
        showNotification({
          type: ERROR_TYPE,
          message: Messages.somethingWentWrong,
        });
      }
    } catch (error) {
      setIsSaving(false);
      const message = get(
        error,
        'response.data.message',
        Messages.somethingWentWrong,
      );
      showNotification({
        type: ERROR_TYPE,
        message,
      });
    }
  };

  return (
    <Container data-testid="parent_register_page">
      <WealthieHelmet title="Register" />
      <Header />
      {/* <SectionContainer>
        <FormHeading
          title="Welcome to Wealthie!"
          text="Please create your account."
        />
      </SectionContainer> */}
      <Center marginBottom="30px" flexDir="column" gap="20px">
        <Image src={WelcomeImage} alt="Welcome Badge" width="179px" />
        <Heading
          as="h1"
          fontSize="largeText"
          fontWeight="largeText"
          lineHeight="title"
        >
          Create Parent Account
        </Heading>
      </Center>
      <BodyContainer>
        <Formik
          initialValues={{
            email: '',
            emailRepeat: '',
            firstName: '',
            lastName: '',
            day: '',
            month: '',
            year: '',
            password: '',
            passwordRepeat: '',
          }}
          onSubmit={onSubmit}
          validationSchema={SignupSchema}
        >
          {(formik) => (
            <Form>
              <FormStack data-testid="parent_register_form">
                <Field name="firstName">
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={
                        form.errors.firstName && form.touched.firstName
                      }
                    >
                      <FormLabel htmlFor="firstName">First Name</FormLabel>
                      <Input
                        {...field}
                        id="firstname-id"
                        data-testid="firstname-id"
                        type="text"
                        autoComplete="given-name"
                        // placeholder="First Name"
                      />
                      <FormErrorMessage>
                        {form.errors.firstName}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="lastName">
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={form.errors.lastName && form.touched.lastName}
                    >
                      <FormLabel htmlFor="lastName">Last Name</FormLabel>
                      <Input
                        {...field}
                        id="lastname-id"
                        data-testid="lastname-id"
                        type="text"
                        autoComplete="family-name"
                        placeholder="Last Name"
                      />
                      {/* <FormInput
                      label="Last Name"
                      formLabelProps={{
                        htmlFor: 'lastName',
                      }}
                      inputProps={{
                        ...field,
                        id: 'lastname-id',
                        type: 'text',
                        autoComplete: 'family-name',
                      }}
                    /> */}
                      <FormErrorMessage>
                        {form.errors.lastName}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="email">
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={form.errors.email && form.touched.email}
                    >
                      <FormLabel htmlFor="email">Email Address</FormLabel>
                      <Input
                        {...field}
                        id="email-id"
                        type="email"
                        data-testid="email-id"
                        // placeholder="e.g., gmail"
                      />
                      <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="emailRepeat">
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={
                        form.errors.emailRepeat && form.touched.emailRepeat
                      }
                    >
                      <FormLabel htmlFor="emailRepeat">
                        Confirm Email Address
                      </FormLabel>
                      <Input
                        {...field}
                        id="emailRepeat-id"
                        data-testid="emailRepeat-id"
                        type="email"
                        // placeholder="e.g., gmail"
                      />
                      <FormErrorMessage>
                        {form.errors.emailRepeat}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <FormDOBInput />
                <Field name="password">
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={form.errors.password && form.touched.password}
                    >
                      <InfoPasswordLabel />
                      <PasswordInput
                        {...field}
                        id="password-id"
                        autoComplete="new-password"
                        data-testid="password-id"
                      />
                      <FormErrorMessage>
                        {form.errors.password}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="passwordRepeat">
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={
                        form.errors.passwordRepeat &&
                        form.touched.passwordRepeat
                      }
                    >
                      <FormLabel htmlFor="passwordRepeat">
                        Confirm Password
                      </FormLabel>
                      <PasswordInput
                        {...field}
                        id="passwordRepeat-id"
                        data-testid="passwordRepeat-id"
                        autoComplete="new-password"
                        // placeholder="password"
                      />
                      <FormErrorMessage>
                        {form.errors.passwordRepeat}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Center>
                  <Button
                    isLoading={formik.isSubmitting}
                    // disabled={!formik.isValid}
                    type="submit"
                    variant="actionSmall"
                    colorScheme="accent"
                    data-testid="submit-button"
                    marginTop="30px"
                  >
                    CREATE MY ACCOUNT
                  </Button>
                </Center>
              </FormStack>
            </Form>
          )}
        </Formik>
        <Center marginY="50px" data-testid="privacy-policy-link">
          <PrivacyPolicyLink />
        </Center>
      </BodyContainer>
      {isSaving && <ActivityIndicator tip="Working" />}
    </Container>
  );
}

const RegisterWrapper = (props: any): React.ReactElement => (
  <GoogleReCaptchaProvider reCaptchaKey={reCaptchaKey}>
    <Register {...props} />
  </GoogleReCaptchaProvider>
);

export default RegisterWrapper;
