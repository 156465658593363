import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { get } from 'lodash';

import { RootState } from '../../store/reducers';
import { isKidAuthSelector } from '../../store/reducers/authSlice';
import { setAnalyticsUserProperties } from '../../lib/analytics';

interface PrivateRouteProps {
  component?: any;
  path: string;
  exact: boolean;
  isParentOnly?: boolean;
  // special route for verification will be allowed with both private and public route.
  isVerifyRoute?: boolean;
}

const defaultRoute = '/home';

const PrivateRoute = ({
  component: Component,
  path,
  exact,
  isVerifyRoute,
  isParentOnly = true,
  ...rest
}: PrivateRouteProps): React.ReactElement => {
  const isAuthenticated = useSelector((state) =>
    get(state, 'authReducer.isAuthenticated'),
  );
  const isKid = useSelector((state: RootState) => isKidAuthSelector(state));
  const allowed = !isKid || !isParentOnly;
  const resolvedDefaultRoute = isAuthenticated ? defaultRoute : '/';
  useEffect(() => {
    setAnalyticsUserProperties(isKid ? 'kid' : 'parent');
  }, [isKid]);
  return (
    <Route
      exact={exact}
      {...rest}
      path={path}
      render={(props) =>
        allowed && (isAuthenticated || isVerifyRoute) ? (
          <Component {...props} />
        ) : (
          <Redirect to={resolvedDefaultRoute} />
        )
      }
    />
  );
};

export default React.memo(PrivateRoute);
