import React from 'react';
import {
  NodeContainer,
  NodeContainerSmall,
  NodeHeading,
  NodeHeadingH3,
  NodeModal,
  NodeText,
  NodeVideoPlayer,
  NodeSubHeading,
} from '../NodeComponents';
import { NodeModalProps } from './types';

const age = 13;
const nodeNumber = 0;

const ModalA13N1: React.FunctionComponent<NodeModalProps> = ({
  isOpen,
  onClose,
}) => (
  <NodeModal isOpen={isOpen} onClose={onClose}>
    <NodeVideoPlayer
      url="/assets/timelineModalMedia/WWD-Acct-KidTimeline-Age13-Evil-01:23-N1-Hero.mp4"
      ageId={age}
      nodeNumber={nodeNumber}
      index={0}
    />
    <NodeContainerSmall>
      <NodeHeading>The Cost of Supervillainy</NodeHeading>
      <NodeSubHeading>
        The Straight Economics on Menacing Your Nemeses
      </NodeSubHeading>
    </NodeContainerSmall>
    <NodeContainerSmall>
      <NodeHeadingH3>£4 million: Underground lair</NodeHeadingH3>
      <NodeText>
        An impenetrable mountainside fortress may *look* cool, but good luck
        getting the pizza guy to deliver… Standard advice says you
        shouldn&apos;t spend more than 30% of your income on housing, and
        underground lairs are expensive! A three-bedroom underground house in
        Cheshire, England, recently listed for £4 million. You may need to steal
        a crown jewel or two.
      </NodeText>
    </NodeContainerSmall>
    <NodeVideoPlayer
      url="/assets/timelineModalMedia/WWD-Acct-KidTimeline-Age13-SwivelChair-01:23-N1-1.mp4"
      isGif
      ageId={age}
      nodeNumber={nodeNumber}
      index={1}
    />
    <NodeContainerSmall>
      <NodeHeadingH3>$16,000: Villainous swivel chair</NodeHeadingH3>
      <NodeText>
        The black leather swivel chair, famously used by Bond Villain Ernst
        Blofeld in 1967&apos;s You Only Live Twice, fetched $16,000 at auction
        almost 10 years ago. You can find a vintage version for £1,323.
        It&apos;s probably decent for gaming.
      </NodeText>
    </NodeContainerSmall>
    <NodeContainerSmall>
      <NodeHeadingH3>$163 million: Your own “death ray”</NodeHeadingH3>
      <NodeText>
        That&apos;s the price tag the US Navy shelled out for its
        “megawatt-class laser” superweapon. Doomsday devices don&apos;t come
        cheap, so shop around. And, since we typically buy with our emotions,
        maybe sleep on that death ray purchase before pulling the trigger. (Good
        advice for after you buy, too!)
      </NodeText>
    </NodeContainerSmall>
    <NodeVideoPlayer
      url="/assets/timelineModalMedia/WWD-Acct-KidTimeline-Age13-SharkTank-01:23-N1-2.mp4"
      isGif
      ageId={age}
      nodeNumber={nodeNumber}
      index={2}
    />
    <NodeContainerSmall>
      <NodeHeadingH3>Up to $1MM+: Shark tank</NodeHeadingH3>
      <NodeText>
        Dangling your arch nemesis over a tank of hungry sharks is going to cost
        you an arm and a leg (haha, sorry): anywhere from $15,000 to $1 million.
        This doesn&apos;t even include annual vet check ups for your sharks, or
        the upkeep for saltwater tanks. If you&apos;re villaining on a budget,
        you&apos;ll find a snake pit&apos;s more economical.
      </NodeText>
    </NodeContainerSmall>
    <NodeContainer>
      <NodeText>
        Do the deep work and be honest about the kind of supervillain you want
        to be. It&apos;s a vibe.
      </NodeText>
    </NodeContainer>
  </NodeModal>
);

export default ModalA13N1;
