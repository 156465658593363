import React from 'react';
import { Box, Button, IconButton } from '@chakra-ui/react';
import { TooltipRenderProps } from 'react-joyride';
import { XIcon } from '../../assets/images/headerIcons/XIcon';
import SelectionDot from './SelectionDot';

const TourTooltip = React.forwardRef<HTMLElement, TooltipRenderProps>(
  (
    {
      step,
      isLastStep,
      primaryProps,
      tooltipProps,
      size,
      index,
      skipProps,
      closeProps,
    },
    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
    ref,
  ) => {
    const emptyDotArray: number[] = Array(size).fill(0);

    const dotArray = emptyDotArray.map((value, mapIndex) => {
      if (mapIndex === index) {
        return <SelectionDot key={mapIndex} isSelectedDot />;
      }
      return <SelectionDot key={mapIndex} isSelectedDot={false} />;
    });

    return (
      <Box
        {...tooltipProps}
        bgColor="white"
        maxWidth="347px"
        paddingX="23.5px"
        paddingY="30px"
        borderRadius="20px"
        boxShadow="0px 5px 55px 0px rgba(0, 0, 0, 0.23)"
        backdropFilter="blur(6px)"
        data-testid="tour-tooltip"
      >
        <Box display="flex" justifyContent="end">
          <IconButton
            icon={<XIcon width="22px" height="22px" />}
            // width="50px"
            // height="50px"
            variant="unstyled"
            {...skipProps}
            display="contents"
            aria-label="Close"
          />
        </Box>
        <Box marginBottom="20px" {...primaryProps}>
          {step.content}
        </Box>
        <Box display="flex" justifyContent="space-between" {...primaryProps}>
          <Box display="flex" gap="10px">
            {dotArray}
          </Box>
          <Box>
            {!isLastStep ? (
              <Button
                variant="text"
                {...primaryProps}
                fontSize="smallTitle"
                fontWeight="smallText"
                lineHeight="extraSmallText"
                data-testid="next-button"
              >
                Next
              </Button>
            ) : (
              <Button
                variant="text"
                {...closeProps}
                fontSize="smallTitle"
                fontWeight="smallText"
                lineHeight="extraSmallText"
                data-testid="done-button"
              >
                Done
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    );
  },
);

TourTooltip.displayName = 'TourTooltip';

export default TourTooltip;
