import { ComponentStyleConfig } from '@chakra-ui/react';

const Link: ComponentStyleConfig = {
  // The styles all button have in common
  baseStyle: {
    textDecorationLine: 'underline',
    fontWeight: 400,
    // fontSize: '1.3125rem',
    // lineHeight: '31px',
    // textDecorationColor: ''
  },
  sizes: {},
  variants: {
    menu: {
      fontWeight: 400,
    },
  },
  defaultProps: {},
};

export default Link;
