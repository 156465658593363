import { CallBackProps, EVENTS } from 'react-joyride';
import {
  trackProductTourNext,
  trackProductTourClose,
  trackProductTourComplete,
  trackProductTourStart,
} from './../../lib/analytics';

export const handleJoyrideCallbackAnalytics = (
  data: CallBackProps,
  screenName: string,
): void => {
  const { action, type, index } = data;
  if (action === 'start' && EVENTS?.TOUR_START?.includes(type)) {
    trackProductTourStart(screenName);
  }
  if (EVENTS?.STEP_AFTER?.includes(type)) {
    if (action === 'next') {
      trackProductTourNext(screenName, index + 2);
    }
    // In this context skip means close or the "X" button in the overlay
    if (action === 'skip') {
      trackProductTourClose(screenName, index + 1);
    }
    // In this context close means done
    if (action === 'close') {
      trackProductTourComplete(screenName, index + 1);
    }
  }
};
