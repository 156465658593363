import { Image } from '@chakra-ui/react';
import React from 'react';
import {
  NodeContainer,
  NodeHeading,
  NodeModal,
  NodeText,
  NodeVideoPlayer,
} from '../NodeComponents';
import { NodeModalProps } from './types';

const age = 10;
const nodeNumber = 0;

const ModalA10N1: React.FunctionComponent<NodeModalProps> = ({
  isOpen,
  onClose,
}) => (
  <NodeModal isOpen={isOpen} onClose={onClose}>
    <NodeVideoPlayer
      url="/assets/timelineModalMedia/WWD-Acct-KidTimeline-Age10-Gumball-01:23-N1-Hero.mp4"
      isGif
      ageId={age}
      nodeNumber={nodeNumber}
      index={0}
    />
    <NodeContainer>
      <NodeHeading>Gumball Machine</NodeHeading>
      <NodeText>
        So, you&apos;re thinking it&apos;s time to step out and flex some
        entrepreneurial muscle. You&apos;re looking to go into business for
        yourself. The lemonade stand is over. You&apos;re looking for something
        fresh, something unexpected. How about gumballs? You know that shiny
        glass bubble just past the grocery store checkout? Ever stop to think
        about who owns it? Ever stopped to think about how much money it makes
        for its owner in a day?...
      </NodeText>
    </NodeContainer>
    <Image
      src="/assets/timelineModalMedia/WWD-Acct-KidTimeline-Age10-GumballDots-01:23-N1-1.png"
      alt="polka dots"
    />
    <NodeContainer>
      <NodeText>
        The gumball machine was invented in 1907, but it didn&apos;t start
        popping up around town until the 1930s. Do you know how much it cost for
        a gumball from a machine in 1930? ONE PENNY! That&apos;s right. 1 cent.
        The penny doesn&apos;t even exist anymore, since it costs more to make a
        penny than a penny is worth.
      </NodeText>
    </NodeContainer>
    <Image
      src="/assets/timelineModalMedia/WWD-Acct-KidTimeline-Age10-Coin-01:23-N1-2.png"
      alt="Coin"
    />
    <NodeContainer>
      <NodeText>
        So nothing costs a penny anymore, not even a gumball. These days,
        gumballs usually cost a quarter, which is great news for your new
        business! Let&apos;s take a look at the numbers.{' '}
      </NodeText>
    </NodeContainer>
    <Image
      src="/assets/timelineModalMedia/WWD-Acct-KidTimeline-Age10-Calculator-01:23-N1-3.png"
      alt="Calculator"
    />
    <NodeContainer>
      <NodeText>
        …According to an article in Vending Times Magazine (yes, that&apos;s
        real) in 2008, the national average revenue for gumball machines was
        about $1 per day. That&apos;s about $1.38 in today&apos;s dollars,
        because of inflation. So, you&apos;re earning an average of about $41.40
        a month.
      </NodeText>
      <NodeText>
        if that&apos;s the average, it&apos;s definitely possible to make more,
        but it&apos;s also possible to make less. At the average rate,
        you&apos;ll need to invest in a lot of gumball machines to make enough
        money to buy a house, a car, or even a dog when you&apos;re older.
      </NodeText>
      <NodeText>
        You might be better entering gumball counting contests, where a prize
        goes to the person who guesses the correct number of gumballs in a
        machine…
      </NodeText>
      <NodeText>Get out the quarters and start counting!</NodeText>
    </NodeContainer>
    <Image
      src="/assets/timelineModalMedia/WWD-Acct-KidTimeline-Age10-Dots-01:23-N1-4.png"
      alt="Small Polka Dots"
    />
  </NodeModal>
);

export default ModalA10N1;
