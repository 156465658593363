import React, { useState } from 'react';
import { Image } from '@chakra-ui/react';
import {
  NodeAudio,
  NodeContainer,
  NodeHeading,
  NodeModal,
} from '../NodeComponents';
import { NodeModalProps } from './types';

const age = 5;
const nodeID = 1;

const ModalA5N2: React.FunctionComponent<NodeModalProps> = ({
  isOpen,
  onClose,
}) => {
  const [activeAudioIndex, setActiveAudioIndex] = useState<number | null>(null);
  return (
    <NodeModal isOpen={isOpen} onClose={onClose}>
      {/* <NodeVideoPlayer url="/assets/timelineModalMedia/WWD-Acct-KidTimeline-Age1-Starfish-01:23-N2.mp4" /> */}
      <Image
        src="/assets/timelineModalMedia/WWD-Acct-KidTimeline-Age5-BurtoltAndBee-01:23-N2-Hero.png"
        alt="Podcast Cassette"
      />
      <Image
        src="/assets/timelineModalMedia/WWD-Acct-KidTimeline-Age5-BurtoltAndBee-01:23-N2-1.png"
        alt="Money Tree"
      />
      <NodeContainer>
        <NodeHeading>Episode 1: Does money grow on trees?</NodeHeading>
        <NodeAudio
          src="/assets/timelineModalMedia/WWD-Acct-KidTimeline-Age5-BurtoltAndBee-01:23-N2-1.mp3"
          index={0}
          activeIndex={activeAudioIndex}
          setActiveIndex={setActiveAudioIndex}
          ageId={age}
          nodeId={nodeID}
        />
      </NodeContainer>
      <Image
        src="/assets/timelineModalMedia/WWD-Acct-KidTimeline-Age5-BurtoltAndBee-01:23-N2-2.png"
        alt="Strawberries"
      />
      <NodeContainer>
        <NodeHeading>Episode 2: Investing in strawberries!</NodeHeading>
        <NodeAudio
          src="/assets/timelineModalMedia/WWD-Acct-KidTimeline-Age5-BurtoltAndBee-01:23-N2-2.mp3"
          index={1}
          activeIndex={activeAudioIndex}
          setActiveIndex={setActiveAudioIndex}
          ageId={age}
          nodeId={nodeID}
        />
      </NodeContainer>
      <Image
        src="/assets/timelineModalMedia/WWD-Acct-KidTimeline-Age5-BurtoltAndBee-01:23-N2-3.png"
        alt="Pixel Speech Bubble With Coin"
      />
      <NodeContainer>
        <NodeHeading>Episode 3: Money talks.</NodeHeading>
        <NodeAudio
          src="/assets/timelineModalMedia/WWD-Acct-KidTimeline-Age5-BurtoltAndBee-01:23-N2-3.mp3"
          index={2}
          activeIndex={activeAudioIndex}
          setActiveIndex={setActiveAudioIndex}
          ageId={age}
          nodeId={nodeID}
        />
      </NodeContainer>
    </NodeModal>
  );
};

export default ModalA5N2;
