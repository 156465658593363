import { FormLabel, FormLabelProps, Text } from '@chakra-ui/react';
import React from 'react';
import InfoPopover from '../InfoPopover';
import { InfoPopoverProps } from '../InfoPopover/InfoPopover';

interface InfoFormLabelProps {
  label: string;
  infoPopoverProps: InfoPopoverProps;
  formLabelProps?: FormLabelProps;
  htmlFor: string;
}

const InfoFormLabel = ({
  label,
  infoPopoverProps,
  formLabelProps,
  htmlFor,
}: InfoFormLabelProps): React.ReactElement => (
  <FormLabel
    htmlFor={htmlFor}
    fontWeight="smallText"
    display="flex"
    gap="5px"
    alignItems="center"
    {...formLabelProps}
  >
    <Text>{label}</Text>
    <InfoPopover {...infoPopoverProps} />
  </FormLabel>
);

export default InfoFormLabel;
