import React, { useEffect, useRef, useState } from 'react';
import { Box } from '@chakra-ui/react';
import ReactPlayer from 'react-player';
import {
  trackVideoPause,
  trackVideoPlay,
  trackVideoMuted,
  trackVideoUnmuted,
  trackVideoEnded,
} from '../../../lib/analytics';

interface NodeVideoPlayerProps {
  url: string;
  isGif?: boolean;
  nodeNumber: number;
  ageId: number;
  index: number;
}

const NodeVideoPlayer: React.FunctionComponent<NodeVideoPlayerProps> = ({
  url,
  isGif,
  nodeNumber,
  ageId,
  index,
}) => {
  const videoRef = useRef<ReactPlayer>(null);
  const isMuted = useRef<boolean>(false);
  const hasEnded = useRef<boolean>(false);
  const [fileName, setFileName] = useState<string>('');

  useEffect(() => {
    const player = videoRef.current?.getInternalPlayer();
    if (player && player.muted !== undefined) {
      isMuted.current = player.muted;
    }
  }, [videoRef]);

  useEffect(() => {
    const urlSubstring = url.substring(url.lastIndexOf('/') + 1);
    setFileName(urlSubstring);
  }, [url]);

  return (
    <Box bgColor="brand.white" w="100%" height="auto" paddingX="7.5px">
      <ReactPlayer
        playing
        playsinline
        width="100%"
        url={[url]}
        controls={!isGif}
        height="auto"
        loop={isGif}
        config={{
          file: {
            attributes: {
              preload: 'metadata',
            },
          },
        }}
        ref={videoRef}
        onPlay={() => {
          hasEnded.current = false;
          trackVideoPlay(fileName, nodeNumber, ageId, !!isGif, index);
        }}
        onEnded={() => {
          hasEnded.current = true;
          trackVideoEnded(fileName, nodeNumber, ageId, !!isGif, index);
        }}
        onPause={() => {
          const duration = videoRef.current?.getDuration();
          const currentTime = videoRef.current?.getCurrentTime();
          if (
            duration !== undefined &&
            currentTime !== undefined &&
            Math.ceil(duration) > Math.ceil(currentTime)
          ) {
            trackVideoPause(fileName, nodeNumber, ageId, !!isGif, index);
          }
        }}
        onVolumeChange={() => {
          const player = videoRef.current?.getInternalPlayer();
          if (
            player &&
            player.muted !== undefined &&
            isMuted.current !== player.muted
          ) {
            isMuted.current = player.muted;
            if (player.muted) {
              trackVideoMuted(fileName, nodeNumber, ageId, !!isGif, index);
            } else {
              trackVideoUnmuted(fileName, nodeNumber, ageId, !!isGif, index);
            }
          }
        }}
      />
    </Box>
  );
};

export default NodeVideoPlayer;
