import React from 'react';
import { Image } from '@chakra-ui/react';

import {
  NodeContainer,
  NodeHeading,
  NodeModal,
  NodeText,
  NodeVideoPlayer,
} from '../NodeComponents';
import { NodeModalProps } from './types';

const age = 11;
const nodeNumber = 0;

const ModalA11N2: React.FunctionComponent<NodeModalProps> = ({
  isOpen,
  onClose,
}) => (
  <NodeModal isOpen={isOpen} onClose={onClose}>
    <NodeVideoPlayer
      url="/assets/timelineModalMedia/WWD-Acct-KidTimeline-Age11-Alien-01:23-N2-Hero.mp4"
      isGif
      ageId={age}
      nodeNumber={nodeNumber}
      index={0}
    />
    <NodeContainer>
      <NodeHeading>Texting with Aliens</NodeHeading>
      <NodeText>
        <b>The Search for Extraterrestrial Intelligence</b> (SETI) was a project
        that started in <b>1960</b> by astronomer Frank Drake. Eventually NASA
        and the National Science Foundation joined SETI in its hunt for aliens.
        They&apos;re still looking…
      </NodeText>
    </NodeContainer>
    <Image
      src="/assets/timelineModalMedia/WWD-Acct-KidTimeline-Age11-Alien-01:23-N2-1.png"
      alt="Alien On Spaceship"
    />
    <NodeContainer>
      <NodeText>
        Together, they started the Microwave Observing Project, which committed
        <b> $100 million</b> to the search. Although there haven&apos;t been any
        confirmed signals from other civilizations, most astronomers agree that
        it is highly likely that life, in some form or another, exists beyond
        Earth. So more recently, SETI has been joined by METI (Messaging
        Extraterrestrial Intelligence). Basically, texting with aliens!
      </NodeText>
    </NodeContainer>
    <Image
      src="/assets/timelineModalMedia/WWD-Acct-KidTimeline-Age11-Alien-01:23-N2-2.png"
      alt="Selfie Alien"
    />
    <NodeContainer>
      <NodeText>
        With the advancement of technology and electromagnetic radiation (try
        saying that 10 times fast), attempting to simply communicate with other
        life forms instead of trying to physically find them is more efficient
        and cost effective… in theory. The newly constructed Five-hundred-meter
        Aperture Spherical radio Telescope <b>(FAST)</b> in China is the largest
        radio telescope ever built. It cost about $180 million, and it will be
        used to send messages out to space in the hopes that someday someone, or
        something, will slide into Earth&apos;s DMs.
      </NodeText>
    </NodeContainer>
  </NodeModal>
);

export default ModalA11N2;
