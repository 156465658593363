import { theme as baseTheme } from '@chakra-ui/react';

const colors = {
  ...baseTheme.colors,
  brand: {
    primary: 'rgba(0, 50, 78, 1)',
    accent: '#FFD600',
    // titleHighlight: '#50C6DD',
    titleHighlight: 'rgba(97, 220, 243, 1)',
    lightText: '#FFFFFF',
    // lightText: '#FAFAFA',
    darkText: '#043D4D',
    badge: '#043D4D',
    lightBackground: 'rgba(97, 220, 243, 0.1)',
    // lightBackground: 'rgba(80, 198, 221, 0.05)',
    mediumBackground: 'rgba(210, 236, 243, 1)',
    primaryBackground: 'rgba(255, 255, 255, 1)',
    greyBackground: 'rgba(196, 196, 196, 1)',
    greyBackgroundTransparent: 'rgba(196, 196, 196, 0.5)',
    secondaryYellow: '#FFE471',
    grey50: '#FFFFFF',
    grey100: '#EDEDED',
    grey200: 'rgba(172, 172, 172, 1)',
    grey300: '#808080',
    // grey400: '#FFFFFF',
    // grey500: '#FFFFFF',
    headerColor: 'rgba(246, 250, 252, 0.86)',
    headerLine: 'rgba(216, 234, 242)',
    shadow: 'rgba(0, 0, 0, 0.05)',
    popoverShadow: 'rgba(0, 0, 0, 0.23)',
    error: 'rgba(236, 0, 103, 1)',
    childTimelineBg: 'rgba(213, 245, 254, 1)',
  },
};
export type Colors = typeof colors;

export default colors;
