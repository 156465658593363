import React from 'react';
import { Text, TextProps } from '@chakra-ui/react';

const NodeHashtags: React.FunctionComponent<TextProps> = ({ children }) => (
  <Text
    fontWeight="smallText"
    fontSize="subText"
    lineHeight="medText"
    marginY="-10px"
  >
    {children}
  </Text>
);

export default NodeHashtags;
