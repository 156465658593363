import * as React from 'react';
import { Global } from '@emotion/react';

const Fonts = (): any => (
  <Global
    styles={`
        @font-face {
          font-family: 'ProximaNova';
          src: url('../assets/fonts/ProximaNova-Regular.otf');
        }

        @font-face {
          font-family: 'ProximaNova Bold';
          src: url('../assets/fonts/Proxima-Nova-Bold.otf');
        }

        @font-face {
          font-family: 'ProximaNova Alt Bold';
          src: url('../assets/fonts/Proxima-Nova-Alt-Bold.otf');
        }

        @font-face {
          font-family: 'ProximaNova Extra Bold';
          src: url('../assets/fonts/Proxima-Nova-Extrabold.otf');
        }

        @font-face {
          font-family: 'ProximaNova Alt Light';
          src: url('../assets/fonts/Proxima-Nova-Alt-Light.otf');
        }

        @font-face {
          font-family: 'ProximaNova Alt Thin';
          src: url('../assets/fonts/Proxima-Nova-Alt-Thin.otf');
        }

        @font-face {
          font-family: 'ProximaNova Thin';
          src: url('../assets/fonts/Proxima-Nova-Thin.otf');
        }

        @font-face {
          font-family: 'ProximaNova Black';
          src: url('../assets/fonts/Proxima-Nova-Black.otf');
        }

        @font-face {
          font-family: 'ProximaNova Semi Bold';
          src: url('../assets/fonts/Proxima-Nova-Semi-Bold.otf');
        }

        @font-face {
          font-family: 'Intro';
          src: url('../assets/fonts/Intro.otf');
        }

        @font-face {
          font-family: 'Intro-Inline';
          src: url('../assets/fonts/Intro-Inline.otf');
        }

      `}
  />
);

export default Fonts;
