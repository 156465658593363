import * as Yup from 'yup';

const emailValidationRequired = {
  email: Yup.string().trim().email('Invalid email format').required('Required'),
  emailRepeat: Yup.string()
    .trim()
    .required('Required')
    .oneOf([Yup.ref('email')], 'Emails must match'),
};

export { emailValidationRequired };
