import React from 'react';
import { NodeModal, NodeVideoPlayer } from '../NodeComponents';
import { NodeModalProps } from './types';

const age = 1;
const nodeNumber = 1;

const ModalA1N2: React.FunctionComponent<NodeModalProps> = ({
  isOpen,
  onClose,
}) => (
  <NodeModal isOpen={isOpen} onClose={onClose}>
    <NodeVideoPlayer
      url="/assets/timelineModalMedia/WWD-Acct-KidTimeline-Age1-Starfish-01:23-N2.mp4"
      isGif
      ageId={age}
      nodeNumber={nodeNumber}
      index={0}
    />
  </NodeModal>
);

export default ModalA1N2;
