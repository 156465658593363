import { CallBackProps, STATUS } from 'react-joyride';

export const hasCompletedTour = (joyRidePage: string): boolean =>
  document.cookie.includes(`${joyRidePage}tourCompleted=true`);

export const handleCreateJoyrideCookies = (
  data: CallBackProps,
  joyRidePage: string,
): void => {
  const { status } = data;

  if (
    (status === STATUS.FINISHED || status === STATUS.SKIPPED) &&
    !hasCompletedTour(joyRidePage)
  ) {
    const expiresDate = new Date('2038-01-19T03:14:07');
    document.cookie = `${joyRidePage}tourCompleted=true; expires=${expiresDate.toUTCString()}`;
  }
};
