import * as React from 'react';
import { createIcon } from '@chakra-ui/icons';

const EditIcon = createIcon({
  displayName: 'Editcon',
  viewBox: '0 0 32 32',
  defaultProps: {
    w: '32px',
    h: '32px',
    mr: '0.25rem',
  },
  path: (
    <>
      <path
        d="M31 16C31 24.2843 24.2843 31 16 31C7.71573 31 1 24.2843 1 16C1 7.71573 7.71573 1 16 1C24.2843 1 31 7.71573 31 16Z"
        fill="white"
        stroke="currentColor"
        strokeWidth="2"
      />
      <path
        d="M9.54969 18.3125L13.6072 22.3861C13.4857 22.5008 13.3246 22.5641 13.1576 22.5627H10.0422C9.86471 22.5627 9.69451 22.4923 9.56903 22.3668C9.44354 22.2413 9.37305 22.0711 9.37305 21.8936V18.7354C9.37939 18.5778 9.44207 18.4278 9.54969 18.3125Z"
        fill="currentColor"
      />
      <path
        d="M19.3832 16.6477L13.6288 22.3968L9.56055 18.3232L15.3043 12.5527L19.3832 16.6477Z"
        fill="currentColor"
      />
      <path
        d="M22.1613 13.9927L20.2288 15.9358L16.1445 11.8354L18.0823 9.8923C18.2271 9.74648 18.3993 9.63075 18.589 9.55177C18.7787 9.47279 18.9822 9.43213 19.1877 9.43213C19.3932 9.43213 19.5967 9.47279 19.7864 9.55177C19.9761 9.63075 20.1483 9.74648 20.2931 9.8923L22.1666 11.7765C22.3123 11.9221 22.4277 12.0951 22.5063 12.2854C22.5849 12.4758 22.6251 12.6798 22.6246 12.8857C22.6241 13.0917 22.5829 13.2955 22.5034 13.4854C22.4239 13.6754 22.3076 13.8478 22.1613 13.9927Z"
        fill="currentColor"
      />
    </>
  ),
});

export default EditIcon;
