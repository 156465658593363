import { ImageProps } from '@chakra-ui/react';

interface TimelineData {
  [key: string]: {
    html: string;
    images?: {
      src: string;
      props?: ImageProps;
    }[];
    outerImages?: {
      src: string;
      props?: ImageProps;
    }[];
  };
}

const data: TimelineData = {
  '0': {
    html: `
      <p>You have a baby! Welcome to the greatest, most sleep-deprived club on the planet. So far, you&rsquo;re doing everything right. Sit with this feeling.</p>
      <p>And now, say goodbye to it forever.</p>
      <p>We kid, we kid.</p>
      <h2>Start Here.</h2>
      <p>One of the most important things you can do as a parent is help to secure your child&rsquo;s financial future. By opening a Wealthie account, you&rsquo;re on your way.</p>
      <p>So congratulations! Pat yourself on the back, and take a nap. Wake up when your kid is 25.</p>
      <h2>Keep Going.</h2>
      <p>You&rsquo;ve started early. Now, your job is to keep going. Work to make consistent, manageable payments a habit. $10 a week in a diversified portfolio (like your child&rsquo;s Wealthie account) at 6% a year, builds almost $60 000 by age 25.</p>
      <h2>Get This.</h2>
      <p>Pair that $60K with almost two decades of Wealthie&rsquo;s smart financial education, and rest assured that by the time that nest egg is ready for your kid, your kid will be ready for it.</p>
      <p>So, have you just won parenting forever? Yes, my friend, we believe you have.</p>
      <p>Welcome to Wealthie. We&rsquo;re happy to have you.</p>
    `,
    outerImages: [
      {
        src: `/assets/timeline/parent-timeline/Age0-WowCat.gif`,
        props: {
          alt: 'Wow Cat',
          width: '369px',
          maxWidth: 'none',
          position: 'absolute',
          left: '-12px',
          bottom: '30px',
        },
      },
    ],
  },
  '1': {
    html: `
      <p>Here&rsquo;s the investing secret most people take a lifetime to learn: investing requires Time + Money. BUT… having one of the two can make up for not having the other. What do we mean? Your baby may not have much Money, but they do have Time, and Time is valuable.</p>
      <h2>Start Here.</h2>
      <p>Is your baby rolling into family BBQs like an A-list celeb? Odds are your bundle of joy is still the only thing your family and friends want to talk about. So, while people in your life are up <em>in</em> your baby&rsquo;s business, why not make them shareholders in your baby&rsquo;s business: <em>Baby Future, Inc.</em></p>
      <h2>Keep Going.</h2>
      <p>Yes, some stuffies will stand the test of time, but odds are nothing will come close to the value of an investment made now for the long term. Need a story to illustrate the power of time and compounding? Try this anecdote, with thanks to Morgan Housel, author of <em>The Psychology of Money...</em></p>
      <h2>Get This.</h2>
      <p>If Warren Buffett had invested his money like many people do — if he&rsquo;d started at 30, invested $25,000, retired at 60, and <em>still</em> made 22% annual Berkshire Hathaway-style returns — today, his net worth would be about $15 million.</p>
      <p>Yes, $15 million is a lot of money, BUT… because he started investing at 10 and kept going, WB&rsquo;s fortune has now rocketed past $100 billion. That&rsquo;s even more money.</p>
      <p>What&rsquo;s the takeaway here? Your offspring is currently nine years ahead of The Oracle of Omaha. And, while we&rsquo;re not suggesting you put Warren Buffet-sized expectations on your pint-sized tycoon, we are saying that the greatest commodity your baby has right now is time. Work it!</p>
    `,
    images: [
      {
        src: `/assets/timeline/parent-timeline/Age1-TimeIsMoney-Pt1.png`,
        props: {
          position: 'absolute',
          width: '268px',
          height: '268px',
          left: '31.5px',
          bottom: '0px',
          alt: 'Time is money',
        },
      },
      {
        src: `/assets/timeline/parent-timeline/Age1-TimeIsMoneyCoin-Pt2.gif`,
        props: {
          position: 'absolute',
          width: '36px',
          height: '36px',
          right: '19.5px',
          // top: '123.5px',
          bottom: '69.5px',
          alt: 'Coin',
        },
      },
    ],
  },
  '2': {
    html: `
      <p>Your child is a toddler. The world has morphed into one giant staircase and a constant onslaught of forehead-level hazards. As of today, your kid can wander everywhere, and reach everything. What they can&rsquo;t reach, they want NOW. What they CAN reach, they will eat. The grocery store is your new Waterloo. You&rsquo;re going to be okay.</p>
      <h2>Start Here.</h2>
      <p>Developmentally, along with the occasional crayon, your toddler is <em>also</em> eating up your family&rsquo;s values. They are watching your every move — from your interactions as a family, to how you treat people in shops, to how you approach everything from meal prep to money management.</p>
      <p>It&rsquo;s a good time to think about what you want your child to learn about value and values from you. If you have a partner, you&rsquo;ll want to think this through together.</p>
      <h2>Keep Going.</h2>
      <p>We inherit the majority of our beliefs and attitudes about money from the families we grow up in. While you may think your understanding of money is universal, odds are it&rsquo;s pretty unique to your own experiences. Write down the first three adjectives that come to mind when you think about money.</p>
      <p>Are they positive or negative? Empowering or frightening? If you have a partner, ask them to do the same. You&rsquo;ll probably spark an interesting conversation about the lessons you both absorbed as kids.</p>
      <h2>Get This.</h2>
      <p>Life may not be a rehearsal, but grocery shopping with a two-year-old should involve one. While you&rsquo;re on your way, talk through <strong>everything</strong> you plan to buy, aloud, together. What do you need? What don&rsquo;t you need? You&rsquo;ve just turned the grocery store into a scavenger hunt.</p>
      <p>Will this keep your kid engaged? For three minutes, maybe. BUT…while it may not stifle demands for unicorn cakes and dinosaur confetti, it <em>will</em> instill an early understanding of wants vs needs, plus planning and patience, in your child&rsquo;s subconscious.</p>
      <p>
        Why does this matter? Studies show that delayed gratification is a lesson that can be learned at age two. That&rsquo;s a lesson that will pay dividends for life.
      </p>
      <p>Have your unicorn cake and eat it, too.</p>
    `,
    outerImages: [
      {
        src: `/assets/timeline/parent-timeline/Age2-PalmLeaves-Pt1.png`,
        props: {
          position: 'absolute',
          width: '453px',
          height: '453px',
          left: '-38.5px',
          maxWidth: 'none',
          alt: 'Palm Leaves',
        },
      },
      {
        src: `/assets/timeline/parent-timeline/Age2-CoolUnicorn-Pt2.gif`,
        props: {
          position: 'absolute',
          width: '220px',
          height: '220px',
          left: '62.5px',
          top: '149.79px',
          alt: 'Cool Unicorn',
        },
      },
    ],
  },
  '3': {
    html: `
      <p>Your threenager is watching you like some kind of baby Netflix. When it comes to basic finance, they&rsquo;ve picked up a thing or two. They see that paper money and plastic cards get “stuff”. And they have neither paper nor plastic. MAJOR bummer.</p>
      <h2>Start Here.</h2>
      <p>Counting and stacking coins to make dollars will give your toddler a felt sense of money and value, plus a subtle sense of agency and power.</p>
      <p>Step One on the Path to Plutocracy, Kid! Kidding, kidding.</p>
      <p>These days, money&rsquo;s mostly virtual, so it&rsquo;s important for your kids to see, count, and understand it in an IRL way, before they enter the digital world and money disappears for them for life.</p>
      <p>If you haven&rsquo;t carried cash since 2009, you can still get a few fast money lessons across in ways your kid will understand. Start by encouraging decision making. It&rsquo;s an excellent primer for learning about <em>tradeoffs.</em></p>
      <h2>Keep Going.</h2>
      <p>Toddlers are on a quest for independence. They&rsquo;re famously all will with little skill (we&rsquo;re sure that <strong>your</strong> kid has huge skills). To minimize meltdowns, offer two choices when either is okay. The spotted shirt or the striped one? Blue cup or green? This story or that one?</p>
      <p>Moments of decision-making will help your toddler form a loose concept of value, and while they may not be able to pronounce “resource allocation”, they&rsquo;ll start to get the gist.</p>
      <h2>Get This.</h2>
      <p>While your little person&rsquo;s spending their days copying all that you do, you can create copy-able moments to show what money is, what it does, and how grownups think about it.</p>
      <p><strong>Describe what&rsquo;s happening.</strong> At the grocery store, your words — “put the bananas here so we can pay for them” or “let&rsquo;s give the cashier the money” — establish the idea that you&rsquo;re exchanging one thing for another.</p>
      <p><strong>Count and compare.</strong> Practice counting objects, one to five. One number for each object. Help your toddler compare small groups at snack time—which pile has less, which pile has more, which pile is the same? Size and value can be tricky concepts for little kids to separate. A dime may be worth more than a nickel, but the nickel is bigger, so that can be confusing.</p>
      <p><strong>Just say no.</strong> Who are we kidding... Delayed gratification is hard at any age, and nearly impossible for toddlers. Three-year-olds are all about immediacy &ndash; full gas, no brakes. An effective parenting strategy is to acknowledge the want without giving into it. <em>I see you. I know this feels hard and you feel sad. But no.</em></p>
      <p>In approximately 25 years, your toddler will thank you for their discipline and willpower. (In the meantime, rely on YOUR discipline and willpower to wait for this glorious moment.)</p>
    `,
    images: [
      {
        src: `/assets/timeline/parent-timeline/Age3-B&WCoinstack.gif`,
        props: {
          alt: 'Coin Stack',
          marginBottom: '30px',
        },
      },
    ],
  },
  '4': {
    html: `
      <p>Think it&rsquo;s too early to talk about money? Actually, you&rsquo;re late. We&rsquo;re sort of kidding, but really, starting early is an important part of forming good money habits.</p>
      <h2>Start Here.</h2>
      <p>Your four-year-old has developed a solid set of skills for navigating the world. They can take turns, follow rules, and even say what they want with actual words (most of the time).</p>
      <p>Giving your child choices is still a good way to encourage independence without chaos. Provide two choices, when either one would be okay. A year ago, the choice might have been between a red cup or a blue one. Now, offer choices that require more thought.</p>
      <p><i>It&rsquo;s your friend&rsquo;s birthday and we have $25 dollars to spend. Would you like to give them a ___ or a ___? (Trick question! The answer is: “which Wealthie gift card should we choose?”)</i></p>
      <p>Be ready to help your child talk through the reasons for their preference.</p>
      <h2>Keep Going.</h2>
      <p>It&rsquo;s a statistical fact that parents don&rsquo;t like to talk about money. Few people do. Most of us were taught not to talk about money, which explains why we have difficult relationships with it, and also why it&rsquo;s hard to find the words to discuss it.</p>
      <p>This is fixable. Here are some simple ways to ease into casual money talk with your kid.</p>
      <p>Your four-year-old doesn&rsquo;t need to know how much money you earn, or how to pay the electricity bill (although… super handy), but it is worth chatting with them about <i>how</i> people earn money. What would your four-year-old like to be when they grow up? A robot firefighting ballet dancer? Great! We can work with that…</p>
      <p>You can start a natural conversation about earning and saving at this stage. How is money earned, and why is the saving part so important? There are also lots of ways to talk about the value of saving without talking about money. Take dinner, for example, where your four-year-old might <strong>save</strong> the best thing on their plate for last. Maybe they <strong>save</strong> room for dessert. The takeaway is that <i>saving something now can give it more value later.</i></p>
      <h2>Get This.</h2>
      <p>So, what does it take to become a saver? It takes patience and persistence, which may feel impossible to cultivate in a four-year-old.</p>
      <p>We like this tip:</p>
      <p>The “Batman Effect” suggests that kids will stay with a task longer when they imagine themselves as someone (or something) they believe is a hard worker, like Batman. No matter your preferred superhero, this is a simple way to tap your child into some wise, long-term financial habits. Cape optional.</p>
    `,
    images: [
      {
        src: `/assets/timeline/parent-timeline/Age4-Bats.png`,
        props: {
          alt: 'Bats',
        },
      },
    ],
  },
  '5': {
    html: `
      <p>Welcome back! You&rsquo;ve been at this now for half a decade! Where has the time gone?! Kidding. We recognize that you&rsquo;re exhausted and five years has felt like 100. You&rsquo;re doing great. Let&rsquo;s talk about allowance.</p>
      <h2>Start Here.</h2>
      <p>We get a <em>lot</em> of questions about allowance.</p>
      <p>Giving a regular allowance is an important step in a young person&rsquo;s journey towards financial independence. They&rsquo;re only five, we get it — but a reminder that most of our adult habits around money are formed before the age of seven. These are important years.</p>
      <h2>Keep Going.</h2>
      <p><strong>It&rsquo;s allowance, not bribery.</strong> Think of allowance as a fixed weekly (or bi-weekly) “salary” for just being a kid. Chores? They&rsquo;re part of being a good citizen. Don&rsquo;t pay for them. Of course you can attach some achievable “deliverables” to allowance, but most of all, it&rsquo;s here to help your little one start to learn about how money works and how to manage it.</p>
      <ol>
        <li>Set an allowance</li>
        <li>Make it meaningful enough that your kid can divide it into buckets</li>
        <li>Keep it consistent.</li>
      </ol>
      <h2>Get This.</h2>
      <p><b><i>But, I haven&rsquo;t carried cash money since the &rsquo;90s.</i></b> No problem. A mom we admire uses cotton balls to visualize the power of saving and growth. Her kids&rsquo; allowance is paid in dollar <i>balls</i>. Cotton fills a jar fast, which may have your five-year-old vibing like a plutocrat within a month and a half.</p>
      <p><strong>Save Spend Shares.</strong> A lot of experts recommend three piggy banks, to encourage your child to divide allowance between saving, spending, and sharing. Hold up! This leaves out a critical lesson about how money <i>grows</i>. Add a “shares” or invest piggy to the collection, and see how compound interest grows an allowance over time.</p>
      <p>(Four pigs is basically a farm. If you don&rsquo;t have the acreage, stick with Save, Spend, <i>Shares</i>, and focus the giving piece on giving <i>time</i>, with activities that do good. Experiential giving makes kinesthetic memories. Plus, putting 25-50% into a “shares” jar to invest could mean your child has a meaningful nest egg to dip into for giving once they&rsquo;re older.)</p>
      <h2>PS.</h2>
      <p><b>Don&rsquo;t Create a Wage Gap.</b> These are your child&rsquo;s formative years. Champion equality. Remember that women still earn 82 cents or less on the dollar <b>(67 cents for racialized women),</b> and it&rsquo;s a good time (and a great conversation) to make that up to the young girls, girl-identifying, and nonbinary kids in your home.</p>
    `,
    images: [
      {
        src: `/assets/timeline/parent-timeline/Age5-NeonMoney.png`,
        props: {
          alt: 'Neon Money',
        },
      },
    ],
  },
  '6': {
    html: `
    <p>A University of Cambridge study says that by age seven, many of a kid&rsquo;s “money habits” are set for life. Your kid&rsquo;s only six! As of today, you are ahead of the game. Take a moment to drink this in.</p>
    <p>And we&rsquo;re back.</p>
    <h2>Start Here.</h2>
    <p>Studies suggest that your child will adopt <strong>your</strong> money habits. Breeeathe. Take a moment to think, really think, about your money habits. How do you spend? How do you save? How do you think about money? Do you worry about not having enough of it? Do you worry about having too much? Do you talk about it too much? Too little? At all?</p>
    <p>Write down your thoughts, write down your habits. Scan what you've written. Don&rsquo;t judge those habits. Move onto Step 2.</p>
    <p>Step 2: Write down your parents&rsquo; money habits.</p>
    <p>Look familiar?</p>
    <h2>Keep Going.</h2>
    <p>We pass money stories down for generations. If your habits and stories are serving you, great. If not, try this: Write down the habits you&rsquo;d <em>like</em> your child to have by age 25. You might include practices like creating and sticking to a household budget, feeling financially confident and empowered, making regular contributions to a diversified investment portfolio, zero social media impulse buys at 3 AM…
    <p>How can you model those habits, even just hint at them, starting now? Your kids will follow your lead.</p>
    <h2>Get This.</h2>
    <p>Start a conversation with your six-year-old. How do they feel about money? If money were a character or a famous person, who would it be? What can money be used for? Expand the answer beyond “it gets me toys” to a larger conversation about the future, about giving, growing, and, obviously, about investing.</p>
    <p>Log into your kid&rsquo;s Wealthie account and check out their gift cards. Explain why you and the adults in your child&rsquo;s life are investing money in their future. What does your six-year-old want their future to look like? What would they like to use their Wealthie account for when they&rsquo;re older? Write down their answers, keep them safe, and repeat this annually. Read together when your child turns 21.</p>
    <p>Wait, 21?!?! I&rsquo;m not crying, you&rsquo;re crying. Kidding. We&rsquo;re all crying.</p>
    <p>Hug your six-year-old.</p>
    `,
    images: [
      {
        src: `/assets/timeline/parent-timeline/Age6-RollerCoaster.gif`,
        props: {
          alt: 'Roller Coaster',
          marginBottom: '40px',
        },
      },
    ],
  },
  '7': {
    html: `
      <p>For a kid, money is magic. It comes out of a machine, it lives in plastic cards, it can buy toys. Money isn&rsquo;t magic, but compound interest certainly is. Especially when it&rsquo;s working for a kid. Compound interest illustrates the value of time. It demonstrates that time is actually money.</p>
      <p>Remember when we talked about how Warren Buffett started investing at 10, and then he kept going? He made time work for his money, and so far, that&rsquo;s worked out pretty well for him.</p>
      <p>Right now, your kid is three years ahead of Mr. Buffett. It&rsquo;s not a race, but your child is obviously a genius.</p>
      <h2>Start Here.</h2>
      <p>What IS money and how does it work?</p>
      <p>Your seven-year-old should know that money works as an exchange. Money makes it easier to trade for the things they&rsquo;d like to do or buy. It can be earned, spent, borrowed, given, saved, and invested. (It can also be lost, stolen, squandered, flushed, and burned, but we&rsquo;ll keep it positive.)</p>
      <h2>Keep Going.</h2>
      <p>A lot of parents hide discussions about money from their kids. This means kids can internalize fear or mystery around money. Help them solve some mysteries.</p>
      <p>Talking about money openly — saving up for a new roof, university, or a banana seat bike — gives your kids a tangible sense of the connection between time and money. It also helps to make money seem less secretive, less stressful, and less scary to a growing mind.</p>
      <h2>Get This.</h2>
      <p>If you plan to hold onto $$$ for a while, it&rsquo;s important to put it to work.</p>
      <p>6% interest on a child&rsquo;s investment, year after year, means that annually, their principal <em>plus</em> their interest grows by 6%.</p>
      <p>Interest making interest is great when you&rsquo;re saving money. Inflation eats away at the power of a dollar over time. Compound interest helps to combat the negative impacts of inflation while also rewarding patience — a nice win-win for your kids to see in action.</p>
    `,
    images: [
      {
        src: '/assets/timeline/parent-timeline/Age7-ParentAndKid.gif',
        props: {
          alt: 'Parent and Kid',
          margin: 'auto',
          marginBottom: '30px',
          width: '228px',
        },
      },
    ],
  },
  '8': {
    html: `
      <p>Is your eight-year-old wondering why they have to learn about “old person stuff” like money? Have they already retired on Robux and influencer proceeds? Fine. Strengthen their empire by introducing stocks, growth, and a little bit of risk.</p>
      <p>A stock represents a piece of a company that your kid can own. Starbucks (SBUX), Spinmaster (TOY), Disney (DIS), are all companies that your eight-year-old can own a piece of now.</p>
      <p>Wealthie focuses on exchange-traded funds, because we want young people to benefit from diversification. (ETFs are made up of slices of multiple stocks across a market.)</p>
      <p>However, if you and your eight-year-old have your eye on a specific stock you&rsquo;d like to buy, here are a few tips to help you get started.</p>
      <h2>Start Here.</h2>
      <p>Do your research, and get familiar with what you're buying. Look at the stock&rsquo;s performance over the years. What does the company sell? Do you understand what they sell? Do you (or would you) use what they sell? Who are the competitors? And what do you think of the company&rsquo;s leaders?</p>
      <p>You&rsquo;re investigating the company&rsquo;s fundamentals, which is why this is called, unsurprisingly, <strong>Fundamental Analysis</strong>.</p>
      <h2>Keep Going.</h2>
      <p>Next, get real about risk tolerance — your own, and your eight-year-old's. Here&rsquo;s a rule of thumb: <strong>start by investing what you're comfortable losing.</strong></p>
      <p>There&rsquo;s always an element of risk in the markets. Historically, that risk has declined with time. It&rsquo;s why we encourage parents and guardians to #StartEarly with their kids.</p>
      <h2>Get This.</h2>
      <p>Check your risk tolerance, do your research, buy your shares (preferably in a few diversified stocks across a variety of industries), and chill. The markets will go up and down, and it will be tempting to check on your stock(s) daily, hourly, on the way to the fridge. Do not do this. You&rsquo;ve made a decision for the long term. Now, let the long term actually happen.</p>
    `,
    outerImages: [
      {
        src: '/assets/timeline/parent-timeline/Age8-SpaceIsland-Pt1.png',
        props: {
          position: 'absolute',
          width: '544px',
          left: '30.5px',
          bottom: '112.6px',
          alt: 'Space Island',
        },
      },
      {
        src: '/assets/timeline/parent-timeline/Age8-AstroTina-Pt2.gif',
        props: {
          position: 'absolute',
          left: '-99.5px',
          width: '544px',
          bottom: '40px',
          alt: 'Astro Tina',
          maxWidth: 'none',
        },
      },
    ],
  },
  '9': {
    html: `
      <p>A Charles Schwab survey in 2019 revealed that when it comes to feeling personally wealthy, 72% of people say it isn&rsquo;t about a dollar amount, it&rsquo;s about the way you live your life.</p>
      <h2>Start Here.</h2>
      <p>Let&rsquo;s get real. Social media is edging its way into your kid&rsquo;s life. It&rsquo;ll try hard to convince your kids that happiness lies somewhere between a Supreme hoodie and a yacht.</p>
      <p>You can gently guide your kid to start defining what a rich life might look and feel like, in the truest sense of the word. What sorts of experiences are important to your nine-year-old? What would they like their life to look like in 10, 15, even 20 years?</p>
      <h2>Keep Going.</h2>
      <p>We get it — it&rsquo;s hard at any age to picture, let alone care about, your future self. Hal Hershfield, a psychologist at the UCLA Anderson School of Management, discovered that the human brain thinks about its future self in the same way it thinks about a complete stranger.</p>
      <p>The problem with this? It&rsquo;s tough to give up something now for a stranger&rsquo;s enjoyment later. People care about and invest in those they feel connected to. So, help your nine-year-old turn this personal stranger into their very best friend.</p>
      <h2>Get This.</h2>
      <p>Ask about what your nine-year-old wants to be when they grow up. Talk about the actions and costs associated with those goals. Ask them to picture themselves in their future… What do they do on weekends? Where are they living? What kinds of clothes are they wearing? What&rsquo;s their favourite song? Their favourite dessert? What have they saved up for and bought? Play this through with them often enough to breed a kind of fun familiarity with this future family member. It may have a real impact on your kid&rsquo;s discipline, not to mention their financial picture over time. Someday you&rsquo;ll look back on their answers together, and either laugh or be astonished.</p>
    `,
    images: [
      {
        src: '/assets/timeline/parent-timeline/Age9-Skateboarder.gif',
        props: {
          alt: 'Skate Board',
          marginBottom: '30px',
        },
      },
    ],
  },
  '10': {
    html: `
      <p>Sure, ten-year-olds aren&rsquo;t supposed to be on social media. They aren&rsquo;t supposed to leave three-day-old cereal under their beds either. And yet…here we are.</p>
      <p>When LEGO polled kids between 5 and 12 about their career aspirations, <strong>“Vlogger/YouTuber”</strong> beat out teacher, musician, athlete and astronaut. Social media is having an impact on your child&rsquo;s life whether you like it or not, and it&rsquo;s a good time to talk about wants versus needs.</p>
      <h2>Start Here.</h2>
      <p>How many X-ray glasses and/or shriveled sea monkeys did you have by age 10? Asking for a friend…</p>
      <p>Kids are easy to sell to. And these days, they get sold to both in real life <em>and</em> in the metaverse. Kids are not good at distinguishing between reality and advertising claims. For this reason, Canada restricts commercial marketing to kids under 13. The U.S. does not. Some research suggests young people in the U.S. see 3,000 ads per day.</p>
      <p>It&rsquo;s natural, then, that your kid may be connecting “having more stuff” with “having a happy life”. Throw this curveball at them: a 2018 Purdue study concluded that happiness and life satisfaction decrease once an individual makes more than about $95,000 USD a year. You can ignore the number (it may feel too high or too low, depending on where you live and the state of your life) but share the broad takeaway: <em>more</em> money can statistically make a person <em>less</em> happy.</p>
      <h2>Keep Going.</h2>
      <p>Get comfortable sharing your own thinking around money so your kid starts to see the bigger picture.</p>
      <p>Model good habits. Surface the concept of “needs” vs. “wants”. Wants are <em>nice to haves</em> — things that go on a wishlist but aren&rsquo;t critical for survival. Needs are rent and mortgages, groceries, clothes, and car insurance. Have your ten-year-old chime in with what they feel are <em>needs</em> vs <em>wants.</em></p>
      <p>This will be a work in progress.</p>
      <h2>Get This.</h2>
      <p>In the 2010&rsquo;s, Rihanna sued her accountants (successfully) after almost going bankrupt from overspending. She had made a lot of money and she was spending a lot of money. And the thing about money, which may seem obvious, is that if a person spends faster than they earn, they run out of money. The defendants (her accountants) argued (unsuccessfully) that they didn&rsquo;t think it was necessary to tell Rihanna that if she spent money on things, she&rsquo;d have the things but not the money.</p>
      <p>Yes, it seems clear that spending <em>more</em> money leaves you with <em>less</em> of it. But, if you leave money out of the discussion at home, you may find your kid learning their money lessons the hard way.</p>
    `,
    images: [
      {
        src: '/assets/timeline/parent-timeline/Age10-GiftBox.gif',
        props: {
          alt: 'Gift Box',
          marginBottom: '40px',
        },
      },
    ],
  },
  '11': {
    html: `
      <p>Eleven! You have a great kid who&rsquo;s young enough to still take your advice on *almost* everything, and old enough to know how to roll their eyes. At this age, you may feel heightened pressure to complete your kid&rsquo;s transformation into the perfect human. It may feel like a good time to suggest your child start donating part of their allowance, to transform them into considerate, generous people.</p>
      <p>Before they start giving money away, take a moment to consider the full picture.</p>
      <h2>Start Here.</h2>
      <p>A lot of us have been taught that allowance should be divided in three ways: a percentage to save, a percentage to spend, and a percentage to share.</p>
      <p>Encouraging generosity in your 11-year-old is important. If you&rsquo;ve got a child who wants to give part of their allowance to a friend at school who doesn&rsquo;t have money for lunch, then you&rsquo;ve definitely done something right. It&rsquo;s good to give, but it&rsquo;s best to give when you <em>have</em> something to give.</p>
      <p>If your child gives their lunch money to someone and no longer has money for their own lunch, then we have a problem.</p>
      <p>While financial generosity is important, being generous with <strong>time</strong> may still be more valuable to development at this stage. We&rsquo;ve always said that your child&rsquo;s most powerful financial asset is time. Help them share their time, while whatever money they have continues to grow in the background.</p>
      <h2>Keep Going.</h2>
      <p>Volunteering helps your child build new skills, confidence, and a sense of pride in being part of a community. It can also teach them valuable lessons about empathy, business, teamwork, and life.</p>
      <p>(<strong>Bonus</strong>: Volunteering by teens has also been linked to more volunteering in adulthood and a greater sense of well-being, better work opportunities, and more education than for teens who didn&rsquo;t volunteer.)</p>
      <h2>Get This.</h2>
      <p>Have your 11-year-old do some research to build a list of organizations they might like to support. Canadahelps.org, charitynavigator.org, and volunteermatch.org are resources full of trusted organizations and opportunities.</p>
      <p>Maybe you&rsquo;d like to find a once weekly or monthly opportunity to make volunteering part of your family&rsquo;s routine.</p>
      <p>From here, your job is to treat this kind of service as a regular, nbd thing. You may feel proud of your kid for the choices they&rsquo;re making, but loading them up with praise will turn something that should be a natural part of life into something that feels extraordinary. Your kid is extraordinary. Let volunteering and service be the one ordinary thing about them.</p>
    `,
    images: [
      {
        src: '/assets/timeline/parent-timeline/Age11-PixelHeart-Pt1.gif',
        props: {
          alt: 'Pixel Heart animated',
        },
      },
      // Top left heart
      {
        src: '/assets/timeline/parent-timeline/Age11-PixelHeart-Pt2.png',
        props: {
          position: 'absolute',
          width: '38px',
          height: '40px',
          left: '31px',
          bottom: '271.42px',
          alt: 'Pixel Heart top left',
        },
      },
      // Bottom right heart
      {
        src: '/assets/timeline/parent-timeline/Age11-PixelHeart-Pt2.png',
        props: {
          position: 'absolute',
          width: '38px',
          height: '40px',
          left: '268px',
          bottom: '82.42px',
          alt: 'Pixel Heart bottom right',
        },
      },
      // Bottom left heart
      {
        src: '/assets/timeline/parent-timeline/Age11-PixelHeart-Pt2.png',
        props: {
          position: 'absolute',
          width: '38px',
          height: '40px',
          left: '20px',
          bottom: '42.42px',
          alt: 'Pixel Heart bottom left',
        },
      },
    ],
  },
  '12': {
    html: `
      <p>Twelve years old is prime time to flex some early entrepreneurial muscle. This is an excellent period to lock in some healthy habits for business and for life.</p>
      <h2>Start Here.</h2>
      <p>If your kid is getting ready to hang a shingle as a babysitter, a gardener, or a mod creator, they&rsquo;re going to have to set a price for their wares. Determining your “worth” (and having to argue for it) can be a real emotional rollercoaster. The sooner you help your kid get comfortable with their own sense of value, the better.</p>
      <p>You and your emerging entrepreneur may want to start with some field research. What are the going rates for the jobs being considered? Do other people in the neighborhood get $10/hour to babysit? If so, asking for $30/hour is probably unwise. But so is accepting $3/hour. Research what others are charging, and suggest a rate at the top of that range. If a customer has a lower rate in mind, your kid can offer to meet in the middle.</p>
      <p>Establish an hourly minimum wage that feels appropriate, and then talk premiums.</p>
      <p>Perhaps there are instances that will call for a top up on the hourly rate, such as extra kids being added to an evening of babysitting, or a lawn mowing job turning into a planting and weed-picking extravaganza.</p>
      <h2>Keep Going.</h2>
      <p>Practice conversations about value and hourly rates with your kid. Help them strengthen their language, so a conversation about a wage isn&rsquo;t a hesitant, uncomfortable negotiation, but an opportunity for your child to declare what they&rsquo;ve determined their time is worth. What should they do if a parent says no to their requested rate? What should they say if the parents don&rsquo;t give them the right amount at the end of the night? The more they role play difficult conversations, the more comfortable they&rsquo;ll feel having them in real life.</p>
      <p>Finding a wage that feels right teaches your kid to value their time. And, building self worth in these small, tangible ways can have a valuable impact on the rest of their life. As an adult, they&rsquo;ll find that a comfort with negotiation opens up unexpected opportunities to ask for what they need, want, and deserve, regardless of which side of the table they&rsquo;re seated at.</p>
      <h2>Get This.</h2>
      <p>Now is a great time to introduce the notion of Opportunity Cost. Opportunity Cost compares the price of doing one thing against the cost of missing out on doing another thing.</p>
      <p>Saying yes to a sleepover with friends on a Saturday night may mean saying no to a babysitting job that would earn them money towards a savings goal. Your 12-year-old will probably choose the sleepover 9 times out of 10, but with the idea of Opportunity Cost firmly planted, you may find that over time, they start to consider their tradeoffs.</p>
    `,
    images: [
      {
        src: '/assets/timeline/parent-timeline/Age12-TrafficLight.gif',
        props: {
          width: '178px',
          margin: 'auto',
          alt: 'Traffic Light',
        },
      },
      {
        src: '/assets/timeline/parent-timeline/Age12-Taxis.gif',
        props: {
          alt: 'Taxis',
          marginBottom: '40px',
        },
      },
    ],
  },
  '13': {
    html: `
      <p>It&rsquo;s official. Your kid is a teenager. That. Was. Fast.</p>
      <p>You have a fabulous, complicated teen, whose growing autonomy makes you realize they will be living independently in just a few short years. (We realize that nothing feels short right now, including your 13-year-old, who at this point may be taller than you.)</p>
      <p>While your kid grows like a weed, show them how to do the same for their money.</p>
      <h2>Start Here.</h2>
      <p>Introducing the Rule of 72. Simply put, it&rsquo;s a formula that calculates how long it will take an investment to double. Doubling their money is a decent way to get your teen to sit up and take notice.</p>
      <p>Here&rsquo;s the Rule, which is actually one of the best math equations around:</p>
      <p><b>72 ÷ Your Expected Interest Rate = The Number of Years for Your Money to Double.</b></p>
      <p>Let&rsquo;s put this into action. Try the Rule of 72 with 1% and you see pretty quickly why it pays to prioritize investing over saving for the long term.</p>
      <ul>
        <li>At 1% interest, it takes 72 years for money to double (72 ÷ 1 = 72)</li>
        <li>At 3% interest, it takes 24 years for money to double (72 ÷ 3 = 24)</li>
        <li>At 9% interest, it takes 8 years for money to double (72 ÷ 9 = 8)</li>
      </ul>
      <h2>Keep Going.</h2>
      <p>As your kid&rsquo;s financial literacy increases and they learn more about saving and investing, they&rsquo;re likely to start thinking a little more about their spending. Maybe the $7 after-school Frappuccino takes a backseat to something less pricey, and the $3 they&rsquo;ve just saved gets invested.</p>
      <p>And, hey, we get it — even monks appreciate a decent caramel frap now and then. This isn&rsquo;t about doing without. This is about your kid starting to understand the power they can have over their money, and what this starts to mean over time.</p>
      <h2>Get This.</h2>
      <p>There&rsquo;s a lot of talk in financial literacy about paying yourself first. The simple rule of thumb is that 15% of all money that comes in should go into investments. As an adult, 15% can feel impossible. As a 13-year-old, 15% can feel like a habit. Start it now.</p>
    `,
    images: [
      {
        src: '/assets/timeline/parent-timeline/Age13-NeonDonut.gif',
        props: {
          position: 'absolute',
          width: '119px',
          height: '120px',
          left: '39px',
          bottom: '229.93px',
          alt: 'Neon Donut',
        },
      },
      // Top right corner
      {
        src: '/assets/timeline/parent-timeline/Age13-NeonCoffee.gif',
        props: {
          position: 'absolute',
          width: '206px',
          height: '205px',
          left: '125px',
          bottom: '171.93px',
          alt: 'Neon Coffee top right',
        },
      },
      // Bottom left corner
      {
        src: '/assets/timeline/parent-timeline/Age13-NeonCoffee.gif',
        props: {
          position: 'absolute',
          width: '155.65px',
          height: '154.7px',
          left: '43px',
          bottom: '68px',
          transform: 'rotate(-32.3deg)',
          alt: 'Neon Coffee bottom left',
        },
      },
      // Bottom right corner
      {
        src: '/assets/timeline/parent-timeline/Age13-NeonCoffee.gif',
        props: {
          position: 'absolute',
          width: '98.36px',
          height: '97.76px',
          left: '205px',
          bottom: '73px',
          transform: 'rotate(23.32deg)',
          alt: 'Neon Coffee bottom right',
        },
      },
    ],
  },
  '14': {
    html: `
      <p>By 14, kids have hopefully learned the lesson that making money takes discipline and hard work. Hahahaha. Actually, they&rsquo;ve learned that making money requires a TikTok channel and an influencer contract. NO MATTER where your 14-year-old is at in their journey, this lesson will ensure that even if your kid thinks making money is easy, they can still keep their “easy money” working hard.</p>
      <h2>Start Here.</h2>
      <p>Here&rsquo;s the math:</p>
      <p>$20 a week <strong>saved</strong> over 35 years, will net you $36,400.</p>
      <p>$20 a week <strong>invested</strong> over 35 years can build a portfolio of about $290K.</p>
      <p>Investing over time is considered one of the most reliable and consistent ways to build wealth. So, let&rsquo;s get real about it.</p>
      <h2>Keep Going.</h2>
      <p>An Arizona State University study examined the <strong>26,000 stocks traded on U.S. exchanges</strong> in the last 100 years.</p>
      <p>Here are the results:</p>
      <ul>
        <li>1,000 stocks account for all profits</li>
        <li>86 stocks account for half of all gains</li>
        <li>The average stock has traded for seven years and lost money.</li>
      </ul>
      <h2>Get This.</h2>
      <p>Excuuuse me?! Do not panic. Those numbers need some serious context!</p>
      <p>Wealthie accounts focus on the long term, because in the history of the markets, over a 20-year period, stock markets have always gone up.</p>
      <p>History <em>also</em> shows that diversification is key. To boost the odds of holding at least one (or part of one) of those 1,000 winning stocks in a portfolio, it&rsquo;s helpful to have exposure to a lot of them.</p>
      <p>Exchange-Traded Funds — ETFs — are one way to get broad market exposure and diversification. These are investments that trade on an exchange, like regular stocks. Their funds made up of little slices of multiple stocks that move with the market.</p>
      <p>If you'd like to learn more about ETFs, great news: your 14-year-old owns one! You can check on your ETF by logging into your investment account.</p>
    `,
    images: [
      {
        src: '/assets/timeline/parent-timeline/Age14-MoneyAlien.png',
        props: {
          alt: 'Money Alien',
          marginBottom: '40px',
        },
      },
    ],
  },
  '15': {
    html: `
      <p>Your 15-year-old&rsquo;s life is starting to get hectic. With the demands of high school ramping up, university or college looming in the not-so-distance, and a busier social life, your kid may be interested in making some money of their own. It&rsquo;s a great time for a part-time or summer job, and an excellent time to build the skills for both.</p>
      <h2>Start Here.</h2>
      <p>First though, <em>value</em>. At 15 — with pressure to fit in AND grow up — understanding value can get complicated. This is the age when clothing and taste can start to dictate acceptance. Your kid may want money to buy whatever they&rsquo;re seeing around them at school, and while they can do what they like with their money, it literally pays to get clear about a few things.</p>
      <p>As your kid&rsquo;s thinking about where to work and what to do with their time, find ways to encourage them to look at what they value in their life. Whatever the answers — family, pets, friends, trips, home, the environment, their Xbox — how many of the items on their list cost money?</p>
      <p>It&rsquo;s an obvious takeaway, but one that can use a refresher from time to time. Money doesn&rsquo;t always equal value.</p>
      <p>Translate that into the workforce, and choosing a job you love over one that pays more, for example, might connect <em>value</em> to your <em>values</em>. Similarly, the tradeoff of a lower salary for work you care about (and actually enjoy), or a healthier life balance, may not feel like a tradeoff at all.</p>
      <p>Money is a tool, not an end point. Instill this in your 15-year-old, and you&rsquo;ll give them one of the most valuable guides to life.</p>
      <h2>Get This.</h2>
      <p>Sitting down with your kid to talk about how to write a CV, fill out an application form, and ace a job interview are skills that will benefit them for decades. And once they&rsquo;ve gotten the job (thanks to your excellent tutelage), prepare them for the deductions they&rsquo;ll see on their pay cheques. They can come as a big surprise. Your kid may think working 10 hours at $10/hour may net them $100. Alas, as we wise, experienced ones know, it does not.</p>
      <p>Research shows that students who work more than 15 hours a week are more likely to drop out of high school, so try to make that the max. Your kid may have to learn how to set boundaries with a boss who&rsquo;s asking for more time. It&rsquo;s good practice for a healthy work/life balance. Working is good. But so is not working. Learning how to healthily hit the balance is key to happier living.</p>
      <h2>Keep Going.</h2>
      <p>While it&rsquo;s tempting to help them get out of bed to actually get to their jobs, experts agree that you pretty much have to leave your teen to it, or risk enabling bad habits. Agony, we know (but so is a world filled with 20-somethings who need their parents as an alarm clock). Let them make mistakes. Let them be late for work or double book themselves, so they can learn the consequences. As always, be a guide, offer advice, and help them when they need it. But let them also learn about adulting on their own.</p>
    `,
    outerImages: [
      {
        src: '/assets/timeline/parent-timeline/Age15-GamerTeen.png',
        props: {
          alt: 'Gamer Gear',
          marginBottom: '20px',
          width: '377.41px',
          maxWidth: 'none',
          position: 'absolute',
          left: '-15px',
        },
      },
    ],
  },
  '16': {
    html: `
      <p>Not every family owns a car, and not every kid is ready to get behind the wheel. But in most places in the U.S. and Canada, 16 is the magical age when the person who was once your baby is legally allowed to drive.</p>
      <p>We are as frightened by this prospect as you are.</p>
      <h2>Start Here.</h2>
      <p>Let&rsquo;s start with the budgeting piece. If your 16-year-old is cutting out Aston-Martin pics for their vision board it&rsquo;s a great time to remind them that at no point should transportation ever take up more than about 15% of a monthly budget.</p>
      <h2>Get This.</h2>
      <p>If your kid is vying to borrow the family car, leverage the teaching moments for all they&rsquo;re worth.</p>
      <ul>
        <li>Charge them for car insurance. Your kid is a new driver and, let&rsquo;s face it, you&rsquo;ll think they&rsquo;re a menace behind the wheel until they&rsquo;re 50. Charge them insurance to help get them accustomed to costs associated with owning your ride, to insure your peace of mind, and to help ease the sting of your premiums going up now that you have a teen driver in the house.</li>
        <li>How much do you spend for gas and routine maintenance? Will your teen pitch in for gas?</li>
        <li>How much are your monthly car payments? Do you own or lease, and what are the advantages of each? If you have a car loan, how much will you end up paying in interest over the course of the loan? Maybe your teen can cover the interest.</li>
      </ul>
      <h2>Keep Going.</h2>
      <p>There are a lot of household costs that start to pile up at 16 — food for ravenous appetites, higher insurance costs, clothes and sporting equipment. If you think you&rsquo;re doing your teen a favour by shouldering it all, Jerald G. Bachman says maybe not.</p>
      <p>Bachman, a University of Michigan professor and an expert on behavior during the transition to adulthood cautions that kids who have cash to splash around on non-essentials while parents cover the basics can fall prey to “premature affluence”. It&rsquo;s an inaccurate experience of the world that can really chip away at a young person&rsquo;s financial literacy. Financial literacy actually deteriorates if it&rsquo;s not put into practice, so get practicing. Have your teen find ways to contribute to household finances, their sports equipment, or their investment account.</p>
      <p>We particularly like Option Three.</p>
    `,
    outerImages: [
      {
        src: '/assets/timeline/parent-timeline/Age16-SprayCans.png',
        props: {
          alt: 'Spray Cans',
          width: '415px',
          maxWidth: 'none',
          position: 'absolute',
          left: '-35px',
          bottom: '30px',
        },
      },
    ],
  },
  '17': {
    html: `
      <p>Your kid is probably eyeing the real world with envy, counting the days to living the dream of renting a split bachelor in a 12-story walk-up. Use this time to casually LARP their future, so that once they&rsquo;re out on their own, they&rsquo;ve given life on their own some thought.</p>
      <h2>Start Here.</h2>
      <p>Budget. If you and your kid dislike this word as much as we do, find something that feels more motivating and empowering. They&rsquo;re stepping into a powerful period of possibility in their lives. Their view of money should reflect that, even while they&rsquo;re living on very little.</p>
      <ul>
        <li><strong>A Spending Plan</strong> keeps the focus on planning for spending, rather than restricting it.</li>
        <li><strong>A Financial Plan</strong> can include plans for the future as well as the present. Bonus: It sounds fancy.</li>
        <li><strong>An Expense Tracker</strong> can help track money going out. So, as your teenager gets used to new bills and financial realities, they&rsquo;re less likely to be eating Cup Noodles at the end of every month for the rest of their youth.</li>
      </ul>
      <h2>Keep Going.</h2>
      <p>What are the things they&rsquo;ll need to pay for once they&rsquo;re on their own? Maybe they&rsquo;ve forgotten to include the gas bill, the electric bill, or the grocery bills, in their back-of-the-napkin calculation of the rent they can afford. Help your kid establish a spending plan, and get focused on the things that are easiest to forget &ndash; bills, a rainy day fund, lease payments, car insurance or a subway pass, and food/vet bills for a pet if they plan to adopt one. (Build this last item into <em>your</em> budget too, in the event said pet is dropped off on your doorstep in a basket…)</p>
      <p>It&rsquo;s a good time to talk about short- versus long-term financial planning. Your kid&rsquo;s Wealthie account, for example, is a long-term investment. But, if they&rsquo;ll soon need money for rent and other short-term expenses, they may want to consider “money market” investments such as GICs and term deposits to provide some light interest.</p>
      <h2>Get This.</h2>
      <p>Which brings us to life itself. Humans adapt. When we make more (or less) money, we tend to expand or shrink into our new circumstances accordingly. Setting a benchmark of up to 15% as a constant investment goal will help to keep things on the level. Of course, life creeps up.</p>
      <p>It&rsquo;s a low-stakes time to lay down some gentle boundaries that will help your kid understand how life&rsquo;s going to change once they&rsquo;re living on their own. Walk through what might happen if the semester-long budget, for example, gets blown in week two.</p>
      <p>Are they setting money aside for spending? Is it a good time to pick up a gig for a few extra bucks? If you&rsquo;re having these conversations with your kid, then we&rsquo;re confident they&rsquo;re a financially literate wizard at 17.</p>
      <p>Now, let them have the experience of walking into a job interview and crushing it.</p>
    `,
    images: [
      {
        src: '/assets/timeline/parent-timeline/Age17-Batteries&Bolts.png',
        props: {
          marginBottom: '40px',
          alt: 'Batteries and Bolts',
        },
      },
    ],
  },
  '18': {
    html: `
      <p>Let&rsquo;s take a moment, because friend, you have ARRIVED! Maybe you&rsquo;ve listed the bunk bed and you&rsquo;re turning their room into an art studio. Maybe you&rsquo;re trying to bribe your kid into living with you for the rest of their life. Wherever you are, this is a milestone. Congratulations.</p>
      <p>Take credit for your epic parenting, and now immediately teach your 18-year-old the basics of credit.</p>
      <h2>Start Here.</h2>
      <p>Credit can be confusing for a young person. If it&rsquo;s not explained well and early, it&rsquo;s the kind of thing that can spiral, fast. Have a conversation with your kid about credit now, so you can quietly take credit for their good habits later.</p>
      <p>Explain it. Credit is the ability to borrow money now with the promise/guarantee you&rsquo;ll pay it back later. Credit isn&rsquo;t free. It comes with interest, and that interest is usually determined by how good your kid&rsquo;s credit is. We&rsquo;ve really come full circle.</p>
      <p>Interest is the cost of using credit. Interest is great when it&rsquo;s helping to grow your savings. It&rsquo;s a drag when it&rsquo;s working on your debt.</p>
      <p>Credit cards can help keep your kids safer while shopping online. They are helpful in an emergency. Aaaand, they can give young adults a false and immediate sense of wealth.</p>
      <p>Let&rsquo;s face it, credit cards can feel like free money. So, some helpful ground rules to share with your 18-year-old:</p>
      <p>#1: If you can&rsquo;t afford it with cash, you can&rsquo;t afford it on credit.</p>
      <p>#2: Pay your card in full and on time, every month.</p>
      <p>#3: There are no other rules. Except for this one: <em>don&rsquo;t spend more than 30% of the maximum your card allows. Everrrrr.</em></p>
      <p>Now there are no other rules.</p>
      <h2>Keep Going.</h2>
      <p>Used properly, a credit card helps to build good credit, and a good credit score makes life easier.</p>
      <p>Credit scores are considered in the approval of loans, phone contracts, rental applications, mortgages, insurance. They are looked at by employers. They impact the interest rates offered on loans and car leases. A low credit score, or no credit score at all, makes life harder and, often, more expensive.</p>
      <h2>Get This.</h2>
      <p>Credit cards make it easy to spend money. Studies have shown that people will spend up to 100% more when they buy with credit and not cash.</p>
      <p>Remember the Rule of 72 we covered when your kid was 13? Well, The Rule works for debt too. With the average credit card interest rate hovering at around 17 percent, it takes a credit card company only four years to double the money they&rsquo;re squeezing from your kid.</p>
      <p>So, choose wisely! There are lots of good credit cards for first time users. Sites like creditcards.com and ratehub.ca will direct your 18-year-old to low interest or cashback cards with no annual fees. Some cards will promise free perks and rewards. Before your kid inhales the air miles, have them look up the APR (the Annual Percentage Rate) and the annual fee on each of the cards they&rsquo;re considering. These are two separate fees. And there are more. When it comes to credit cards, <em>free</em>... is relative.</p>
      <p>But speaking of free, take a look at your glorious 18-year-old! They&rsquo;re ready for the world and, thanks to your nudges and planning, they&rsquo;ve got a nest egg that&rsquo;s ready for them.</p>
      <p>Take a moment to look at what you&rsquo;ve built together. And then put the fear of a mother into them and ensure that they NEVER TOUCH THAT MONEY!!! Keep it growing.</p>
      <p>We&rsquo;re kidding. Sort of. This is the beginning of the rest of their lives. And now, thanks to you, this, and them, they&rsquo;re ready for anything.</p>
      <p>XO.</p>
    `,
    images: [
      {
        src: '/assets/timeline/parent-timeline/Age18-GraduationHat.png',
        props: {
          margin: 'auto',
          alt: 'Graduation Hat',
          marginBottom: '40px',
        },
      },
    ],
  },
};
export default data;
