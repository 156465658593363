import React from 'react';
import { Image } from '@chakra-ui/react';

import {
  NodeContainer,
  NodeContainerSmall,
  NodeHeading,
  NodeModal,
  NodeText,
  NodeVideoPlayer,
  NodeHeadingH3,
  NodeListItem,
  NodeList,
} from '../NodeComponents';
import { NodeModalProps } from './types';

const age = 10;
const nodeNumber = 0;

const ModalA10N2: React.FunctionComponent<NodeModalProps> = ({
  isOpen,
  onClose,
}) => (
  <NodeModal isOpen={isOpen} onClose={onClose}>
    <Image
      src="/assets/timelineModalMedia/WWD-Acct-KidTimeline-Age10-Batman-01:23-N2-Hero.png"
      alt="Batman Helmet"
    />
    <NodeContainer>
      <NodeHeading>Economics of Being Batman</NodeHeading>
      <NodeText>
        Yes, Batman&apos;s got a lot to juggle, keeping Gotham safe from the
        Riddler and Penguin. But, that&apos;s not all that&apos;s on his mind.
        Like any business titan running a multi-billion dollar enterprise while
        moonlighting as a caped crusader, Batman&apos;s got to stay on top of
        his budget.
      </NodeText>
      <NodeText>We&apos;ve peered into the BatWallet.</NodeText>
    </NodeContainer>
    <Image
      src="/assets/timelineModalMedia/WWD-Acct-KidTimeline-Age10-Bats-01:23-N2-1.png"
      alt="Bats"
    />
    <NodeContainerSmall>
      <NodeHeadingH3>$13.04 billion: Total net worth</NodeHeadingH3>
      <NodeText>
        Ten years ago a group of econ students (and obvious DC Comic fans) at
        Lehigh University estimated Batman&apos;s wealth at 11.6 billion USD,
        ($13.04B in today&apos;s dollars). It&apos;s mostly the result of
        inheritance after his parents&apos; brutal murder. But, B. Wayne
        deserves some credit, too. He&apos;s put his money to work at Wayne
        Enterprises. Being mega wealthie is actually Batman&apos;s superpower.
        He doesn&apos;t have superhuman strength, x-ray vision, or even spider
        powers. He just knows how to make every buck count.
      </NodeText>
    </NodeContainerSmall>
    <Image
      src="/assets/timelineModalMedia/WWD-Acct-KidTimeline-Age10-BatmanMansion-01:23-N2-2.png"
      alt="Wayne Manor"
    />
    <NodeContainerSmall>
      <NodeHeadingH3>$137 million: Market value of Wayne Manor</NodeHeadingH3>
      <NodeText>
        Bruce inherited his 42,500 square foot, 11 bedroom, luxury lair from his
        parents when they died. Since then, it&apos;s been destroyed several
        times by various enemies.
      </NodeText>
      <NodeText>
        Without considering the costs of all those rebuilds, here&apos;s what
        Batman budgets annually:
        <NodeList>
          <NodeListItem>$1.6M: annual upkeep</NodeListItem>
          <NodeListItem>$37,000: property taxes</NodeListItem>
          <NodeListItem>
            $27,000: house insurance (and that&apos;s monthly!)
          </NodeListItem>
        </NodeList>
      </NodeText>
    </NodeContainerSmall>
    <NodeContainerSmall>
      <NodeHeadingH3>$41,250: The Batsuit</NodeHeadingH3>
      <NodeText>
        This is what the costume worn by Michael Keaton in Batman Returns
        fetched at auction. If you&apos;d prefer a functional memory cloth
        polymer cape (the kind that stiffens to facilitate flying from building
        to building), that&apos;s going to set you back $40,000 on its own.
      </NodeText>
    </NodeContainerSmall>
    <NodeContainerSmall>
      <NodeHeadingH3>$11, 588, 928: Annual life insurance policy</NodeHeadingH3>
      <NodeText>
        It&apos;s not cheap being a batguy in the big city. According to Vantis
        Life, a US insurance company that scores factors like dangerous
        situations, exposure to firearms, and alcohol consumption, Batman is one
        of the most valuable clients in Gotham or anywhere.
      </NodeText>
    </NodeContainerSmall>
    <NodeVideoPlayer
      url="/assets/timelineModalMedia/WWD-Acct-KidTimeline-Age10-Butler-01:23-N2-3.mp4"
      ageId={age}
      nodeNumber={nodeNumber}
      index={0}
    />
    <NodeContainerSmall>
      <NodeHeadingH3>$1.5MM: Salary for Alfred Pennyworth</NodeHeadingH3>
      <NodeText>
        The starting salary for a butler is around $30/hour. However, the
        highest paid butler on the planet — a guy named Gary Williams, who works
        for a billionaire in Miami — rakes in a reported $2.2 million a year.
        Keep in mind that Batman&apos;s right hand has a fairly extensive job
        description, and some top shelf job experience. Alfred&apos;s LinkedIn
        page includes stints with British spy agencies MI5 and MI6. He spends as
        much time supporting his boss in special ops as he does delivering tea.
        He&apos;s also been on the job since Bruce was seven, so we&apos;ve
        factored in a few substantial raises.
      </NodeText>
    </NodeContainerSmall>
    <Image
      src="/assets/timelineModalMedia/WWD-Acct-KidTimeline-Age10-BatmanNewspaper-01:23-N2-4.png"
      alt="Daily Planet"
    />
    <NodeContainerSmall>
      <NodeHeadingH3>
        $12.3MM: Estimated Price to Buy the Daily Planet
      </NodeHeadingH3>
      <NodeText>
        That&apos;s right, Batman is technically Superman&apos;s boss, having
        bought the newspaper where Clark Kent works as a bespectacled reporter.
        It wasn&apos;t cheap (and print media is a risky investment these days).
        But the cost of owning the competition: Priceless.
      </NodeText>
    </NodeContainerSmall>
    <NodeVideoPlayer
      url="/assets/timelineModalMedia/WWD-Acct-KidTimeline-Age10-Joker-01:23-N2-5.mp4"
      isGif
      ageId={age}
      nodeNumber={nodeNumber}
      index={1}
    />
    <NodeContainerSmall>
      <NodeText>Financial advice from Batman&apos;s nemesis:</NodeText>
      <NodeText>
        “If you&apos;re good at something, never do it for free.”
      </NodeText>
      <NodeText>- The Joker, &apos;The Dark Knight&apos;</NodeText>
    </NodeContainerSmall>
  </NodeModal>
);

export default ModalA10N2;
