import * as React from 'react';
import { Spin } from 'antd';
import { useParams, useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { useDispatch } from 'react-redux';
import { verifyInvitation } from '../../store/reducers/authSlice';
import WealthieHelmet from '../../components/WealthieHelmet';

function VerifyGiftInvitation(): React.ReactElement {
  const { inviteToken } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  React.useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(() => {
        // user is logged in
        dispatch(verifyInvitation(inviteToken, history));
      })
      .catch(() => {
        // user is not logged yet, save to local storage and then call when login
        localStorage.setItem('@invite', inviteToken);
        history.push('/');
      });
  }, [inviteToken, history, dispatch]);

  return (
    <div
      style={{
        flex: 1,
        minHeight: 500,
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
      }}
    >
      <WealthieHelmet title="Verifying" />
      <Spin
        data-testid="verify_invite_page"
        style={{ alignSelf: 'center' }}
        spinning
        tip="Verifing your invitation"
      />
    </div>
  );
}

export default VerifyGiftInvitation;
