import React from 'react';
import {
  NodeContainer,
  NodeHeading,
  NodeModal,
  NodeText,
  NodeVideoPlayer,
  NodeHashtags,
} from '../NodeComponents';
import { NodeModalProps } from './types';

const age = 7;
const nodeNumber = 0;

const ModalA7N1: React.FunctionComponent<NodeModalProps> = ({
  isOpen,
  onClose,
}) => (
  <NodeModal isOpen={isOpen} onClose={onClose}>
    <NodeVideoPlayer
      url="/assets/timelineModalMedia/WWD-Acct-KidTimeline-Age7-Rocket.mp4"
      isGif
      ageId={age}
      nodeNumber={nodeNumber}
      index={0}
    />
    <NodeContainer>
      <NodeHeading>Thinking of Building a Rocket.</NodeHeading>
      <NodeText>
        NASA, the National Aeronautics and Space Administration, has always set
        the standard for rocket-making, rocket-launching, and rocket pops. Okay,
        maybe not rocket pops. Those were created by Merritt Foods in 1955 and
        are technically called “Bomb Pops”. Anyway, NASA makes rockets in the
        Vehicle Assembly Building at Florida&apos;s Kennedy Space Centre.
      </NodeText>
      <NodeText>
        To build and launch a rocket, you&apos;ll need a giant warehouse and a
        launch pad. Oh, and a rocket. So, how much will it all cost? NASA says
        it could cost <b>$1.6 billion</b> just to blast off!
      </NodeText>
    </NodeContainer>
    <NodeVideoPlayer
      url="/assets/timelineModalMedia/WWD-Acct-KidTimeline-Age7-RocketBuild-01:23-N1-1.mp4"
      isGif
      ageId={age}
      nodeNumber={nodeNumber}
      index={1}
    />
    <NodeContainer>
      <NodeText>
        You can get a pack of 6 Rocket Pops for about $5.50. You won&apos;t
        learn as much about space and science, but they do taste really good.
      </NodeText>
      <NodeHashtags>#ToTheMoon</NodeHashtags>
    </NodeContainer>
  </NodeModal>
);

export default ModalA7N1;
