import React from 'react';
import {
  NodeHeading,
  NodeListItem,
  NodeList,
  NodeModal,
  NodeText,
  NodeVideoPlayer,
  NodeContainer,
} from '../NodeComponents';
import { NodeModalProps } from './types';

const age = 3;
const nodeNumber = 0;

const ModalA3N1: React.FunctionComponent<NodeModalProps> = ({
  isOpen,
  onClose,
}) => (
  <NodeModal isOpen={isOpen} onClose={onClose}>
    <NodeVideoPlayer
      url="/assets/timelineModalMedia/WWD-Acct-KidTimeline-Age3-Knitcoin1-01:23-N1.mp4"
      ageId={age}
      nodeNumber={nodeNumber}
      index={0}
    />
    <NodeContainer>
      <NodeHeading>Knitcoin #1: Squirrel Saves!</NodeHeading>
      <NodeText>
        Welcome to Knitcoin, the first in a series of short videos for our
        shortest investors.
      </NodeText>
      <NodeText>
        By the age of 3, kids grasp basic concepts about money and how it can be
        used. This is a great time to introduce the habit of saving and how it
        helps money grow over time. Frankly, it&apos;s a decent lesson to learn
        &#40;or re-learn!&#41; at any age.
      </NodeText>
      <NodeText>
        Here are some questions to help start a conversation with your little
        investor:
      </NodeText>
      <NodeList isAcorn>
        <NodeListItem isAcorn>
          How do you think Squirrel makes money?
        </NodeListItem>
        <NodeListItem isAcorn>
          Can people make their own money, too?
        </NodeListItem>
        <NodeListItem isAcorn>What is a FUN way to make money?</NodeListItem>
      </NodeList>
    </NodeContainer>
  </NodeModal>
);

export default ModalA3N1;
