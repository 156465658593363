import React from 'react';
import { Box, UnorderedList, ListItem } from '@chakra-ui/react';
import InfoFormLabel from '../InfoFormLabel/InfoFormLabel';

const InfoPasswordLabel = (): React.ReactElement => (
  <InfoFormLabel
    htmlFor="password"
    infoPopoverProps={{
      questionMarkSize: 'sm',
      popoverText: (
        <Box>
          Reminder! Your password must contain at least:
          <UnorderedList size="sm" paddingLeft="15px">
            <ListItem>8 characters, </ListItem>
            <ListItem>1 uppercase, </ListItem>
            <ListItem>1 lowercase</ListItem>
            <ListItem>
              1 special symbol <br />
              (e.g., ! ,#, $ etc.)
            </ListItem>
          </UnorderedList>
        </Box>
      ),
      popoverProps: {
        offset: [68, 15],
      },
      analyticsSrc: 'password',
    }}
    label="Password"
  />
);

export default InfoPasswordLabel;
