import React from 'react';
import { Circle } from '@chakra-ui/react';

interface DotsProps {
  isSelectedDot: boolean;
}

const SelectionDot: React.FunctionComponent<DotsProps> = ({ isSelectedDot }) =>
  isSelectedDot ? (
    <Circle size="14px" background="black" data-testid="selected-dot" />
  ) : (
    <Circle
      size="14px"
      borderColor="black"
      borderWidth="2px"
      data-testid="unselected-dot"
    />
  );

export default SelectionDot;
