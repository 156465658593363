import React from 'react';
import {
  NodeContainer,
  NodeHeading,
  NodeModal,
  NodeText,
  NodeVideoPlayer,
  NodeHashtags,
} from '../NodeComponents';
import { NodeModalProps } from './types';

const age = 7;
const nodeNumber = 1;

const ModalA7N2: React.FunctionComponent<NodeModalProps> = ({
  isOpen,
  onClose,
}) => (
  <NodeModal isOpen={isOpen} onClose={onClose}>
    <NodeVideoPlayer
      url="/assets/timelineModalMedia/WWD-Acct-KidTimeline-Age7-XMarksSpot-01:23-N2-Hero.mp4"
      isGif
      ageId={age}
      nodeNumber={nodeNumber}
      index={0}
    />
    <NodeContainer>
      <NodeHeading>X marks the spot</NodeHeading>
      <NodeText>
        Back on earth, American Peter Diamandis started the competition in 1996
        called the X Prize. He wanted someone &#40;a private company…not
        NASA&#41; to build a spacecraft that could carry three people to the
        edge of space - 100 km &#40;62 miles&#41; above the earth&apos;s
        surface. To prove it worked, they had to launch the spacecraft twice
        within two weeks.
      </NodeText>
    </NodeContainer>
    <NodeVideoPlayer
      url="/assets/timelineModalMedia/WWD-Acct-KidTimeline-Age7-XMarksSpot-01:23-N2-1.mp4"
      isGif
      ageId={age}
      nodeNumber={nodeNumber}
      index={1}
    />
    <NodeContainer>
      <NodeText>
        The prize was <b>10 million bucks</b>. 26 companies from around the
        world competed. A company owned by Microsoft co-founder Paul Allen and
        aerospace engineer Burt Rutan won the prize in <b>2004</b>. They spent
        over $25 million to win the $10 million prize. Doesn&apos;t sound like a
        great investment, huh? Well, it wasn&apos;t all about the prize money.
        The X Prize made space tourism a real possibility. And now, it&apos;s a
        reality &#40;well, if you have tens of millions of dollars to spend on a
        12-minute flight on a sunny afternoon&#41;.
      </NodeText>
    </NodeContainer>
    <NodeVideoPlayer
      url="/assets/timelineModalMedia/WWD-Acct-KidTimeline-Age7-XMarksSpot-01:23-N2-2.mp4"
      isGif
      ageId={age}
      nodeNumber={nodeNumber}
      index={2}
    />
    <NodeContainer>
      <NodeHashtags>#spacetourism</NodeHashtags>
    </NodeContainer>
  </NodeModal>
);

export default ModalA7N2;
