import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { get } from 'lodash';
import * as Yup from 'yup';
import {
  Button,
  Center,
  FormControl,
  FormErrorMessage,
  Input,
  Text,
  Box,
} from '@chakra-ui/react';

import { Formik, Form, Field } from 'formik';

import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from 'react-google-recaptcha-v3';

import Header from '../../layout/Header/Header';
import Container from '../../components/Container';

import {
  showNotification,
  // ERROR_TYPE,
  INFO_TYPE,
} from '../../components/Notification';

import { forgotPasswordApi, reCaptchaKey } from '../../apis';
import Divider from '../../components/Divider/Divider';
import SignUp from '../../components/SignUp/SignUp';
import ActivityIndicator from '../../components/ActivityIndicator';
import FormStack from '../../components/FormStack';
import SectionContainer from '../../components/SectionContainer';
import FormHeading from '../../components/FormHeading';
import InfoFormLabel from '../../components/InfoFormLabel/InfoFormLabel';
import BodyContainer from '../../components/BodyContainer';
import WealthieHelmet from '../../components/WealthieHelmet';

const ResetSchema = Yup.object().shape({
  email: Yup.string().required('Email is required'),
});

function ForgotPassword(): React.ReactElement {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const onSubmit = useCallback(
    async (values, actions) => {
      // handle stuff
      try {
        setIsLoading(true);
        if (!executeRecaptcha) {
          actions.setErrors({
            email: 'Error with form.  Please reload and try again',
          });
          setIsLoading(false);
          return false;
        }
        const recaptchaToken = await executeRecaptcha('forgotPassword');
        await forgotPasswordApi({
          ...values,
          token: recaptchaToken,
        });
        setIsLoading(false);
        showNotification({
          type: INFO_TYPE,
          message:
            'If we found your email, then we will send you an activation code. Please check your inbox.',
        });
        history.push({
          pathname: '/reset-pwd',
          state: { email: values.email },
        });
      } catch (error) {
        setIsLoading(false);
        showNotification({
          type: 'error',
          message: get(
            error,
            'data.error.message',
            'Something went wrong.  Please try again shortly',
          ),
        });
      }
    },
    [executeRecaptcha, history],
  );

  return (
    <Container data-testid="forgot-pwd-page">
      <WealthieHelmet title="Forgot Password" />
      <Header headerStyle="backArrow" showNav />
      <SectionContainer>
        <FormHeading
          title="Forgot Password"
          text="Please enter your email address and we will send you instructions on how to reset your password."
        />
      </SectionContainer>
      <BodyContainer>
        <Formik
          initialValues={{ email: '' }}
          onSubmit={onSubmit}
          validationSchema={ResetSchema}
        >
          {(formik) => (
            <Form>
              <FormStack data-testid="forgot-pwd-form">
                <Field name="email">
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={form.errors.email && form.touched.email}
                    >
                      <InfoFormLabel
                        label="Email Address"
                        htmlFor="email"
                        infoPopoverProps={{
                          questionMarkSize: 'sm',
                          popoverText: (
                            <Box>
                              <Text>
                                We will only send an email if an account with
                                that email address already exists in our system.
                              </Text>
                              <br />
                              <Text>
                                If an activation email does not arrive, please
                                confirm that you are using the email address
                                that you registered with.
                              </Text>
                            </Box>
                          ),
                          popoverProps: {
                            offset: [26, 0],
                          },
                          analyticsSrc: 'email_adress',
                        }}
                      />
                      <Input
                        {...field}
                        id="email-id"
                        data-testid="email-id"
                        type="email"
                        // placeholder="Email Address"
                        required
                      />
                      <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Center>
                  <Button
                    isLoading={formik.isSubmitting}
                    disabled={!formik.isValid}
                    type="submit"
                    variant="action"
                    colorScheme="accent"
                    data-testid="submit-button"
                    // The gap from the container is 20px so I added 30px to get the right spacing
                    marginTop="30px"
                  >
                    RESET
                  </Button>
                </Center>
              </FormStack>
            </Form>
          )}
        </Formik>
        <Divider />
        <SignUp />
        {isLoading && <ActivityIndicator tip="Working" />}
        <Box marginBottom="50px" />
      </BodyContainer>
    </Container>
  );
}

const ForgotPasswordWrapper = (props: any): React.ReactElement => (
  <GoogleReCaptchaProvider reCaptchaKey={reCaptchaKey}>
    <ForgotPassword {...props} />
  </GoogleReCaptchaProvider>
);

export default ForgotPasswordWrapper;
