import { Container, Image } from '@chakra-ui/react';
import React from 'react';
import {
  NodeContainer,
  NodeContainerSmall,
  NodeHeading,
  NodeHeadingH3,
  NodeModal,
  NodeText,
  NodeVideoPlayer,
} from '../NodeComponents';
import { NodeModalProps } from './types';

const age = 14;
const nodeNumber = 0;

const ModalA14N1: React.FunctionComponent<NodeModalProps> = ({
  isOpen,
  onClose,
}) => (
  <NodeModal isOpen={isOpen} onClose={onClose}>
    <NodeVideoPlayer
      url="/assets/timelineModalMedia/WWD-Acct-KidTimeline-Age14-SpongeBob-01:23-N1-Hero.mp4"
      isGif
      ageId={age}
      nodeNumber={nodeNumber}
      index={0}
    />
    <Container paddingX="32.5px" display="flex" flexDir="column" gap="5px">
      <NodeHeading>SpongeBob SquarePants</NodeHeading>
      <NodeText>Real Estate Rockstar</NodeText>
    </Container>
    <NodeContainerSmall>
      <NodeText>
        When you think about the greatest investors of all time, SpongeBob
        SquarePants likely doesn&apos;t clear your top 5. But, do not
        underestimate the Sponge! Bob&apos;s made at least one killer
        investment. He owns hishome! And that hollowed-out pineapple is now
        allegedly worth…18 Million Dollars.
      </NodeText>
      <NodeText>
        That&apos;s almost 3X what Drake paid for his Toronto mansion.
      </NodeText>
    </NodeContainerSmall>
    <NodeContainerSmall>
      <NodeHeadingH3>
        So what lessons can you soak up from this real estate rockstar?
      </NodeHeadingH3>
      <NodeText>
        According to his driver&apos;s licence, SpongeBob was born on July 14,
        1986. SpongeBob&apos;s a Millennial! A Millennial with money moves.
      </NodeText>
      <NodeText>
        He&apos;s a devoted fry cook at the Krusty Krab. He&apos;s got a good
        work ethic, but his income&apos;s inconsistent. Bob needs to work on his
        self worth! According to Spongepedia, Bob claims to make under ten cents
        a year. He also occasionally pays his boss, Mr. Krabs, $100 an hour for
        the privilege of working. (We don&apos;t recommend this approach to
        employment.) We&apos;ve seen him pocket an envelope of cash on payday,
        so he pulls in a haul now and then, but checking his resumé, we think
        Bob may be the original gig worker.
      </NodeText>
    </NodeContainerSmall>
    <Image
      src="/assets/timelineModalMedia/WWD-Acct-KidTimeline-Age14-SpongeBob-01:23-N1-1.png"
      alt="Spongebob eyes"
    />

    <NodeContainerSmall>
      <NodeHeadingH3>The Takeaway</NodeHeadingH3>
      <NodeText>
        Bob&apos;s been side hustling since he was 12— jouster, chef, lifeguard,
        lawyer, the list goes on. He started early, and put time to work in his
        favour. He&apos;s clearly put money away and learned a thing or two
        about compound interest — that&apos;s when the interest on your money
        joins the principal, and all of that money starts making interest.
        It&apos;s your money making money, and the joke is that it&apos;s the
        8th Wonder of the World.
      </NodeText>
      <NodeText>But back to Bob…</NodeText>
      <NodeText>
        Is he investing? Probably. He&apos;s sure invested a lot of time over at
        Mrs. Puff&apos;s Boating School, where he&apos;s failed to get his
        boating license 1 258 058 times. Silver lining? He&apos;s saving money
        on gas and boat payments, and likely putting that money to work in the
        markets in a diversified portfolio.
      </NodeText>
    </NodeContainerSmall>
    <Image
      src="/assets/timelineModalMedia/WWD-Acct-KidTimeline-Age14-SpongeBoat-01:23-N1-2.png"
      alt="Sail Boat"
    />
    <NodeContainer>
      <NodeText>
        Finally, Bob knows a deal when he sees one. Submerged “land” is
        considerably cheaper to buy than its above sea equivalent. In Canada, a
        man recently listed two lots, “presently underwater,” for the bargain
        price of $99,000.
      </NodeText>
      <NodeText>Location, location… floatation!</NodeText>
    </NodeContainer>
  </NodeModal>
);

export default ModalA14N1;
