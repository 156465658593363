import { GiftCardType } from '../store/reducers/homeSlice';

const trackEvent = (name: string, params: any = {}) => {
  window.gtag('event', name, params);
};

export const setAnalyticsUserProperties = (
  loginType: 'parent' | 'kid' | 'anonymous' = 'anonymous',
): void => {
  window.gtag('set', 'user_properties', {
    login_type: loginType,
  });
};

// Menu
export const trackMenuOpen = (): void => {
  trackEvent('open_navigation_menu');
};
export const trackMenuClose = (): void => {
  trackEvent('close_navigation_menu');
};
// Badge
export const trackBadgeSwipe = (
  swipeDirection: 'left' | 'right',
  // prevSlide: number,
  // nextSlide: number,
): void => {
  trackEvent('badge_swipe', {
    swipe_direction: swipeDirection,
    // prev_slide: prevSlide,
    // next_slide: nextSlide,
  });
};
// Tooltip
export const trackTooltipOpen = (analyticsSrc: string): void => {
  trackEvent('tooltip_open', { tooltip_type: analyticsSrc });
};
export const trackTooltipClose = (analyticsSrc: string): void => {
  trackEvent('tooltip_close', { tooltip_type: analyticsSrc });
};
// Age Section
export const trackAgeSectionOpen = (ageId: number): void => {
  trackEvent('age_section_opened', { age_id: ageId });
};
export const trackAgeSectionClosed = (): void => {
  trackEvent('age_section_closed');
};
// Avatar Select
export const trackAvatarSelect = (avatarId: number): void => {
  trackEvent('avatar_select', { avatar_id: avatarId });
};
// Gift card modal
export const trackGiftCardModalOpen = (
  giftCardDetails: GiftCardType | null,
): void => {
  const giftCardParams = {
    gift_card_id: giftCardDetails?.id,
    gift_card_type_id: giftCardDetails?.giftCardTypeId,
    gift_card_sku: giftCardDetails?.giftCardGameName,
  };
  trackEvent('open_giftcard_modal', giftCardParams);
};
export const trackGiftCardModalClose = (
  giftCardDetails: GiftCardType | null,
): void => {
  const giftCardParams = {
    gift_card_id: giftCardDetails?.id,
    gift_card_type_id: giftCardDetails?.giftCardTypeId,
    gift_card_sku: giftCardDetails?.giftCardGameName,
  };
  trackEvent('close_giftcard_modal', giftCardParams);
};
// Node Modal
export const trackNodeModalOpen = (ageId?: number, nodeId?: number): void => {
  trackEvent('open_node_modal', { node_id: nodeId, age_id: ageId });
};
export const trackNodeModalClose = (ageId?: number, nodeId?: number): void => {
  trackEvent('close_node_modal', { node_id: nodeId, age_id: ageId });
};
// Password
export const trackShowPassword = (): void => {
  trackEvent('show_password');
};
export const trackHidePassword = (): void => {
  trackEvent('hide_password');
};
// Login/Logout
export const trackLogin = (): void => {
  trackEvent('login');
};
export const trackLogout = (): void => {
  trackEvent('logout');
};
export const trackParentSignup = (parentId: number): void => {
  trackEvent('parent_signup', { parent_id: parentId });
};
export const trackParentEdit = (): void => {
  trackEvent('parent_edit');
};
// Assign Modal
export const trackAssignModalOpen = (): void => {
  trackEvent('assign_modal_open');
};
export const trackAssignModalClose = (): void => {
  trackEvent('assign_modal_close');
};
export const trackAssignGiftcard = (): void => {
  trackEvent('assign_gift_card');
};
// Child Profile
export const trackChildSignup = (
  assignsGiftcard: boolean,
  giftCardId?: number,
): void => {
  trackEvent('child_signup', {
    assigns_giftcard: assignsGiftcard,
    gift_card_id: giftCardId,
  });
};
export const trackChildEdit = (): void => {
  trackEvent('child_edit');
};
// Video
export const trackVideoPlay = (
  src: string,
  nodeId: number | null,
  ageId: number,
  isGif: boolean,
  playerId: number | null,
): void => {
  trackEvent('video_play', {
    media_file_name: src,
    node_id: nodeId,
    age_id: ageId,
    is_gif: isGif,
    player_id: playerId,
  });
};
export const trackVideoPause = (
  src: string,
  nodeId: number | null,
  ageId: number,
  isGif: boolean,
  playerId: number | null,
): void => {
  trackEvent('video_pause', {
    media_file_name: src,
    node_id: nodeId,
    age_id: ageId,
    is_gif: isGif,
    player_id: playerId,
  });
};
export const trackVideoMuted = (
  src: string,
  nodeId: number | null,
  ageId: number,
  isGif: boolean,
  playerId: number | null,
): void => {
  trackEvent('video_mute', {
    media_file_name: src,
    node_id: nodeId,
    age_id: ageId,
    is_gif: isGif,
    player_id: playerId,
  });
};
export const trackVideoUnmuted = (
  src: string,
  nodeId: number | null,
  ageId: number,
  isGif: boolean,
  playerId: number | null,
): void => {
  trackEvent('video_unmute', {
    media_file_name: src,
    node_id: nodeId,
    age_id: ageId,
    is_gif: isGif,
    player_id: playerId,
  });
};
export const trackVideoEnded = (
  src: string,
  nodeId: number | null,
  ageId: number,
  isGif: boolean,
  playerId: number | null,
): void => {
  trackEvent('video_played_at_end', {
    media_file_name: src,
    node_id: nodeId,
    age_id: ageId,
    is_gif: isGif,
    player_id: playerId,
  });
};
// Audio
export const trackAudioPlay = (
  src: string,
  playerId: number | null,
  nodeId: number | null,
  ageId: number,
): void => {
  trackEvent('audio_play', {
    media_file_name: src,
    player_id: playerId,
    node_id: nodeId,
    age_id: ageId,
  });
};
export const trackAudioPause = (
  src: string,
  playerId: number | null,
  nodeId: number | null,
  ageId: number,
): void => {
  trackEvent('audio_pause', {
    media_file_name: src,
    player_id: playerId,
    node_id: nodeId,
    age_id: ageId,
  });
};
export const trackAudioMuted = (
  src: string,
  playerId: number | null,
  nodeId: number | null,
  ageId: number,
): void => {
  trackEvent('audio_mute', {
    media_file_name: src,
    player_id: playerId,
    node_id: nodeId,
    age_id: ageId,
  });
};
export const trackAudioUnmuted = (
  src: string,
  playerId: number | null,
  nodeId: number | null,
  ageId: number,
): void => {
  trackEvent('audio_unmute', {
    media_file_name: src,
    player_id: playerId,
    node_id: nodeId,
    age_id: ageId,
  });
};
export const trackAudioEnded = (
  src: string,
  playerId: number | null,
  nodeId: number | null,
  ageId: number,
): void => {
  trackEvent('audio_played_at_end', {
    media_file_name: src,
    player_id: playerId,
    node_id: nodeId,
    age_id: ageId,
  });
};
// Open Game
export const trackOpenGiftCardExperience = (
  giftCardId?: number,
  giftCardTypeId?: number,
  giftCardSku?: string,
): void => {
  trackEvent('open_gift_card_experience', {
    giftcard_id: giftCardId,
    gift_card_type_id: giftCardTypeId,
    gift_card_sku: giftCardSku,
  });
};

// Product Tour
export const trackProductTourStart = (tourType: string): void => {
  trackEvent('product_tour_start', {
    tour_type: tourType,
  });
};
export const trackProductTourClose = (
  tourType: string,
  currentStep: number,
): void => {
  trackEvent('product_tour_close', {
    tour_type: tourType,
    current_step: currentStep,
  });
};
export const trackProductTourComplete = (
  tourType: string,
  currentStep: number,
): void => {
  trackEvent('product_tour_complete', {
    tour_type: tourType,
    current_step: currentStep,
  });
};
export const trackProductTourNext = (
  tourType: string,
  nextStep: number,
): void => {
  trackEvent('product_tour_next', {
    tour_type: tourType,
    next_step: nextStep,
  });
};

// Gift Card Video
export const trackGiftCardVideoPlay = (
  videoName: string,
  giftCardId: number,
  giftCardSku: string,
): void => {
  trackEvent('giftcard_video_play', {
    media_file_name: videoName,
    gift_card_type_id: giftCardId,
    gift_card_ski: giftCardSku,
  });
};
export const trackGiftCardVideoPause = (
  videoName: string,
  giftCardId: number,
  giftCardSku: string,
): void => {
  trackEvent('giftcard_video_pause', {
    media_file_name: videoName,
    gift_card_type_id: giftCardId,
    gift_card_ski: giftCardSku,
  });
};
export const trackGiftCardVideoMute = (
  videoName: string,
  giftCardId: number,
  giftCardSku: string,
): void => {
  trackEvent('giftcard_video_mute', {
    media_file_name: videoName,
    gift_card_type_id: giftCardId,
    gift_card_ski: giftCardSku,
  });
};
export const trackGiftCardVideoUnmute = (
  videoName: string,
  giftCardId: number,
  giftCardSku: string,
): void => {
  trackEvent('giftcard_video_unmute', {
    media_file_name: videoName,
    gift_card_type_id: giftCardId,
    gift_card_ski: giftCardSku,
  });
};
export const trackGiftCardVideoPlayToEnd = (
  videoName: string,
  giftCardId: number,
  giftCardSku: string,
): void => {
  trackEvent('giftcard_video_play_to_end', {
    media_file_name: videoName,
    gift_card_type_id: giftCardId,
    gift_card_ski: giftCardSku,
  });
};
