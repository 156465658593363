import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { get } from 'lodash';
import { setAnalyticsUserProperties } from '../../lib/analytics';
import { isKidAuthSelector } from '../../store/reducers/authSlice';
import { RootState } from '../../store/reducers';

interface PublicRouteProps {
  component?: any;
  path: string;
  exact: boolean;
  allowAuth?: boolean;
}

const PublicRoute = ({
  component: Component,
  path,
  exact,
  allowAuth = false,
  ...rest
}: PublicRouteProps): React.ReactElement => {
  const isAuthenticated = useSelector((state) =>
    get(state, 'authReducer.isAuthenticated'),
  );
  const isKid = useSelector((state: RootState) => isKidAuthSelector(state));
  useEffect(() => {
    if (!isAuthenticated) {
      setAnalyticsUserProperties('anonymous');
    } else {
      setAnalyticsUserProperties(isKid ? 'kid' : 'parent');
    }
  }, [isAuthenticated, isKid]);
  return (
    <Route
      exact={exact}
      {...rest}
      path={path}
      render={(props) =>
        !isAuthenticated || allowAuth ? (
          <Component {...props} />
        ) : (
          <Redirect to="/home" />
        )
      }
    />
  );
};

export default React.memo(PublicRoute);
