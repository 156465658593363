import { extendTheme } from '@chakra-ui/react';
// Global style overrides
import styles from './styles';
// Foundation Overrides
import typography from './foundations/typography';
import colors from './foundations/colors';
// Component style overrides
import Button from './components/button';
import Heading from './components/heading';
import FormLabel from './components/formlabel';
import FormControl from './components/formControl';
import Input from './components/input';
import Select from './components/select';
import Text from './components/text';
import Accordion from './components/accordion';
import Link from './components/link';
import Popover from './components/popover';
import textStyles from './textSyles';

const overrides = {
  styles,
  textStyles,
  ...typography,
  colors,
  shadows: {
    outline: {
      zIndex: 1,
      borderColor: 'purple',
      boxShadow: '0 0 0 1px purple',
    },
  },
  components: {
    Accordion,
    Button,
    FormLabel,
    FormControl,
    Heading,
    Input,
    Select,
    Link,
    Text,
    Popover,
    // ModalContent,
    // ModalBody: ModalContent,
  },
  breakpoints: {
    sm: '450px',
    md: '830px',
    lg: '960px',
    xl: '1200px',
    '2xl': '1536px',
  },
};
export default extendTheme(overrides);
