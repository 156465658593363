import React from 'react';
import {
  NodeContainer,
  NodeHeading,
  NodeList,
  NodeListItem,
  NodeModal,
  NodeVideoPlayer,
} from '../NodeComponents';
import { NodeModalProps } from './types';

const age = 5;
const nodeNumber = 0;

const ModalA5N1: React.FunctionComponent<NodeModalProps> = ({
  isOpen,
  onClose,
}) => (
  <NodeModal isOpen={isOpen} onClose={onClose}>
    <NodeVideoPlayer
      url="/assets/timelineModalMedia/WWD-Acct-KidTimeline-Age5-Knitcoin3-01:23-N1.mp4"
      ageId={age}
      nodeNumber={nodeNumber}
      index={0}
    />
    <NodeContainer>
      <NodeHeading>Knitcoin #3: Squirrel Spends!</NodeHeading>
      <NodeList isAcorn>
        <NodeListItem isAcorn>
          Squirrel&apos;s made a friend! Give Owl a name!
        </NodeListItem>
        <NodeListItem isAcorn>
          How much does Owl&apos;s flower cost? How can you tell?
        </NodeListItem>
        <NodeListItem isAcorn>
          What&apos;s the greatest thing that money can buy?
        </NodeListItem>
      </NodeList>
    </NodeContainer>
  </NodeModal>
);

export default ModalA5N1;
