import React, { useMemo } from 'react';
import { AccordionPanel, Box, Image } from '@chakra-ui/react';
import parse from 'html-react-parser';

import ageData from './agedata';
import styles from './styles.module.scss';
import { isEmpty } from 'lodash';

interface Props {
  age: number;
}

const imageHeightsPerAge = {
  '0': '399px',
  '1': '229px',
  '2': '493px',
  '3': '0px',
  '4': '0px',
  '5': '0px',
  '6': '0px',
  '7': '0px',
  '8': '584px',
  '9': '0px',
  '10': '0px',
  '11': '0px',
  '12': '0px',
  '13': '376.93px',
  '14': '0px',
  '15': '486.14px',
  '16': '694.44px',
  '17': '0px',
  '18': '0px',
};

const images = {
  image0: require('../../../assets/images/timeline/numbers/Chat-Numbers-System-01.svg'),
  image1: require('../../../assets/images/timeline/numbers/Chat-Numbers-System-02.svg'),
  image2: require('../../../assets/images/timeline/numbers/Chat-Numbers-System-03.svg'),
  image3: require('../../../assets/images/timeline/numbers/Chat-Numbers-System-04.svg'),
  image4: require('../../../assets/images/timeline/numbers/Chat-Numbers-System-05.svg'),
  image5: require('../../../assets/images/timeline/numbers/Chat-Numbers-System-06.svg'),
  image6: require('../../../assets/images/timeline/numbers/Chat-Numbers-System-07.svg'),
  image7: require('../../../assets/images/timeline/numbers/Chat-Numbers-System-08.svg'),
  image8: require('../../../assets/images/timeline/numbers/Chat-Numbers-System-09.svg'),
  image9: require('../../../assets/images/timeline/numbers/Chat-Numbers-System-10.svg'),
  image10: require('../../../assets/images/timeline/numbers/Chat-Numbers-System-11.svg'),
  image11: require('../../../assets/images/timeline/numbers/Chat-Numbers-System-12.svg'),
  image12: require('../../../assets/images/timeline/numbers/Chat-Numbers-System-13.svg'),
  image13: require('../../../assets/images/timeline/numbers/Chat-Numbers-System-14.svg'),
  image14: require('../../../assets/images/timeline/numbers/Chat-Numbers-System-15.svg'),
  image15: require('../../../assets/images/timeline/numbers/Chat-Numbers-System-16.svg'),
  image16: require('../../../assets/images/timeline/numbers/Chat-Numbers-System-17.svg'),
  image17: require('../../../assets/images/timeline/numbers/Chat-Numbers-System-18.svg'),
  image18: require('../../../assets/images/timeline/numbers/Chat-Numbers-System-19.svg'),
};

function renderImage(image, age, index) {
  const props = image.props ? image.props : {};
  return (
    <Image
      {...props}
      src={image.src}
      // alt={`Age ${age}`}
      align="center"
      fit="contain"
      key={`${image.src}_${index}`}
    />
  );
}

function renderImages(images, age) {
  return images.map((image, index) => renderImage(image, age, index));
}

function AgeBlock(props: Props): React.ReactElement {
  const { age } = props;
  const ageBlockData = useMemo(() => {
    if (!ageData[age]) {
      return <p></p>;
    }
    return parse(ageData[age].html);
  }, [age]);
  const image = useMemo(() => {
    let image = images[`image${age}`];
    if (!image) {
      image = images['image0'].default;
    } else {
      image = image.default;
    }
    return image;
  }, [age]);

  const bottomImages = useMemo(() => {
    if (!ageData[age] || isEmpty(ageData[age].images)) {
      return null;
    }
    return renderImages(ageData[age].images, age);
  }, [age]);

  const outerImages = useMemo(() => {
    if (!ageData[age] || isEmpty(ageData[age].outerImages)) {
      return null;
    }
    return renderImages(ageData[age].outerImages, age);
  }, [age]);

  return (
    <>
      <AccordionPanel
        bg="brand.lightBackground"
        px={0}
        py={0}
        position="relative"
        borderColor="brand.titleHighlight"
        borderLeftWidth="2px"
        borderRightWidth="2px"
        borderBottomWidth="2px"
        borderBottomRadius="8px"
      >
        <Box className={styles.ageBlockContainer} px="18.5px">
          <Image
            src={image}
            alt={`Age ${age}`}
            align="left"
            // w="60%"
            // marginLeft="-30px"
            // marginTop="-10px"
            marginBottom="20px"
          />
          {ageBlockData}
          {bottomImages}
        </Box>
        <Box
          position="relative"
          height={imageHeightsPerAge[age]}
          overflow="hidden"
        >
          {outerImages}
        </Box>
      </AccordionPanel>
    </>
  );
}

export default AgeBlock;
