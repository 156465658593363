import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { get, isEmpty } from 'lodash';
import {
  Box,
  Button,
  Container as BaseContainer,
  Heading,
  Text,
} from '@chakra-ui/react';

import Header from '../../layout/Header/Header';
import ChildDetail from '../../components/ChildDetail';

import {
  setCurrentChild,
  getParentProfile,
  selectParent,
} from '../../store/reducers/homeSlice';

import { RootState } from '../../store/reducers';
import Container from '../../components/Container';
import ActivityIndicator from '../../components/ActivityIndicator';
import AddChildText from '../../components/AddChildText';
import ItemWithInfo from '../../components/ItemWithInfo';
import WealthieHelmet from '../../components/WealthieHelmet';

function MyKids(): React.ReactElement {
  const history = useHistory();
  const dispatch = useDispatch();
  const children = useSelector((state) => get(state, 'homeReducer.children'));
  const parent = useSelector((state: RootState) => selectParent(state));

  useEffect(() => {
    dispatch(getParentProfile());
  }, [dispatch]);

  useEffect(() => {
    if (parent.parentGiftCards && !isEmpty(parent.parentGiftCards)) {
      history.replace({
        pathname: '/assign-gift-cards',
        state: {
          giftId: parent.parentGiftCards[0].id,
          gift: parent.parentGiftCards[0],
        },
      });
    }
  }, [history, parent]);

  const onClickAddChild = () => {
    history.push('/child-sign-up');
  };

  const onClickViewKid = useCallback(
    (kid) => () => {
      dispatch(setCurrentChild({ ...kid }));
      history.push({
        pathname: `/child-timeline/${get(kid, 'kid.id')}`,
      });
    },
    [dispatch, history],
  );

  const KidSection = (kid) => (
    <Box
      display={'flex'}
      flexDirection={'column'}
      alignItems="center"
      marginBottom="30px"
    >
      <ChildDetail hasEdit isLarge {...kid.kid} kid={kid.kid} enableClickKid />
      <Button
        alignContent="center"
        onClick={onClickViewKid(kid)}
        variant="action"
      >
        View Timeline
      </Button>
    </Box>
  );

  return (
    <Container data-testid="my_kids_page">
      <WealthieHelmet title="My Kids" />
      <Header headerStyle="hamburger" showNav />
      <BaseContainer centerContent>
        <ItemWithInfo
          infoText={
            <>
              <Text>
                Here, you can edit a kid&apos;s profile, view thier gift
                experiences, and add another kid&apos;s profile!
              </Text>
            </>
          }
          anchorComponent={
            <Heading variant="title" mb="0">
              Kids + Cards
            </Heading>
          }
          questionMarkSize="md"
          analyticsSrc="Kids + cards"
        />

        {(children || []).map((kid) => (
          <KidSection kid={kid} key={kid.id} />
        ))}

        <Box
          mt="20px"
          mb="50px"
          alignContent="center"
          alignItems="center"
          display="flex"
          flexDirection="column"
          gap="20px"
        >
          <Button
            data-testid="add_button"
            onClick={onClickAddChild}
            variant="action"
            colorScheme="accent"
          >
            ADD A KID
          </Button>
          <AddChildText parentsChildren={children} />
        </Box>
      </BaseContainer>
      {parent && parent.isLoading && <ActivityIndicator tip="Working" />}
    </Container>
  );
}

export default MyKids;
