import playMeImage from '../../assets/images/giftcardassets/playMe.png';

// Gift Card images

import batMath from '../../assets/images/giftcardassets/cards/batmath.png';
import dinoEgg from '../../assets/images/giftcardassets/cards/dinoegg.png';
import superHero from '../../assets/images/giftcardassets/cards/superhero.png';

import codePanda from '../../assets/images/giftcardassets/cards/codepanda.png';
import coloringBook from '../../assets/images/giftcardassets/cards/coloringbook.png';
import connectTheRoads from '../../assets/images/giftcardassets/cards/connecttheroads.png';
import dragonIsland from '../../assets/images/giftcardassets/cards/dragonisland.png';
import gigglyParkAdventure from '../../assets/images/giftcardassets/cards/gigglyparkadventure.png';
import seaShoreTreasure from '../../assets/images/giftcardassets/cards/seashoretreasure.png';

import TheBestOfBothWorlds from '../../assets/images/giftcardassets/cards/the-best-of-both-worlds.png';
import TheDancingOne from '../../assets/images/giftcardassets/cards/the-dancing-one.png';
import TheOriginalOne from '../../assets/images/giftcardassets/cards/the-original-one.png';
import ThePlayingOne from '../../assets/images/giftcardassets/cards/the-playing-one.png';
import TheRobotOverlordOne from '../../assets/images/giftcardassets/cards/the-robot-overlord-one.png';
import TheSingingOne from '../../assets/images/giftcardassets/cards/the-singing-one.png';

const giftCardNames = {};
giftCardNames['dino-egg'] = dinoEgg;
giftCardNames['bat-math'] = batMath;
giftCardNames['super-hero-memory'] = superHero;

giftCardNames['code-panda'] = codePanda;
giftCardNames['coloring-book'] = coloringBook;
giftCardNames['connect-the-roads'] = connectTheRoads;
giftCardNames['dragon-island'] = dragonIsland;
giftCardNames['giggly-park-adventure'] = gigglyParkAdventure;
giftCardNames['seashore-treasure'] = seaShoreTreasure;

giftCardNames['theBestOfBothWorlds'] = TheBestOfBothWorlds;
giftCardNames['theDancingOne'] = TheDancingOne;
giftCardNames['theOriginalOne'] = TheOriginalOne;
giftCardNames['thePlayingOne'] = ThePlayingOne;
giftCardNames['theRobotOverlordOne'] = TheRobotOverlordOne;
giftCardNames['theSingingOne'] = TheSingingOne;

const backgroundImages: any[] = [];
const giftBoxImages: any[] = [];

for (let i = 0; i < 7; i++) {
  backgroundImages[
    i
  ] = require(`../../assets/images/giftcardassets/backgrounds/Asset${
    i + 1
  }_lrg.png`).default;
}

for (let i = 0; i < 14; i++) {
  giftBoxImages[
    i
  ] = require(`../../assets/images/giftcardassets/boxes/Timeline-GiftBoxes-${
    i + 13
  }.png`).default;
}

function backgroundImage(seed: number): string {
  const seedIndex = seed % backgroundImages.length;
  return backgroundImages[seedIndex];
}

function boxImage(seed: number): string {
  const seedIndex = seed % giftBoxImages.length;
  return giftBoxImages[seedIndex];
}

function cardImageFromName(name: string): string {
  return giftCardNames[name];
}

export {
  backgroundImage,
  cardImageFromName,
  boxImage,
  playMeImage as openMeImage,
};
