import React from 'react';
// import type { ModalProps } from '@chakra-ui/react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Text,
  Center,
  ModalHeader,
  ModalFooter,
  Box,
  Button,
} from '@chakra-ui/react';
import { ReactComponent as ErrorX } from '../../assets/images/icons/error-x.svg';
import { ReactComponent as Success } from '../../assets/images/icons/success.svg';

interface Props {
  modalContent?: string | React.ReactElement;
  modalType?: 'none' | 'success' | 'error';
  isOpen: boolean;
  onClose: () => void;
  children?: any;
  hasClose?: boolean;
}

const CustomModal = ({
  isOpen,
  onClose,
  modalType,
  modalContent,
  children,
  hasClose,
}: Props): React.ReactElement => {
  const Icon = () => {
    switch (modalType) {
      case 'success': {
        return (
          <Center
            marginBottom="30px"
            textAlign="center"
            display="flex"
            flexDir="column"
            gap="30px"
          >
            <Box>
              <Success />
            </Box>
            <Text
              fontSize="smallTitle"
              fontWeight="smallText"
              lineHeight="medText"
            >
              Success!
            </Text>
          </Center>
        );
      }
      case 'error': {
        return (
          <Center marginBottom="30px" textAlign="center">
            <ErrorX />
          </Center>
        );
      }
      default: {
        return null;
      }
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent
        bg="brand.grey50"
        paddingX="50px"
        paddingY="30px"
        maxWidth="345px"
        borderRadius="20px"
      >
        {Icon() && <ModalHeader p="0px">{Icon()}</ModalHeader>}
        {children}
        {!children && (
          <>
            <ModalBody textAlign="center">{modalContent}</ModalBody>
          </>
        )}
        {hasClose && (
          <ModalFooter justifyContent={'center'} p="0">
            <Button variant="text" fontWeight="smallText" onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
};

{
  /* <Modal isOpen={visible} onClose={handleCancel} isCentered>
  <ModalOverlay />
  <ModalContent
    bg="brand.grey50"
    paddingX="50px"
    paddingY="30px"
    maxWidth="345px"
    borderRadius="20px"
  >
    <ModalBody p="0px" mb="30px">
      <Text fontSize="smallTitle" lineHeight="medText" fontWeight="smallText">
        Confirm that you&apos;re assigning this Gift Card to{' '}
        <Text fontWeight="medText">
          {currentChild?.firstName} {currentChild?.lastName}!
        </Text>
        <br />
        This action can&apos;t be undone.
      </Text>
    </ModalBody>
    <ModalFooter justifyContent={'flex-start'} alignItems="center" p="0px">
      <Button
        mr={3}
        size={'md'}
        // p="20px"
        onClick={handleOk}
        variant="action"
      >
        Confirm
      </Button>
      <Button
        mr={3}
        size={'md'}
        onClick={handleCancel}
        // mt="1.5rem"
        variant="text"
        fontSize="smallTitle"
        lineHeight="medText"
        fontWeight="smallText"
      >
        Cancel
      </Button>
    </ModalFooter>
  </ModalContent>
</Modal>; */
}

export default CustomModal;
