import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

import { RootState } from '../../store/reducers';
import {
  getParentProfile,
  GiftCardType,
  selectChildren,
  selectGiftCards,
} from '../../store/reducers/homeSlice';
import GameContainer from './GameContainer';

function ChildDetail(): React.ReactElement {
  const { childID, cardId } = useParams();
  const dispatch = useDispatch();
  const children = useSelector((state: RootState) => selectChildren(state));
  const currentChild = useMemo(
    () => children.find((child) => String(child.id) === String(childID)),
    [children, childID],
  );
  useEffect(() => {
    dispatch(getParentProfile());
  }, [dispatch]);
  const [currentCard, setCurrentCard] = useState<GiftCardType | null>(null);
  const giftCards = useSelector((state: RootState) => selectGiftCards(state));
  useEffect(() => {
    if (currentChild && giftCards) {
      const childGiftCards = giftCards.filter(
        (giftCard) => String(giftCard.childId) === String(currentChild.id),
      );
      if (childGiftCards && !isEmpty(childGiftCards)) {
        const selectedCard = childGiftCards.find(
          (card) => String(card.id) === String(cardId),
        );
        if (selectedCard) {
          setCurrentCard(selectedCard);
        }
      }
    }
  }, [cardId, currentChild, giftCards, setCurrentCard]);

  return <GameContainer cardId={cardId} currentCard={currentCard} />;
}

export default ChildDetail;
