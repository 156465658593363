import React from 'react';
import { Image } from '@chakra-ui/react';

import {
  NodeContainer,
  NodeHeading,
  NodeModal,
  NodeText,
  NodeHashtags,
} from '../NodeComponents';
import { NodeModalProps } from './types';

const ModalA8N2: React.FunctionComponent<NodeModalProps> = ({
  isOpen,
  onClose,
}) => (
  <NodeModal isOpen={isOpen} onClose={onClose}>
    <Image
      src="/assets/timelineModalMedia/WWD-Acct-KidTimeline-Age8-Lemonade-01:23-N2-Hero.png"
      alt="The Buzz"
    />
    <NodeContainer>
      <NodeHeading>The Buzz</NodeHeading>
      <NodeText>
        So you want to start your own business... You want to be an
        entrepreneur, huh? Go for it! Why not start, as many entrepreneurs have,
        with a lemonade stand? That&apos;s what Mikaila Ulmer from Austin, Texas
        did.
      </NodeText>
    </NodeContainer>
    <Image
      src="/assets/timelineModalMedia/WWD-Acct-KidTimeline-Age8-Lemonade-01:23-N2-1.png"
      alt="Black and White bees"
    />
    <NodeContainer>
      <NodeText>
        Mikaila was stung by bee…twice in one week! Not cool, bees… not cool.
        But Mikaila decided to turn lemons into lemonade. She researched bees
        and learned about all the good stuff that bees do.
      </NodeText>
    </NodeContainer>
    <Image
      src="/assets/timelineModalMedia/WWD-Acct-KidTimeline-Age8-Lemonade-01:23-N2-2.png"
      alt="Bee"
    />
    <NodeContainer>
      <NodeText>
        About <b>one of every three mouthfuls of our food</b> is thanks to
        pollination by a bee! The pollen they leave around as they fly from
        flower to flower helps plants grow. They are one of nature&apos;s most
        potent and natural fertilizers
      </NodeText>
    </NodeContainer>
    <Image
      src="/assets/timelineModalMedia/WWD-Acct-KidTimeline-Age8-Lemonade-01:23-N2-3.png"
      alt="Lemons"
    />
    <NodeContainer>
      <NodeText>
        Back to Mikaila. She decided to use a recipe for lemonade in her
        Grandmother&apos;s cookbook from the 1940s. She replaced the sugar in
        the recipe with honey (thanks bees!) and opened a lemonade stand. And
        her lemonade sold like…well, it sold like really delicious lemonade on a
        hot summer day. Oh, and she gave a percentage of her profits to groups
        <b> saving honeybees.</b>
      </NodeText>
    </NodeContainer>
    <Image
      src="/assets/timelineModalMedia/WWD-Acct-KidTimeline-Age8-Lemonade-01:23-N2-4.png"
      alt="Cash Register"
    />
    <NodeContainer>
      <NodeText>
        By the time <b>she was nine</b>, Mikaila&apos;s company, Me & The Bees
        Lemonade, was on shelves at <b>Whole Foods Markets.</b> She became one
        of the youngest business owners in the U.S., and in 2018 sold more than
        <b> 360,000 bottles</b> of her lemonade.
      </NodeText>
      <NodeHashtags>#honey #lemonade</NodeHashtags>
    </NodeContainer>
  </NodeModal>
);

export default ModalA8N2;
