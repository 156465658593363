import React from 'react';
import {
  Box,
  Heading,
  UnorderedList,
  ListItem,
  Link,
  HeadingProps,
} from '@chakra-ui/react';

import Container from '../../components/Container';
import Header from '../../layout/Header/Header';
import WealthieHelmet from '../../components/WealthieHelmet';

const StyledTextBox = ({ children }) => (
  <Box display="flex" gap="10px" flexDir="column">
    {children}
  </Box>
);

const SmallText = ({ children }) => (
  <Box fontSize="subText" fontWeight="smallText" lineHeight="smallText">
    {children}
  </Box>
);

const HeadingH2 = ({ children }) => (
  <Heading as="h2" variant="subTitle">
    {children}
  </Heading>
);
const HeadingH3 = (props: HeadingProps) => (
  <Heading as="h3" variant="small" {...props}>
    {props.children}
  </Heading>
);

const PrivacyPolicy = (): React.ReactElement => (
  <Container>
    <WealthieHelmet title="Privacy Policy" />
    <Header showNav headerStyle="backArrow" />
    <Box
      display="flex"
      gap="30px"
      flexDir="column"
      marginBottom="50px"
      paddingX="10.5px"
    >
      <Box>
        <Heading as="h1" textAlign="center" marginBottom="20px" variant="title">
          Privacy Policy
        </Heading>
        <SmallText>
          This Privacy Policy describes how your personal information is
          collected, used, and shared when you visit wealthie.works (the “Site”
          or “Wealthie”).
        </SmallText>
      </Box>
      <StyledTextBox>
        <HeadingH2>Personal information we do not collect</HeadingH2>
        <SmallText>
          While we work with several trusted partners and vendors for marketing
          and site optimization purposes, Wealthie Works Daily Inc. does not
          store any of your personal information (including transactional data)
          on our owned accounts. Instead, we work with trusted partners and
          vendors who collect and securely store this information on our behalf.
          The partners we work with, along with any information we leverage, are
          outlined in this Privacy Policy.
        </SmallText>
      </StyledTextBox>
      <StyledTextBox>
        <HeadingH2>Personal information we collect</HeadingH2>
        <SmallText>
          Personal information we do not collect While we work with several
          trusted partners and vendors for marketing and site optimization
          purposes, Wealthie Works Daily Inc. does not store any of your
          personal information (including transactional data) on our owned
          accounts. Instead, we work with trusted partners and vendors who
          collect and securely store this information on our behalf. The
          partners we work with, along with any information we leverage, are
          outlined in this Privacy Policy.
        </SmallText>
        <HeadingH3 marginTop="20px">
          We collect device information using the following technologies:
        </HeadingH3>
        <SmallText>
          When you visit the Site, we automatically collect certain information
          about your device, including information about your web browser, IP
          address, time zone, and some of the cookies that are installed on your
          device. Additionally, as you browse the Site, we collect information
          about the individual web pages or products that you view, what
          websites or search terms referred you to the Site, and information
          about how you interact with the Site. We refer to this
          automatically-collected information as “Device Information.”
          <br />
          <br />
          <UnorderedList paddingLeft="10px">
            <ListItem>
              “Cookies” are data files that are placed on your device or
              computer and often include an anonymous unique identifier. For
              more information about cookies, and how to disable cookies, visit{' '}
              <Link href="https://www.allaboutcookies.org" target="_blank">
                https://www.allaboutcookies.org
              </Link>
              .
            </ListItem>
            <ListItem>
              “Log files” track actions occurring on the Site, and collect data
              including your IP address, browser type, Internet service
              provider, referring/exit pages, and date/time stamps.
            </ListItem>
            <ListItem>
              “Web beacons,” “tags,” and “pixels” are electronic files used to
              record information about how you browse the Site.
            </ListItem>
          </UnorderedList>
          <br />
          Additionally when you sign up for our email distribution list or
          waitlist, we collect certain information from you, including your
          name, email address, and age. We refer to this information as
          “Waitlist Information.”
          <br />
          <br />
          The Data you provide during the Email Sign up process is stored
          through Hubspot, our email service provider. For information on their
          data storage policy pertaining to ‘Contacts’, please visit:{' '}
          <Link
            href="https://legal.hubspot.com/privacy-policy"
            target="hubspot"
          >
            https://legal.hubspot.com/privacy-policy
          </Link>
          <br />
          <br />
          The Data you provide for the Waitlist sign up process is also stored
          through Hubspot, our waitlist management provider. For more
          information on their data storage policy pertaining to waitlist
          contacts, please visit: <br />
          <Link
            href="https://legal.hubspot.com/privacy-policy"
            target="hubspot"
          >
            https://legal.hubspot.com/privacy-policy
          </Link>{' '}
          to the providers privacy policy.
          <br />
          <br />
          When we talk about “Personal Information” in this Privacy Policy, we
          are talking both about Device Information and Email/Waitlist
          Information.
        </SmallText>
      </StyledTextBox>
      <StyledTextBox>
        <HeadingH2>How we do we use your personal information?</HeadingH2>
        <SmallText>
          We use the Account Information that we collect generally to fulfill
          any accounts set up through the Site. Additionally, we use this
          Account Information to:
          <br />
          <br />
          <UnorderedList paddingLeft="10px">
            <ListItem>Communicate with you</ListItem>
            <ListItem>Screen for potential risk or fraud</ListItem>
            <ListItem>
              Provide you with information or advertising relating to our
              products or services, when it’s in line with the preferences you
              have shared with us.
            </ListItem>
          </UnorderedList>
          <br />
          We use the Device Information that we collect to help us screen for
          potential risk and fraud (in particular, your IP address), and more
          generally to improve and optimize our Site (for example, by generating
          analytics about how our customers browse and interact with the Site,
          and to assess the success of our marketing and advertising campaigns).
          <br />
          <br />
          We also use your Device Information on Lucky Orange – a tool that
          monitors user site visits and navigation for website optimization
          purposes. You can learn more about how Lucky Orange uses your Device
          Information here:{' '}
          <Link
            href="https://www.luckyorange.com/privacy.php"
            target="luckyorange"
          >
            https://www.luckyorange.com/privacy.php
          </Link>
          . You can opt-out of being tracked by Lucky Orange by visiting:{' '}
          <Link
            href="https://privacy.luckyorange.com/"
            target="luckyorange_privacy"
          >
            https://privacy.luckyorange.com/
          </Link>{' '}
          and selecting ‘Do Not Track Me’.
          <br />
          Device information is also used for interest-based Advertising and
          Retargeting. See our behavioural advertising section below.
        </SmallText>
      </StyledTextBox>
      <StyledTextBox>
        <HeadingH2>Your personal information</HeadingH2>
        <SmallText>
          We also use Google Analytics to help us understand how our customers
          use the Site–you can read more about how Google uses your Personal
          Information here:{' '}
          <Link href="https://www.google.com/intl/en/policies/privacy/">
            https://www.google.com/intl/en/policies/privacy/
          </Link>
          .
          <br />
          <br />
          You can also opt-out of Google Analytics here:{' '}
          <Link href="https://tools.google.com/dlpage/gaoptout" target="_blank">
            https://tools.google.com/dlpage/gaoptout.
          </Link>
          <br />
          <br />
          Finally, we may also share your Personal Information to comply with
          applicable laws and regulations, to respond to a subpoena, search
          warrant or other lawful request for information we receive, or to
          otherwise protect our rights.
          <br />
          <br />
          Personal information is also used for interest-based Advertising and
          Retargeting to market Wealthie’s own services. See behavioural
          advertising section below.
        </SmallText>
      </StyledTextBox>
      <StyledTextBox>
        <HeadingH2>Behavioural Advertising</HeadingH2>
        <SmallText>
          As described above, we use your Personal Information to provide you
          with targeted advertisements or marketing communications we believe
          may be of interest to you. For more information about how targeted
          advertising works, you can visit the Network Advertising Initiative’s
          (“NAI”) educational page here.
          <br />
          <br />
          You can opt out of targeted advertising by:
          <br />
          <br />
          <UnorderedList paddingLeft="10px">
            <ListItem>
              <Link href="https://www.facebook.com/settings/?tab=ads">
                FACEBOOK
              </Link>
            </ListItem>
            <ListItem>
              <Link href="https://www.google.com/settings/ads/anonymous">
                GOOGLE
              </Link>
            </ListItem>
          </UnorderedList>
          <br />
          Additionally, you can opt out of some of these services and others
          (Twitter, etc.) by visiting the Digital Advertising Alliance’s opt-out
          portal here.
        </SmallText>
      </StyledTextBox>
      <StyledTextBox>
        <HeadingH2>Do Not Track</HeadingH2>
        <SmallText>
          Please note that we do not alter our Site’s data collection and use
          practices when we see a Do Not Track signal from your browser.
        </SmallText>
      </StyledTextBox>
      <StyledTextBox>
        <HeadingH2>Marketing and advertising</HeadingH2>
        <SmallText>
          Wealthie will not use Personal Information of Minors for marketing
          purposes and will not knowingly direct or send marketing
          communications to a Primary or Secondary Student User or a Youth User.
          Wealthie may use Personal Information of other Users to market
          products and services of Wealthie and affiliated Wealthie companies to
          such Users, provided that (a) such use and marketing is consistent
          with applicable law and Wealthie’s legal obligations; (b) such
          Personal Information excludes Payment Data; (c) the Users are not
          Minors; (d) the Users have not opted out of receiving marketing; and
          (e) where required by applicable law, express or implied consent to
          marketing exists and has not been withdrawn. Wealthie may provide such
          Personal Information to a third party service provider on a restricted
          basis to provide such marketing solely on behalf of Wealthie and
          affiliated Wealthie companies, but not for marketing of any third
          party products or services. Wealthie does not permit third-party ad
          networks or similar services to access or collect Personal Information
          within the Services. Users may change their marketing preferences at
          any time. Wealthie will not knowingly direct or send marketing
          communications to a User who has expressed a preference not to receive
          marketing.
        </SmallText>
        <HeadingH3>Minors</HeadingH3>
        <SmallText>
          The Site does not collect information from Minors, however during the
          registration process Parents or Guardians may be asked to provide
          information about Minors they intend to register for Wealthie
          accounts. A special note for parents, legal guardians, grandparents
          and account registrants:
          <br />
          We take special steps to protect the privacy of information we obtain
          about the children in your lives, as defined in this Privacy
          statement.
        </SmallText>
      </StyledTextBox>
      <StyledTextBox>
        <HeadingH2>Compliance and data protection laws</HeadingH2>
        <HeadingH3>COPPA</HeadingH3>
        <SmallText>
          Wealthie’s Services are designed for Education and Information. The
          Services are not designed or intended to collect Personal Information
          from young children under the age of 13 for any commercial purpose. In
          the event the Children’s Online Privacy Protection Act (COPPA), 15
          U.S.C. 6501 et seq. is deemed to apply to the collection of any
          Personal Information under this Agreement, Wealthie will comply with
          all applicable provisions of COPPA and collect and use such
          information for educational purposes only at the direction of the
          Educator and on the basis of Educator consent.
        </SmallText>
        <HeadingH3>Other data protection laws</HeadingH3>
        <SmallText>
          Wealthie is committed to complying with PIPEDA and all other data
          protection laws applicable to its delivery of the Services.
        </SmallText>
      </StyledTextBox>
      <StyledTextBox>
        <HeadingH2>Your Rights</HeadingH2>
        <SmallText>
          If you are a European or Canadian resident, you have the right to
          access personal information we hold about you and to ask that your
          personal information be corrected, updated, or deleted. If you would
          like to exercise this right, please contact us through the contact
          information below.
          <br />
          <br />
          Additionally, if you are a European resident we note that we are
          processing your information in order to fulfill contracts we might
          have with you (for example if you make an order through the Site), or
          otherwise to pursue our legitimate business interests listed above.
          Additionally, please note that your information will be transferred
          outside of Europe, including to Canada and the United States.
        </SmallText>
      </StyledTextBox>
      <StyledTextBox>
        <HeadingH2>Changes</HeadingH2>
        <SmallText>
          We may update this privacy policy from time to time in order to
          reflect, for example, changes to our practices or for other
          operational, legal or regulatory reasons.
        </SmallText>
      </StyledTextBox>
      <StyledTextBox>
        <HeadingH2>Contact Us</HeadingH2>
        <SmallText>
          For more information about our privacy practices, if you have
          questions, or if you would like to make a complaint, please contact us
          by e-mail at admin@wealthie.works or by mail using the details
          provided below:
          <br />
          <br />
          141 Adelaide Street West, Toronto , ON, M5H 3L5, Canada
        </SmallText>
      </StyledTextBox>
    </Box>
  </Container>
);

export default PrivacyPolicy;
