import React from 'react';
import { ListItemProps, ListItem } from '@chakra-ui/react';

interface NodeListItemProps extends ListItemProps {
  isAcorn?: boolean;
}

const NodeListItem: React.FunctionComponent<NodeListItemProps> = ({
  children,
  isAcorn,
}) => {
  if (isAcorn) {
    return <ListItem>🌰 {children}</ListItem>;
  }
  return <ListItem>{children}</ListItem>;
};

export default NodeListItem;
