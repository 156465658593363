import React from 'react';
import { Text, TextProps } from '@chakra-ui/react';

const NodeSubHeading: React.FunctionComponent<TextProps> = ({ children }) => (
  <Text
    as="h2"
    fontWeight="smallText"
    fontSize="subTitleSection"
    lineHeight="medText"
  >
    {children}
  </Text>
);

export default NodeSubHeading;
