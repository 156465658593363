import React from 'react';
import { ListProps, List, UnorderedList } from '@chakra-ui/react';

interface NodeListProps extends ListProps {
  isAcorn?: boolean;
}

const NodeList: React.FunctionComponent<NodeListProps> = ({
  children,
  isAcorn,
}) => {
  if (isAcorn) {
    return (
      <List display="flex" gap="10px" flexDir="column">
        {children}
      </List>
    );
  }
  return (
    <UnorderedList display="flex" gap="10px" flexDir="column" paddingLeft="5px">
      {children}
    </UnorderedList>
  );
};

export default NodeList;
