import React, { useCallback, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { get } from 'lodash';
import * as Yup from 'yup';
import {
  Box,
  Button,
  Center,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Text,
} from '@chakra-ui/react';
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from 'react-google-recaptcha-v3';
import { Field, Form, Formik } from 'formik';

import Header from '../../layout/Header/Header';
import {
  Messages,
  showNotification,
  ERROR_TYPE,
} from '../../components/Notification';
import { confirmPasswordApi, reCaptchaKey } from '../../apis';
import Container from '../../components/Container';
import SectionContainer from '../../components/SectionContainer';
import FormHeading from '../../components/FormHeading';
import FormStack from '../../components/FormStack';
import ActivityIndicator from '../../components/ActivityIndicator';
import { passwordValidationRequired } from '../../lib/passwordValidation';
import InfoFormLabel from '../../components/InfoFormLabel/InfoFormLabel';
import PasswordInput from '../../components/PasswordInput';
import InfoPasswordLabel from '../../components/InfoPasswordLabel/InfoPasswordLabel';
import WealthieHelmet from '../../components/WealthieHelmet';

const ResetPasswordSchema = Yup.object().shape({
  ...passwordValidationRequired,
  confirmationCode: Yup.string()
    .required('Required')
    .length(6, 'Activation code must be exactly 6 characters'),
});

function ResetPassword(): React.ReactElement {
  const location = useLocation();
  const history = useHistory();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [isLoading, setIsLoading] = useState(false);
  const onSubmit = useCallback(
    async (values, actions) => {
      try {
        setIsLoading(true);
        const email = get(location, 'state.email', '');
        if (!executeRecaptcha) {
          actions.setErrors({
            confirmationCode: 'Error with form.  Please reload and try again',
          });
          setIsLoading(false);
          return false;
        }
        const recaptchaToken = await executeRecaptcha('confirmPassword');
        const params = {
          ...values,
          password: values.password,
          email,
          token: recaptchaToken,
        };
        await confirmPasswordApi(params);
        setIsLoading(false);
        history.push('/');
        showNotification({
          type: 'info',
          message: Messages.passwordResetSuccessfully,
        });
      } catch (error) {
        setIsLoading(false);
        const message = get(
          error,
          'response.data.error.message',
          Messages.somethingWentWrong,
        );
        showNotification({
          type: ERROR_TYPE,
          message,
        });
      }
    },
    [history, location, executeRecaptcha],
  );

  return (
    <Container data-testid="reset_pwd_page">
      <WealthieHelmet title="Reset Password" />
      <Header headerStyle="backArrow" showNav />
      <SectionContainer>
        <FormHeading
          title="Reset Password"
          text="Please enter your activation code and new password."
        />
      </SectionContainer>
      <Formik
        initialValues={{
          password: '',
          passwordRepeat: '',
          email: '',
        }}
        onSubmit={onSubmit}
        validationSchema={ResetPasswordSchema}
      >
        {(formik) => (
          <Form>
            <FormStack data-testid="reset_pwd_form">
              <Field name="confirmationCode">
                {({ field, form }) => (
                  <FormControl
                    isInvalid={
                      form.errors.confirmationCode &&
                      form.touched.confirmationCode
                    }
                  >
                    <InfoFormLabel
                      htmlFor="confirmationCode"
                      label="Activation Code"
                      infoPopoverProps={{
                        questionMarkSize: 'sm',
                        popoverText: (
                          <Box>
                            <Text>
                              We will only send an email if an account with that
                              email address already exists in our system.
                            </Text>
                            <br />
                            <Text>
                              If an activation email does not arrive, please
                              confirm that you are using the email address that
                              you registered with.
                            </Text>
                          </Box>
                        ),
                        popoverProps: {
                          offset: [11, 10],
                        },
                        analyticsSrc: 'activation_code',
                      }}
                    />
                    <Input
                      {...field}
                      id="confirmationCode-id"
                      type="text"
                      inputMode="numeric"
                      autoComplete="one-time-code"
                      pattern="[0-9]*"
                      placeholder="Activation Code"
                    />
                    <FormErrorMessage>
                      {form.errors.confirmationCode}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="password">
                {({ field, form }) => (
                  <FormControl
                    isInvalid={form.errors.password && form.touched.password}
                  >
                    <InfoPasswordLabel />
                    <PasswordInput
                      {...field}
                      id="password-id"
                      placeholder="Password"
                      autoComplete="new-password"
                      data-testid="password-id"
                    />
                    <FormErrorMessage>{form.errors.password}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="passwordRepeat">
                {({ field, form }) => (
                  <FormControl
                    isInvalid={
                      form.errors.passwordRepeat && form.touched.passwordRepeat
                    }
                  >
                    <FormLabel htmlFor="passwordRepeat">
                      Confirm Password
                    </FormLabel>
                    <PasswordInput
                      {...field}
                      id="passwordRepeat-id"
                      data-testid="passwordRepeat-id"
                      placeholder="Repeat Password"
                      autoComplete="new-password"
                    />
                    <FormErrorMessage>
                      {form.errors.passwordRepeat}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Center>
                <Button
                  isLoading={formik.isSubmitting}
                  disabled={!formik.isValid}
                  type="submit"
                  variant="action"
                  colorScheme="accent"
                  marginTop="30px"
                  marginBottom="50px"
                  data-testid="submit-button"
                >
                  CHANGE PASSWORD
                </Button>
              </Center>
            </FormStack>
          </Form>
        )}
      </Formik>
      {isLoading && <ActivityIndicator tip="Working" />}
    </Container>
  );
}

const ResetPasswordWrapper = (props: any): React.ReactElement => (
  <GoogleReCaptchaProvider reCaptchaKey={reCaptchaKey}>
    <ResetPassword {...props} />
  </GoogleReCaptchaProvider>
);

export default ResetPasswordWrapper;
