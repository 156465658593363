import React, { useState } from 'react';
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Select,
} from '@chakra-ui/react';
import { Field, useFormikContext } from 'formik';

import { daysForMonth, yearList } from '../../lib/dobValidation';

const FormDOBInput = (): React.ReactElement => {
  const [monthDays, setMonthDays] = useState<number>(31);
  const {
    errors,
    touched,
  }: {
    values: any;
    errors: any;
    touched: any;
    setErrors: any;
  } = useFormikContext();

  const validYears = yearList();
  const onMonthChange = (value: number) => {
    setMonthDays(daysForMonth(value));
  };

  const isInvalidDate =
    !!(errors.month && touched.month) ||
    !!(errors.day && touched.day) ||
    !!(errors.year && touched.year);

  return (
    <>
      <FormLabel mt="12px !important">Birthday</FormLabel>
      <HStack
        mt="3px !important"
        pt="0"
        spacing={2}
        align="stretch"
        data-testid="form-dob-input"
      >
        <Field name="day">
          {({ field }) => (
            <FormControl isInvalid={isInvalidDate}>
              <Select
                placeholder={monthDays > 0 ? 'Day' : 'Day'}
                {...field}
                height="3.0rem"
                border="1px solid"
                borderColor="brand.grey200"
                onChange={(evt) => {
                  field.onChange(evt);
                }}
                data-testid="birth-day"
              >
                {monthDays &&
                  Array.from(Array(monthDays).keys()).map((day) => (
                    <option value={day + 1} key={day}>
                      {day + 1}
                    </option>
                  ))}
              </Select>
            </FormControl>
          )}
        </Field>
        <Field name="month">
          {({ field }) => (
            <FormControl isInvalid={isInvalidDate}>
              <Select
                placeholder="Month"
                {...field}
                height="3.0rem"
                border="1px solid"
                borderColor="brand.grey200"
                onChange={(evt) => {
                  onMonthChange(parseInt(evt.target.value, 10));
                  field.onChange(evt);
                }}
                data-testid="birth-month"
              >
                <option value="1">01</option>
                <option value="2">02</option>
                <option value="3">03</option>
                <option value="4">04</option>
                <option value="5">05</option>
                <option value="6">06</option>
                <option value="7">07</option>
                <option value="8">08</option>
                <option value="9">09</option>
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>
              </Select>
            </FormControl>
          )}
        </Field>
        <Field name="year">
          {({ field }) => (
            <FormControl isInvalid={isInvalidDate}>
              <Select
                placeholder="Year"
                {...field}
                height="3.0rem"
                border="1px solid"
                borderColor="brand.grey200"
                onChange={(evt) => {
                  field.onChange(evt);
                }}
                data-testid="birth-year"
              >
                {validYears &&
                  validYears.map((year) => (
                    <option value={year} key={year}>
                      {year}
                    </option>
                  ))}
              </Select>
              <FormErrorMessage>
                {isInvalidDate && <div>must be a valid date</div>}
              </FormErrorMessage>
            </FormControl>
          )}
        </Field>
      </HStack>
    </>
  );
};

export default FormDOBInput;
