import React from 'react';
import { Text, TextProps } from '@chakra-ui/react';

const NodeText: React.FunctionComponent<TextProps> = ({ children }) => (
  <Text fontWeight="smallText" fontSize="subText" lineHeight="medText">
    {children}
  </Text>
);

export default NodeText;
