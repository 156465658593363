import React, { useState } from 'react';
import { Heading, Box, Image, IconButton } from '@chakra-ui/react';
import ReactPlayer from 'react-player';

import ContainerComponent from '../../components/Container';
import Header from '../../layout/Header/Header';
import WealthieHelmet from '../../components/WealthieHelmet';
import MuteIcon from '../../assets/images/icons/MuteIcon';
import UnMuteIcon from '../../assets/images/icons/UnMuteIcon';
import {
  trackGiftCardVideoMute,
  trackGiftCardVideoPause,
  trackGiftCardVideoPlay,
  trackGiftCardVideoPlayToEnd,
  trackGiftCardVideoUnmute,
} from '../../lib/analytics';

interface VideoSizes {
  largeScreen: string;
  smallScreen: string;
}

interface GiftCardPlayerProps {
  collectionName: string;
  cardName: string;
  graphicUrl: string;
  videoUrl: string;
  stampUrl: string;
  videoWidth: VideoSizes;
  videoHeight: VideoSizes;
  giftCardId: number;
}

const GiftCardPlayer: React.FunctionComponent<GiftCardPlayerProps> = ({
  collectionName,
  cardName,
  graphicUrl,
  videoUrl,
  stampUrl,
  videoWidth,
  videoHeight,
  giftCardId,
}) => {
  const [muted, setMuted] = useState(false);
  const [playing, setPlaying] = useState(false);

  return (
    <ContainerComponent>
      <WealthieHelmet title="Giftcard Player" />
      <Header />
      <Box
        paddingX={['15px']}
        //        paddingX={['15px', '30px']}
        display="flex"
        flexDirection="column"
        alignItems={['center']}
        //        alignItems={['center', 'center', 'revert']}
      >
        {/* Headings */}
        <Box
          textAlign="center"
          display="flex"
          flexDir="column"
          gap="10px"
          marginBottom={['20px']}
          //          marginBottom={['20px', '30px']}
        >
          <Heading
            as="h1"
            fontSize="subTitle"
            fontWeight="medText"
            lineHeight="largeText"
          >
            {collectionName}
          </Heading>
          <Heading
            as="h2"
            fontSize="subText"
            fontWeight="smallText"
            lineHeight="medText"
          >
            {cardName}
          </Heading>
        </Box>
        {/* Images and video */}
        <Box
          display="flex"
          // flexDir={['column', 'column', 'row']}
          flexDir={['column']}
          justifyContent={['space-evenly']}
          // justifyContent={['space-evenly', 'space-evenly', 'space-between']}
          marginBottom="50px"
          maxWidth={[videoWidth.smallScreen, videoWidth.largeScreen, 'none']}
        >
          {/* Graphic */}
          <Box
            maxWidth={['345px']}
            // maxWidth={['345px', '345px', '240px']}
            maxHeight={['100px']}
            // maxHeight={['100px', 'none']}
            // flexShrink="2"
            flexBasis={['20%']}
            minWidth="100px"
          >
            <Image src={graphicUrl} maxHeight={['100px']} />
            {/* <Image src={graphicUrl} maxHeight={['100px', '100px', 'none']} /> */}
          </Box>
          {/* Video Player */}
          <Box
            position="relative"
            maxWidth={[videoWidth.smallScreen, videoWidth.largeScreen]}
            maxHeight={[videoHeight.smallScreen, videoHeight.largeScreen]}
            // flexShrink="1"
            flexBasis={['60%']}
          >
            <Box
              onClick={() => {
                if (!playing) {
                  trackGiftCardVideoPlay(cardName, giftCardId, cardName);
                } else {
                  trackGiftCardVideoPause(cardName, giftCardId, cardName);
                }
                setPlaying(!playing);
              }}
              cursor="pointer"
            >
              <ReactPlayer
                url={videoUrl}
                width="100%"
                height="100%"
                muted={muted}
                playing={playing}
                onEnded={() => {
                  trackGiftCardVideoPlayToEnd(cardName, giftCardId, cardName);
                }}
              />
            </Box>
            <IconButton
              onClick={() => {
                if (!muted) {
                  trackGiftCardVideoMute(cardName, giftCardId, cardName);
                } else {
                  trackGiftCardVideoUnmute(cardName, giftCardId, cardName);
                }
                setMuted(!muted);
              }}
              position="absolute"
              top="0%"
              right="0%"
              aria-label={muted ? 'Unmute Button' : 'Mute Button'}
              variant="unstyled"
              backgroundColor="black"
              width="42px"
              height="42px"
              size="sm"
            >
              {muted ? (
                <UnMuteIcon width="24px" height="24px" />
              ) : (
                <MuteIcon width="24px" height="24px" />
              )}
            </IconButton>
          </Box>
          {/* Stamp */}
          <Box
            maxWidth={['345px']}
            maxHeight={['150px']}
            // maxWidth={['345px', '345px', '240px']}
            // maxHeight={['150px', '150px', 'none']}
            minWidth="100px"
            alignSelf="flex-end"
            // flexShrink="2"
            flexBasis={['20%']}
          >
            <Image src={stampUrl} maxHeight={['150px']} />
            {/* <Image src={stampUrl} maxHeight={['150px', '150px', 'none']} /> */}
          </Box>
        </Box>
      </Box>
    </ContainerComponent>
  );
};

export default GiftCardPlayer;
