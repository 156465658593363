import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Amplify, { Auth } from 'aws-amplify';
import axios from 'axios';
import AppStore from './store';
import { logout } from './store/reducers/authSlice';

axios.interceptors.request.use(function (config) {
  return new Promise((resolve, reject) => {
    Auth.currentSession()
      .then((session) => {
        const idTokenExpire = session.getIdToken().getExpiration();
        const refreshToken = session.getRefreshToken();
        const currentTimeSeconds = Math.round(+new Date() / 1000);
        if (idTokenExpire < currentTimeSeconds) {
          Auth.currentAuthenticatedUser().then((res) => {
            res.refreshSession(refreshToken, (err: any, data: any) => {
              if (err) {
                // exipire and cant refresh
                AppStore.store.dispatch(logout());
                reject(err);
              } else {
                config.headers.Authorization = `Bearer ${data
                  .getIdToken()
                  .getJwtToken()}`;
                resolve(config);
              }
            });
          });
        } else {
          config.headers.Authorization = `Bearer ${session
            .getIdToken()
            .getJwtToken()}`;
          resolve(config);
        }
      })
      .catch(() => {
        // another errors like 404, internet etc
        resolve(config);
      });
  });
});

Amplify.configure({
  Auth: {
    // REQUIRED - Amazon Cognito Region
    region: process.env.REACT_APP_AWS_REGION,

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: process.env.REACT_APP_AWS_USER_POOL_ID,

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: process.env.REACT_APP_AWS_USER_POOL_CLIENT_ID,

    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: false,

    // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
    authenticationFlowType: 'USER_PASSWORD_AUTH',

    // OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
    clientMetadata: { appId: 'works.wealthie.auth' },

    // OPTIONAL - Hosted UI configuration
    oauth: {},
  },
});

// TODO: Add axios refresh token

ReactDOM.render(<App />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
