import React from 'react';
import GiftCardPlayer from './GiftCardPlayer';

const giftCardPlayerObject = {
  wealthieverse: {
    collectionName: 'WEALTHIEVERSE',
    videoWidth: {
      largeScreen: '700px',
      smallScreen: '345px',
    },
    videoHeight: {
      largeScreen: '585px',
      smallScreen: '300px',
    },
    theOriginalOne: {
      graphic: '/assets/giftCardPlayer/Wealthieverse/Card1/graphic.png',
      video: '/assets/giftCardPlayer/Wealthieverse/Card1/video.mp4',
      stamp: '/assets/giftCardPlayer/Wealthieverse/Card1/stamp.png',
      cardName: 'Card 1: The Original One',
    },
    theRobotOverlordOne: {
      graphic: '/assets/giftCardPlayer/Wealthieverse/Card2/graphic.png',
      video: '/assets/giftCardPlayer/Wealthieverse/Card2/video.mp4',
      stamp: '/assets/giftCardPlayer/Wealthieverse/Card2/stamp.png',
      cardName: 'Card 2: The Robot Overlord One',
    },
    theOneThatWontDecide: {
      graphic: '/assets/giftCardPlayer/Wealthieverse/Card3/graphic.png',
      video: '/assets/giftCardPlayer/Wealthieverse/Card3/video.mp4',
      stamp: '/assets/giftCardPlayer/Wealthieverse/Card3/stamp.png',
      cardName: "Card 3: The One That Won't Decide",
    },
  },
  artistCards: {
    collectionName: 'The Artist Cards',
    videoWidth: {
      largeScreen: '640px',
      smallScreen: '345px',
    },
    videoHeight: {
      largeScreen: '640px',
      smallScreen: '345px',
    },
    theSingingOne: {
      graphic: '/assets/giftCardPlayer/TheArtistCards/Card1/graphic.png',
      video: '/assets/giftCardPlayer/TheArtistCards/Card1/video.mp4',
      stamp: '/assets/giftCardPlayer/TheArtistCards/Card1/stamp.png',
      cardName: 'Card 1: The Singing One',
    },
    theDancingOne: {
      graphic: '/assets/giftCardPlayer/TheArtistCards/Card2/graphic.png',
      video: '/assets/giftCardPlayer/TheArtistCards/Card2/video.mp4',
      stamp: '/assets/giftCardPlayer/TheArtistCards/Card2/stamp.png',
      cardName: 'Card 2: The Dancing One',
    },
    thePlayingOne: {
      graphic: '/assets/giftCardPlayer/TheArtistCards/Card3/graphic.png',
      video: '/assets/giftCardPlayer/TheArtistCards/Card3/video.mp4',
      stamp: '/assets/giftCardPlayer/TheArtistCards/Card3/stamp.png',
      cardName: 'Card 3: The Playing One',
    },
  },
};

const VideoPlayerTest = (): React.ReactElement => (
  // <GiftCardPlayer
  //   collectionName={giftCardPlayerObject.artistCards.collectionName}
  //   cardName={giftCardPlayerObject.artistCards.theDancingOne.cardName}
  //   graphicUrl={giftCardPlayerObject.artistCards.theDancingOne.graphic}
  //   videoUrl={giftCardPlayerObject.artistCards.theDancingOne.video}
  //   stampUrl={giftCardPlayerObject.artistCards.theDancingOne.stamp}
  //   videoWidth={giftCardPlayerObject.artistCards.videoWidth}
  //   videoHeight={giftCardPlayerObject.artistCards.videoHeight}
  //   giftCardId={0}
  // />
  <GiftCardPlayer
    collectionName={giftCardPlayerObject.wealthieverse.collectionName}
    videoWidth={giftCardPlayerObject.wealthieverse.videoWidth}
    videoHeight={giftCardPlayerObject.wealthieverse.videoHeight}
    cardName={giftCardPlayerObject.wealthieverse.theRobotOverlordOne.cardName}
    graphicUrl={giftCardPlayerObject.wealthieverse.theRobotOverlordOne.graphic}
    videoUrl={giftCardPlayerObject.wealthieverse.theRobotOverlordOne.video}
    stampUrl={giftCardPlayerObject.wealthieverse.theRobotOverlordOne.stamp}
    giftCardId={0}
  />
);

export default VideoPlayerTest;
