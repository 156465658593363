import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/reducers';
import { isKidAuthSelector } from '../../store/reducers/authSlice';
import ParentPage from './ParentPage';
import KidPage from './KidPage';

function LandingPage(): React.ReactElement {
  const isKid = useSelector((state: RootState) => isKidAuthSelector(state));

  if (isKid) return <KidPage />;
  return <ParentPage />;
}

export default LandingPage;
