import React from 'react';
import {
  NodeContainer,
  NodeHeading,
  NodeModal,
  NodeText,
  NodeVideoPlayer,
  NodeList,
  NodeListItem,
  NodeContainerSmall,
  NodeHeadingH3,
} from '../NodeComponents';
import { NodeModalProps } from './types';

const age = 9;
const nodeNumber = 0;

const ModalA9N1: React.FunctionComponent<NodeModalProps> = ({
  isOpen,
  onClose,
}) => (
  <NodeModal isOpen={isOpen} onClose={onClose}>
    <NodeVideoPlayer
      url="/assets/timelineModalMedia/WWD-Acct-KidTimeline-Age9-GoingPro-01:23-N1-Hero.mp4"
      isGif
      ageId={age}
      nodeNumber={nodeNumber}
      index={0}
    />
    <NodeContainer>
      <NodeHeading>Going Pro</NodeHeading>
      <NodeText>
        The <b>NFL</b> is the <b>biggest and most profitable</b> of all the
        professional American sports leagues. But, it&apos;s the NBA&apos;s pro
        basketball players who{' '}
        <b>earn the highest average salaries — $8.5 million </b>
        US dollars on average. in 2019/20. That&apos;s almost{' '}
        <b>3x the average salary in the NFL.</b>
      </NodeText>
    </NodeContainer>
    <NodeVideoPlayer
      url="/assets/timelineModalMedia/WWD-Acct-KidTimeline-Age9-NeonMoney-01:23-N1-1.mp4"
      isGif
      ageId={age}
      nodeNumber={nodeNumber}
      index={1}
    />
    <NodeContainer>
      <NodeText>So, what gives? It&apos;s the wrath of the math:</NodeText>
      <NodeList>
        <NodeListItem>
          NBA players receive 51.5% of all of their League&apos;s revenues. NFL
          players receive about 48%.
        </NodeListItem>
        <NodeListItem>
          The NBA has more fans around the world than other leagues, which
          equals more revenue. In 2017, the NBA used its popularity to negotiate
          a tv broadcasting deal for 24 billion dollars.
        </NodeListItem>
        <NodeListItem>
          The NBA&apos;s salary cap is increasing. That&apos;s the rule that
          limits the amount of money a team can spend on its players. In a
          valuable league, that can mean big bucks.
        </NodeListItem>
      </NodeList>
    </NodeContainer>
    <NodeVideoPlayer
      url="/assets/timelineModalMedia/WWD-Acct-KidTimeline-Age9-NeonGraphs-01:23-N1-2.mp4"
      isGif
      ageId={age}
      nodeNumber={nodeNumber}
      index={2}
    />
    <NodeContainerSmall>
      <NodeHeadingH3>Here&apos;s another way to break this down:</NodeHeadingH3>
      <NodeText>
        <b>51.5% of the NBA&apos;s revenues</b> are shared among{' '}
        <b>529 players.</b>
      </NodeText>
      <NodeText>
        <b>48% of the NFL&apos;s revenues</b> are shared among{' '}
        <b>1696 players.</b>
      </NodeText>
    </NodeContainerSmall>
    <NodeContainer>
      <NodeText>
        Shopping for a pro league to play in? The NFL has over 3x as many
        players as the NBA, so your odds of playing in the NFL are higher. How
        high? Well, our advice is STAY IN SCHOOL. The chances of becoming a
        professional athlete are about the same as getting struck by lightning.
      </NodeText>
      <NodeText>P.S.SSST.</NodeText>
    </NodeContainer>
    <NodeVideoPlayer
      url="/assets/timelineModalMedia/WWD-Acct-KidTimeline-Age9-GoingPro-01:23-N1-3.mp4"
      isGif
      ageId={age}
      nodeNumber={nodeNumber}
      index={3}
    />
    <NodeContainer>
      <NodeHeading>P.S: Number 2.</NodeHeading>
      <NodeText>
        According to Statista, the second highest paying sports league in the
        world (after the NBA) is the <b>Indian Premier League.</b> That&apos;s
        the sport of cricket. Do you know it? It&apos;s sort of like baseball.
        But not. It&apos;s hugely popular in places like India and Australia.
      </NodeText>
      <NodeText>
        The LOWEST paying sports league in the world? Well, one of them is right
        around the corner. Look no further than the{' '}
        <b>Canadian Football League, </b>
        where players make an average of <b>$90,000 USD</b> a year.
      </NodeText>
    </NodeContainer>
  </NodeModal>
);

export default ModalA9N1;
