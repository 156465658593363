const Text = {
  variants: {
    siteTitle: (): any => ({
      fontFamily: 'title',
      fontSize: 'title',
    }),
    siteTitleTail: (): any => ({
      fontFamily: 'title',
      fontSize: 'title',
      color: 'brand.titleHighlight',
    }),
    small: (): any => ({
      fontFamily: 'body',
      fontSize: '1rem',
      color: 'brand.primary',
    }),
    badgeText: (): any => ({
      fontFamily: 'badge',
      fontWeight: '700',
      fontSize: 'badge',
      color: 'brand.lightText',
      textAlign: 'center',
      textTransform: 'uppercase',
    }),
    badgeTextDark: (): any => ({
      fontFamily: 'badge',
      fontWeight: '700',
      fontSize: 'badge',
      color: 'brand.darkText',
      textAlign: 'center',
      textTransform: 'uppercase',
    }),
  },
};

export default Text;
