import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { Box, Center, Heading } from '@chakra-ui/react';

import Header from '../../layout/Header/Header';
import {
  getParentProfile,
  GiftCardType,
  selectChildren,
  selectGiftCards,
  //  selectParent,
  UserType,
} from '../../store/reducers/homeSlice';
import { RootState } from '../../store/reducers';
import Container from '../../components/Container';

import styles from './styles.module.scss';
import GiftCardImage from '../../components/GiftCardImage';
import { cardImageFromName } from '../ChildTimeline/images';
import ChildDetail from '../../components/ChildDetail';
import GiftCardModal from '../../components/GiftCardModal';
import BodyContainer from '../../components/BodyContainer';
import WealthieHelmet from '../../components/WealthieHelmet';

const timeFormat = (timestamp: string) =>
  moment(timestamp).format('MMMM Do, YYYY');

const getCardDate = (card: GiftCardType) => {
  if (card.transactionTimestamp) {
    return timeFormat(card.transactionTimestamp);
  } else {
    return 'Unknown date';
  }
};

const CardByDay = ({
  giftCard,
  onPressCard,
}: {
  giftCard: GiftCardType;
  onPressCard: (card: GiftCardType) => void;
  onPressChild: (childId: number) => void;
}) => {
  const cardDate = getCardDate(giftCard);
  return (
    <>
      <Box className={styles.day}>{cardDate}</Box>
      {/* <Box className={styles.gift_info_wrapper}> */}
      <Box display="flex" gap="40px" marginBottom="21px">
        <Box
          key={giftCard.id}
          className={styles.cardContainer}
          onClick={() => onPressCard(giftCard)}
          cursor="pointer"
          aria-label="View Gift Card"
        >
          {/* <GiftCardForName name={giftCard.giftCardGameName} /> */}
          <GiftCardImage
            giftCardImageSrc={cardImageFromName(giftCard.giftCardGameName)}
            size="custom"
            height="105.89px"
            width="168px"
            radius="13px"
            borderPixels="2px"
          />
        </Box>
        <Box
          width={'106px'}
          height="106px"
          cursor="pointer"
          aria-label="View Child Timeline"
        >
          <ChildDetail
            isShowInformation={false}
            fillContainer
            avatarId={giftCard.avatarId}
            enableClickKid
            kid={{ id: String(giftCard.childId) }}
            parentId={String(giftCard.parentId)}
            id={String(giftCard.childId)}
          />
        </Box>
      </Box>
    </>
  );
};

const GiftCardList = ({
  giftCards,
}: {
  giftCards: GiftCardType[];
  childrenList: UserType[];
}) => {
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [modalCard, setModalCard] = React.useState<GiftCardType | null>(null);
  const history = useHistory();

  const onPressChild = (childId: number) => {
    history.push(`/child-timeline/${childId}`);
  };

  function openModal(giftCard: GiftCardType) {
    setIsOpen(true);
    setModalCard(giftCard);
  }

  function closeModal() {
    setIsOpen(false);
    setModalCard(null);
  }

  function openGame(childId) {
    if (!modalCard) {
      return;
    }
    const cardId = modalCard.id;
    closeModal();
    window.open(`/child-timeline/${childId}/game/${cardId}`);
  }

  const onPressGiftcard = (giftCard: GiftCardType) => {
    openModal(giftCard);
  };

  return (
    <>
      {giftCards.map((item) => (
        <CardByDay
          key={item.id}
          giftCard={item}
          onPressCard={onPressGiftcard}
          onPressChild={onPressChild}
        />
      ))}
      {modalCard && (
        <GiftCardModal
          closeModal={closeModal}
          giftCardImageSrc={cardImageFromName(modalCard.giftCardGameName)}
          modalCard={modalCard}
          modalIsOpen={modalIsOpen}
          openGame={() => {
            openGame(modalCard.childId);
          }}
        />
      )}
    </>
  );
};

function GiftCards(): React.ReactElement {
  const dispatch = useDispatch();
  //  const history = useHistory();
  const childrenList = useSelector((state: RootState) => selectChildren(state));
  const giftCards = useSelector((state: RootState) => selectGiftCards(state));
  //  const parent = useSelector((state: RootState) => selectParent(state));
  useEffect(() => {
    dispatch(getParentProfile());
  }, [dispatch]);

  // useEffect(() => {
  //   if (parent.parentGiftCards) {
  //     history.push({
  //       pathname: '/assign-gift-cards',
  //       state: {
  //         giftId: parent.parentGiftCards[0].id,
  //         gift: parent.parentGiftCards[0],
  //       },
  //     });
  //   }
  // }, [history, parent]);

  return (
    <Container data-testid="gift_cards_page">
      <WealthieHelmet title="Gift Card Archive" />
      <Header headerStyle="hamburger" showNav />
      <Center>
        <Heading variant="title">Gift Card Archive</Heading>
      </Center>
      <BodyContainer>
        <Box my="5">
          <GiftCardList giftCards={giftCards} childrenList={childrenList} />
        </Box>
      </BodyContainer>
    </Container>
  );
}

export default GiftCards;
