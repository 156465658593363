import React, { useEffect, useRef, useState } from 'react';
import {
  trackAudioEnded,
  trackAudioMuted,
  trackAudioPause,
  trackAudioPlay,
  trackAudioUnmuted,
} from '../../../lib/analytics';

interface NodeAudioProps {
  src: string;
  index: number | null;
  activeIndex: number | null;
  setActiveIndex: React.Dispatch<React.SetStateAction<number | null>>;
  nodeId: number;
  ageId: number;
}

const NodeAudio: React.FunctionComponent<NodeAudioProps> = ({
  src,
  index,
  activeIndex,
  setActiveIndex,
  nodeId,
  ageId,
}) => {
  const audioRef = useRef<HTMLAudioElement>(null);
  const [isMuted, setIsMuted] = useState<boolean>(false);
  const [fileName, setFileName] = useState<string>('');

  useEffect(() => {
    if (activeIndex === index) {
      audioRef.current?.play();
    } else {
      audioRef.current?.pause();
    }
  }, [activeIndex, index]);

  useEffect(() => {
    if (!audioRef.current) {
      return;
    }
    setIsMuted(audioRef.current.muted);
  }, [audioRef]);

  useEffect(() => {
    const urlSubstring = src.substring(src.lastIndexOf('/') + 1);
    setFileName(urlSubstring);
  }, [src]);

  // TODO: Replace the styles with an scss file
  return (
    <audio
      controls
      src={src}
      onPlay={() => {
        trackAudioPlay(fileName, index, nodeId, ageId);
        setActiveIndex(index);
      }}
      onPause={() => {
        const duration = audioRef.current?.duration;
        const currentTime = audioRef.current?.currentTime;
        if (
          duration !== undefined &&
          currentTime !== undefined &&
          Math.ceil(duration) > Math.ceil(currentTime)
        ) {
          trackAudioPause(fileName, index, nodeId, ageId);
        }
      }}
      onEnded={() => {
        trackAudioEnded(fileName, index, nodeId, ageId);
      }}
      ref={audioRef}
      style={{
        maxWidth: '280px',
        borderColor: 'rgba(45, 202, 209, 1)',
        borderWidth: '2px',
        borderRadius: '27px',
        backgroundColor: 'rgba(246, 250, 252, 1)',
      }}
      onVolumeChange={() => {
        if (!audioRef.current) {
          return;
        }
        if (isMuted !== audioRef.current.muted) {
          setIsMuted(audioRef.current.muted);
          if (audioRef.current.muted) {
            trackAudioMuted(src, index, nodeId, ageId);
          } else {
            trackAudioUnmuted(src, index, nodeId, ageId);
          }
        }
      }}
    />
  );
};

export default NodeAudio;
