import React from 'react';
import { Container, ContainerProps } from '@chakra-ui/react';

const NodeContainerSmall: React.FunctionComponent<ContainerProps> = ({
  children,
}) => (
  <Container paddingX="32.5px" display="flex" flexDir="column" gap="10px">
    {children}
  </Container>
);

export default NodeContainerSmall;
