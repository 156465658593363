import React, { useEffect } from 'react';
import { Box, ModalBody, ModalFooter } from '@chakra-ui/react';
import CustomModal from '../CustomModal';
import GiftCardImage from '../GiftCardImage';
import GiftCardDetail from '../../pages/ChildTimeline/GiftCardDetail';
import { GiftCardType } from '../../store/reducers/homeSlice';
import {
  trackGiftCardModalClose,
  trackGiftCardModalOpen,
  trackOpenGiftCardExperience,
} from '../../lib/analytics';

interface GiftCardModalProps {
  modalIsOpen: boolean;
  closeModal: () => void;
  giftCardImageSrc: string;
  modalCard: GiftCardType | null;
  openGame: () => void;
}

const GiftCardModal = ({
  modalIsOpen,
  closeModal,
  giftCardImageSrc,
  modalCard,
  openGame,
}: GiftCardModalProps): React.ReactElement => {
  useEffect(() => {
    if (modalIsOpen) {
      trackGiftCardModalOpen(modalCard);
    }
  }, [modalIsOpen, modalCard]);

  const onClose = () => {
    trackGiftCardModalClose(modalCard);
    closeModal();
  };

  const openGameTracked = () => {
    openGame();
    trackOpenGiftCardExperience(
      modalCard?.id,
      modalCard?.giftCardTypeId,
      modalCard?.giftCardGameName,
    );
  };

  return (
    <CustomModal isOpen={modalIsOpen} onClose={onClose} hasClose>
      <Box position="absolute" top="-50px" left="28px">
        <GiftCardImage
          giftCardImageSrc={giftCardImageSrc}
          size="lg"
          isRotated
        />
      </Box>
      <ModalBody p="0px" mb="30px">
        {modalCard && (
          <Box>
            <Box
              w="245px"
              // className={styles.giftCardInfo}
              fontSize={'21px'}
              fontWeight={'400'}
              lineHeight={'26px'}
              display="flex"
              flexDir="column"
              gap="10px"
              marginTop="120px"
            >
              <Box>
                <b>To:</b> {modalCard.childName}
              </Box>
              <Box>
                <b>From:</b> {modalCard.senderName}
              </Box>
              <Box>
                <b>Amount:</b> {modalCard.giftCardAmt}{' '}
                {modalCard.giftCardCurrency}
              </Box>
              <Box>
                <b>Message:</b> &quot;{modalCard.message}&quot;
              </Box>
            </Box>
          </Box>
        )}
      </ModalBody>
      <ModalFooter justifyContent={'center'} p="0" gap="15px" marginLeft="43px">
        <GiftCardDetail card={modalCard} onOpenGame={openGameTracked} />
      </ModalFooter>
    </CustomModal>
  );
};

export default GiftCardModal;
