import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, HStack, IconButton } from '@chakra-ui/react';

import BackIcon from '../../components/BackIcon';
import HeaderTitle from './HeaderTitle';
import HeaderMenu from '../HeaderMenu';
import { ReactComponent as MenuIcon } from '../../assets/images/headerIcons/hamburger-menu.svg';
import { ReactComponent as CloseIcon } from '../../assets/images/headerIcons/x-icon.svg';
import { trackMenuClose, trackMenuOpen } from '../../lib/analytics';

interface HeaderLandingProps {
  hasNoti?: boolean;
  className?: string;
  showNav?: boolean;
  showLogoutOnly?: boolean;
  isBackGoHome?: boolean;
  headerStyle?: 'none' | 'hamburger' | 'backArrow';
}

function Header(props: HeaderLandingProps): React.ReactElement {
  const [openMenu, setOpenMenu] = useState(false);
  const history = useHistory();

  const toggleMenu = () => {
    if (!openMenu) {
      trackMenuOpen();
    } else {
      trackMenuClose();
    }
    setOpenMenu((openMenu) => !openMenu);
  };

  const closeMenu = () => {
    trackMenuClose();
    setOpenMenu(false);
  };

  const HeaderIcon = () => {
    switch (props.headerStyle) {
      case 'hamburger': {
        return (
          <Box>
            {(props.showNav || props.showLogoutOnly) && (
              <IconButton
                aria-label="Menu"
                onClick={toggleMenu}
                mt="-2px"
                isRound={false}
                borderRadius="0"
                variant=""
                verticalAlign="top"
                icon={openMenu ? <CloseIcon /> : <MenuIcon />}
              />
            )}
          </Box>
        );
      }
      case 'backArrow': {
        return (
          <Box>
            {props.showNav &&
            location.pathname !== '/homes' &&
            history.length > 1 ? (
              <IconButton
                aria-label="Back"
                variant=""
                onClick={() => {
                  if (location.pathname === '/my-kids' || props.isBackGoHome) {
                    history.replace('/home');
                  } else {
                    history.goBack();
                  }
                }}
                icon={<BackIcon />}
              />
            ) : (
              <IconButton aria-label="" variant="" icon={<></>} />
            )}
          </Box>
        );
      }
      default: {
        return null;
      }
    }
  };

  const backarrowWidth = '12';

  return (
    <Box marginX="0">
      <HStack
        justifyContent={
          props.headerStyle === 'hamburger' ? 'space-between' : 'center'
        }
        paddingX="30px"
        w="100%"
        h="60px"
        bg="brand.headerColor"
        shadow={'brand.shadow'}
        boxShadow={
          '0px 4px 6px var(--chakra-colors-brand-shadow)'
          // `inset 0 ${
          //   openMenu ? '0px' : '-1px'
          // } var(--chakra-colors-brand-headerLine)`,
        }
        borderBottom="1px solid var(--chakra-colors-brand-headerLine)"
        backdropFilter="blur(10px)"
        marginBottom="20px"
      >
        {props.headerStyle === 'backArrow' && HeaderIcon()}
        <HeaderTitle />
        {props.headerStyle === 'hamburger' && HeaderIcon()}
        {/* This box is to make the spacing the same when the headerStyle is backArrow */}
        {props.headerStyle === 'backArrow' && <Box w={backarrowWidth} />}
      </HStack>
      <HeaderMenu
        openMenu={openMenu}
        closeMenu={closeMenu}
        showLogoutOnly={props.showLogoutOnly}
        className={props.className}
      />
    </Box>
  );
}

export default Header;
