import React, { useState } from 'react';
import type { InputProps } from '@chakra-ui/react';
import { InputGroup, Input, InputRightElement, Button } from '@chakra-ui/react';
import { ReactComponent as PasswordEyeOpen } from '../../assets/images/icons/password-eye-open.svg';
import { ReactComponent as PasswordEyeClosed } from '../../assets/images/icons/password-eye-closed.svg';
import { trackHidePassword, trackShowPassword } from '../../lib/analytics';

const PasswordInput = (props: InputProps): React.ReactElement => {
  const [isVisible, setIsVisible] = useState(false);

  const onClick = () => {
    if (!isVisible) {
      trackShowPassword();
    } else {
      trackHidePassword();
    }
    setIsVisible(!isVisible);
  };

  return (
    <InputGroup>
      <Input
        type={isVisible ? 'text' : 'password'}
        {...props}
        paddingRight="9px"
      />
      <InputRightElement width="23px">
        <Button
          onClick={onClick}
          variant="unstyled"
          width="23px"
          data-testid="password-toggle-button"
        >
          {isVisible ? (
            <PasswordEyeOpen cursor="pointer" data-testid="password-eye-open" />
          ) : (
            <PasswordEyeClosed
              cursor="pointer"
              data-testid="password-eye-closed"
            />
          )}
        </Button>
      </InputRightElement>
    </InputGroup>
  );
};

export default PasswordInput;
