import React from 'react';
import { Container, ContainerProps } from '@chakra-ui/react';

const NodeContainer: React.FunctionComponent<ContainerProps> = ({
  children,
}) => (
  <Container paddingX="32.5px" display="flex" flexDir="column" gap="20px">
    {children}
  </Container>
);

export default NodeContainer;
