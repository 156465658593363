import React from 'react';
import { Box, Button, Heading, Text, Image } from '@chakra-ui/react';

import Container from '../../components/Container';
import Header from '../../layout/Header/Header';
import GiftCardImage from '../../components/GiftCardImage';
import GiftcardImage from '../../assets/images/giftcardassets/cards/codepanda.png';
import Welcome from '../../assets/images/badges/welcome.png';

// TODO: Replace these with the real name
const tempParentName = 'Hilary Doyle';
const tempChildName = 'Cameron Clarke';

const Email = (): React.ReactElement => (
  <Container>
    <Header />
    <Box
      display="flex"
      flexDir="column"
      alignItems="center"
      gap="20px"
      marginBottom="20px"
    >
      <GiftCardImage
        size="custom"
        // Temporarily hard coded image
        giftCardImageSrc={GiftcardImage}
        isRotated
        height="175.12"
        width="278.45px"
        radius="20px"
        borderPixels="4px"
      />
      <Heading as="h1" variant="subTitle" textAlign="center">
        {tempParentName} just sent {tempChildName} an investment e-gift! <br />
        Woooot!
      </Heading>
      <Button variant="action" colorScheme="accent">
        START HERE
      </Button>
    </Box>
    <Box
      bgColor="brand.lightBackground"
      paddingX="18.5px"
      paddingTop="40px"
      paddingBottom="30px"
      borderRadius="8px"
      display="flex"
      flexDir="column"
      alignItems="center"
      marginBottom="50px"
    >
      <Box marginBottom="20px">
        <Heading as="h2" variant="subTitle" marginBottom="10px">
          Welcome to Wealthie!
        </Heading>
        <Text>
          Wealthie is an investment and financial literacy platform for kids and
          families. We make it easy for friends and family to contribute to your
          child’s future.
        </Text>
      </Box>
      <Box marginBottom="30px">
        <Heading as="h2" variant="subTitle" marginBottom="10px">
          Time is valuable. Start Early!
        </Heading>
        <Text>
          Every Wealthie card comes with a fun, collectible experience for your
          child, plus an investment in their future.
          <br />
          <br />
          Investment gifts slide into trusted Wealthie portfolios, built to grow
          for the long term.
        </Text>
      </Box>
      <Button variant="action" colorScheme="accent" marginBottom="30px">
        GET STARTED
      </Button>
      <Box marginBottom="20px">
        <Heading as="h2" variant="subTitle" marginBottom="10px">
          Never invested before?
        </Heading>
        <Text>
          Don’t worry! Wealthie is set up to make investing straightforward,
          with access to curated, low-fee portfolios, and support from some of
          the best in the business.
        </Text>
      </Box>
      <Image
        src={Welcome}
        alt="Welcome Badge"
        boxSize="176px"
        marginBottom="30px"
      />
    </Box>
  </Container>
);

export default Email;
