import { Image } from '@chakra-ui/react';
import React from 'react';
import {
  NodeContainer,
  NodeContainerSmall,
  NodeHeading,
  NodeModal,
  NodeText,
  NodeVideoPlayer,
} from '../NodeComponents';
import { NodeModalProps } from './types';

const age = 17;
const nodeNumber = 0;

const ModalA17N1: React.FunctionComponent<NodeModalProps> = ({
  isOpen,
  onClose,
}) => (
  <NodeModal isOpen={isOpen} onClose={onClose}>
    <Image
      src="/assets/timelineModalMedia/WWD-Acct-KidTimeline-Age17-PredictFuture-01:23-N1-Hero.png"
      alt="Crystal Ball"
    />
    <NodeContainer>
      <NodeHeading>How to Predict the Future</NodeHeading>
      <NodeText>
        It can be tough to sort out where we&apos;re at in the economic cycle.
        Case in point… recessions. How do you know when you&apos;re in one?
      </NodeText>
      <NodeText>
        A lot of economists agree that if the gross domestic product (GDP)
        shrinks for two consecutive quarters, we&apos;re in a recession. But
        some economists look to other measures, such as nonfarm payrolls, or
        retail sales. The National Bureau of Economic Research (NBER) is even
        less clear. They&apos;ve said “there is no fixed rule about what
        measures contribute to the process” of declaring a recession.
      </NodeText>
      <NodeText>
        So, with that in mind, here are some other methods that economists and
        business people have used to predict economic downturns in the past. We
        wouldn&apos;t bet the farm on any of these predictors…
      </NodeText>
    </NodeContainer>
    <Image
      src="/assets/timelineModalMedia/WWD-Acct-KidTimeline-Age17-Underwear-01:23-N1-1.png"
      alt="Underwear"
    />
    <NodeContainer>
      <NodeText>
        <b>Underwear?</b> Alan Greenspan came up with the idea that men&apos;s
        underwear sales could signal a recession, back when the former Fed chair
        was just a consultant. His thinking? Sales of gitch dip when men have
        less money for “unnecessary” expenses.
      </NodeText>
    </NodeContainer>
    <NodeVideoPlayer
      url="/assets/timelineModalMedia/WWD-Acct-KidTimeline-Age17-Champagne-01:23-N1-2.mp4"
      isGif
      ageId={age}
      nodeNumber={nodeNumber}
      index={0}
    />
    <NodeContainer>
      <NodeText>
        <b>Alcohol?</b> Champagne sales pop when the economy&apos;s up. But more
        drinking at home — even the premium stuff — can signal some
        belt-tightening.
      </NodeText>
    </NodeContainer>
    <Image
      src="/assets/timelineModalMedia/WWD-Acct-KidTimeline-Age17-SkirtsAndTies-01:23-N1-3.png"
      alt="Skirts and Ties"
    />
    <NodeContainer>
      <NodeText>
        <b>Skirts and ties?</b> The “hemline index” was first introduced during
        the Great Depression: shorter skirts = stronger economy. Ties supposedly
        get skinnier during lean times, too.
      </NodeText>
    </NodeContainer>
    <Image
      src="/assets/timelineModalMedia/WWD-Acct-KidTimeline-Age17-Lipstick-01:23-N1-4.png"
      alt="Lipstick"
    />
    <NodeContainerSmall>
      <NodeText>
        <b>Lipstick?</b> Coined by Leonard Lauder (Chairman Emeritus of Estée
        Lauder), the “lipstick index” says we&apos;re more likely to treat
        ourselves to small luxuries when times are tough.
      </NodeText>
      <NodeText>
        Allegedly debunked in 2009, when lipstick sales declined by 10%, Covid
        masking mandates were thought to have killed it for good. But lip makeup
        was the fastest growing makeup category of 2022.
      </NodeText>
    </NodeContainerSmall>
    <Image
      src="/assets/timelineModalMedia/WWD-Acct-KidTimeline-Age17-NailPolish-01:23-N1-5.png"
      alt="Goth Nails"
    />
    <NodeContainer>
      <NodeText>
        <b>Goths?</b> The darker the nail polish, apparently, the darker the
        economic outlook.
      </NodeText>
      <NodeText>
        Here&apos;s the thing: there&apos;s opportunity for investors in every
        economic cycle, and the best thing you can do for yourself when it comes
        to the economy, is what you&apos;re already doing — give yourself time!
      </NodeText>
    </NodeContainer>
    <NodeVideoPlayer
      url="/assets/timelineModalMedia/WWD-Acct-KidTimeline-Age17-Clock-01:23-N1-6.mp4"
      isGif
      ageId={age}
      nodeNumber={nodeNumber}
      index={1}
    />
  </NodeModal>
);

export default ModalA17N1;
