import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/reducers';

import { getChild, selectChild } from '../../store/reducers/homeSlice';

import ChildTimeline from '../ChildTimeline';

function KidHomePage(): React.ReactElement {
  const dispatch = useDispatch();
  const child = useSelector((state: RootState) => selectChild(state));

  useEffect(() => {
    dispatch(getChild());
  }, [dispatch]);

  if (!child) {
    return <></>;
  }
  return <ChildTimeline isKid child={child} />;
}

export default KidHomePage;
