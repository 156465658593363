import { trackLogin, trackLogout } from './../../lib/analytics';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Auth } from 'aws-amplify';
import { CognitoUser } from '@aws-amplify/auth';
import axios from 'axios';
import { get } from 'lodash';

import { AppThunk } from '..';
import showNotification, { Messages } from '../../components/Notification';
import { acceptInviteApi } from '../../apis';

import { RootState } from '.';

export interface UserAttributes {
  sub: string;
  email: string;
  email_verified: string;
  name: string;
  updatedAt: string;
}

type WealthieCognitoUser = CognitoUser & UserAttributes;

interface AuthState {
  user: WealthieCognitoUser | undefined;
  isAuthenticated: boolean;
  currentGiftCardId: string;
  isLoading: boolean;
}

const initialState: AuthState = {
  user: undefined,
  isAuthenticated: false,
  currentGiftCardId: '',
  isLoading: false,
};

type LoginPayload = {
  user: WealthieCognitoUser;
};

type GiftCardIdPayload = {
  giftCardId: string;
};

const authSlice = createSlice({
  name: 'Auth',
  initialState,
  reducers: {
    loginSuccess(state, action: PayloadAction<LoginPayload>) {
      return {
        ...state,
        user: action.payload.user,
        isAuthenticated: true,
        isLoading: false,
      };
    },
    loginBegin(state) {
      state.isLoading = true;
      return state;
    },
    loginComplete(state) {
      state.isLoading = false;
      return state;
    },
    logout() {
      return initialState;
    },
    setCurrentGiftId(state, action: PayloadAction<GiftCardIdPayload>) {
      return { ...state, currentGiftCardId: action.payload.giftCardId };
    },
  },
});

export const {
  loginSuccess,
  logout,
  setCurrentGiftId,
  loginBegin,
  loginComplete,
} = authSlice.actions;

export const isKid = (user: WealthieCognitoUser | undefined): boolean =>
  !!user && get(user, 'attributes.name', false);

export const currentGiftIdSelector = createSelector(
  (state: RootState) => state.authReducer,
  (authReducer: AuthState) => authReducer.currentGiftCardId,
);

export const isKidAuthSelector = createSelector(
  (state: RootState) => state.authReducer,
  (authReducer: AuthState) => isKid(authReducer.user),
);

export const selectIsLoading = createSelector(
  (state: RootState) => state.authReducer,
  (authReducer: AuthState) => authReducer.isLoading,
);

export const logoutWealthie = (): AppThunk => async (dispatch) => {
  await Auth.signOut();
  dispatch(logout());
  trackLogout();
};

export const verifyInvitation = (
  inviteToken: string,
  history?: any,
): AppThunk => async (dispatch) => {
  try {
    const res = await acceptInviteApi({ invite_token: inviteToken });
    dispatch(setCurrentGiftId({ giftCardId: res.data?.giftCardId }));
    localStorage.removeItem('@invite');
    showNotification({
      type: 'success',
      message: Messages.verificationSucceeded,
    });
    // push to history if params has history
    if (history) {
      history.push({
        pathname: '/home',
        state: {
          giftId: res.data?.giftCardId,
          gift: res.data,
        },
      });
    }
  } catch (error) {
    localStorage.removeItem('@invite');
    showNotification({
      type: 'error',
      message: get(
        error,
        'response.data.error.message',
        Messages.verificationFailed,
      ),
    });
    if (history) {
      history.push('/');
    }
  }
};

export const loginWealthie = (
  userName: string,
  password: string,
  history: any,
): AppThunk => async (dispatch) => {
  try {
    if (!userName || !password) {
      throw new Error("Username or Password can't be empty");
    }
    dispatch(loginBegin());
    const inviteCode = await localStorage.getItem('@invite');
    const user = await Auth.signIn(userName, password, {
      appId: 'works.wealthie.auth',
    });
    const token = get(user, 'signInUserSession.accessToken.jwtToken', '');
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    dispatch(loginComplete());
    dispatch(loginSuccess({ user }));
    trackLogin();
    if (isKid(user)) {
      history.push('/home');
    } else {
      history.push('/home');
      if (inviteCode) {
        dispatch(verifyInvitation(inviteCode, history));
      }
    }
  } catch (error) {
    console.error(error);
    dispatch(loginComplete());
    const errorObject: any = error;
    if (errorObject.name && errorObject.name === 'NotAuthorizedException') {
      showNotification({
        type: 'error',
        message: errorObject.message,
      });
    } else {
      showNotification({
        type: 'error',
        message: Messages.somethingWentWrong,
      });
    }
  }
};

export default authSlice.reducer;
