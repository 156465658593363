import React from 'react';
import { Image } from '@chakra-ui/react';

import {
  NodeContainer,
  NodeContainerSmall,
  NodeHeading,
  NodeModal,
  NodeText,
  NodeVideoPlayer,
  NodeHeadingH3,
} from '../NodeComponents';
import { NodeModalProps } from './types';

const age = 9;
const nodeNumber = 1;

const ModalA9N2: React.FunctionComponent<NodeModalProps> = ({
  isOpen,
  onClose,
}) => (
  <NodeModal isOpen={isOpen} onClose={onClose}>
    <NodeVideoPlayer
      url="/assets/timelineModalMedia/WWD-Acct-KidTimeline-Age9-Wizard-01:23-N2-Hero.mp4"
      isGif
      ageId={age}
      nodeNumber={nodeNumber}
      index={0}
    />
    <NodeContainer>
      <NodeHeading>The Cost of Becoming a Wizard</NodeHeading>
      <NodeText>
        From the budding wizards at Hogwarts to the more seasoned wizzes of Lord
        of the Rings, wizardry is where it&apos;s at. If you&apos;re curious
        about a career in wizardry, we&apos;ve run the numbers:
      </NodeText>
    </NodeContainer>
    <Image
      src="/assets/timelineModalMedia/WWD-Acct-KidTimeline-Age9-WizardWand-01:23-N2-1.png"
      alt="Black and White bees"
    />
    <NodeContainerSmall>
      <NodeHeadingH3>Carry a Big Stick:</NodeHeadingH3>
      <NodeText>
        If you&apos;re serious about wizardry, you&apos;ll want/need a wand to
        express yourself.
      </NodeText>
      <NodeText>
        Are you a no frills kind of wiz? A 14-inch gold-tipped standard, or a
        basic lucite wand will run you about $4.50 to $5.50.
      </NodeText>
      <NodeText>
        Are you a wizard who loves movies and history? Maybe you&apos;d like to
        bid on Glynda the Good Witch&apos;s wand, which sold for $400 000 back
        in 2019!
      </NodeText>
    </NodeContainerSmall>
    <NodeVideoPlayer
      url="/assets/timelineModalMedia/WWD-Acct-KidTimeline-Age9-WizardPotion-01:23-N2-2.mp4"
      isGif
      ageId={age}
      nodeNumber={nodeNumber}
      index={1}
    />
    <NodeContainerSmall>
      <NodeHeadingH3>Hocus Pocus:</NodeHeadingH3>
      <NodeText>
        Every great wizard has a few potions up their sleeve. Since you&apos;ll
        need money for a cloak, we&apos;ve found a potion to help you win at
        BINGO:
      </NodeText>

      <NodeText>2 tbsp Pepper</NodeText>
      <NodeText>2 tbsp Mustard (yellow works best) </NodeText>
      <NodeText>
        10 freshly picked Honeysuckles OR 20 grams of Organic Cloves
      </NodeText>
      <NodeText>1 sealable glass bottle to hold all ingredients.</NodeText>
      <NodeText>
        Next, find a BINGO game with some prize money!
        <NodeText>
          (*there are no actual guarantees this potion will make you money, but
          who doesn&apos;t love BINGO!)
        </NodeText>
      </NodeText>
    </NodeContainerSmall>
    <NodeVideoPlayer
      url="/assets/timelineModalMedia/WWD-Acct-KidTimeline-Age9-WizardOwl-01:23-N2-3.mp4"
      isGif
      ageId={age}
      nodeNumber={nodeNumber}
      index={2}
    />
    <NodeContainerSmall>
      <NodeHeadingH3>Wizard BFFs:</NodeHeadingH3>
      <NodeText>
        Every wizard needs a companion — one that strikes fear into the souls of
        enemies, and is also very cute. Like an owl! Did you know that owls are
        able to turn their heads a full 270 degrees? They&apos;ve adapted to
        nearly every ecosystem on the planet? Hoo knew?!
      </NodeText>
      <NodeText>
        The Eurasian Eagle Owl generally costs between $2,800 and $3,800.
        That&apos;ll take a LOT of BINGO wins, and it doesn&apos;t include money
        for food. You may want to start by adopting an owl through WWF for $25 a
        month. Or, inspired by Scabbers, try training a gerbil.
      </NodeText>
      <NodeText>
        You&apos;re now armed with the tools you need to become a wizard. Next
        up, come up with a rad Wizard name, and remember Ron Weasley&apos;s
        words: Don&apos;t let the Muggles get you down.
      </NodeText>
    </NodeContainerSmall>
  </NodeModal>
);

export default ModalA9N2;
