import * as Yup from 'yup';

const passwordRegex = /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/;
const passwordMinRequirements =
  'Password must contain at least 8 characters, one uppercase, one lowercase, one number and one special case character';

const passwordValidationNotRequired = {
  password: Yup.string().trim().matches(passwordRegex, passwordMinRequirements),
  passwordRepeat: Yup.string()
    .trim()
    .when('password', {
      is: (password) => password && password.length > 0,
      then: Yup.string().required('Repeat Password is required'),
    })
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
};

const passwordValidationRequired = {
  password: Yup.string()
    .required('Required')
    .matches(passwordRegex, passwordMinRequirements),
  passwordRepeat: Yup.string()
    .required('Required')
    .oneOf([Yup.ref('password')], 'Passwords must match'),
};

export { passwordValidationNotRequired, passwordValidationRequired };
