import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { get } from 'lodash';

import showNotification, { Messages } from '../../components/Notification';
import {
  getChildProfileApi,
  getParentProfileApi,
  updateKidApi,
  updateParentApi,
} from '../../apis';

import { AppThunk } from '..';
import { RootState } from '.';
import { trackChildEdit, trackParentEdit } from '../../lib/analytics';

// TODO: create a custom thunk to handle all the request

export interface UserType {
  cognitoUserId?: string;
  createdAt?: string;
  dateOfBirth?: string;
  email?: string;
  firstName?: string;
  id?: string;
  lastName?: string;
  updatedAt?: string;
  parentId?: string;
  avatarId?: number;
  isLoading?: boolean;
}

export interface GiftCardType {
  id: number;
  parentId: number;
  childId: number;
  avatarId: number;
  giftCardAmt: number;
  giftCardCurrency: string;
  giftCardExperienceLink: string;
  giftCardTypeId: number;
  giftCardGameName: string;
  transactionTimestamp: string;
  recipientName: string;
  childName: string;
  senderName: string;
  message: string;
}

export interface ParentType extends UserType {
  children?: Array<UserType>;
  parentGiftCards?: Array<GiftCardType>;
}
interface HomeState {
  parent: ParentType;
  child: UserType;
  children: Array<UserType>;
  currentChild: UserType;
  giftCards: Array<GiftCardType>;
}

const initialState: HomeState = {
  parent: {},
  child: {},
  children: [],
  giftCards: [],
  currentChild: {},
};

type ChildrenPayload = {
  children: Array<UserType>;
  parent: ParentType;
  giftCards: Array<GiftCardType>;
};

type ChildPayload = {
  giftCards: Array<GiftCardType>;
  child: UserType;
};

type CurrentChildPayload = {
  kid: UserType;
};

const homeSlice = createSlice({
  name: 'Auth',
  initialState,
  reducers: {
    getParentSuccess(state, action: PayloadAction<ChildrenPayload>) {
      return {
        ...state,
        children: action.payload.children,
        parent: action.payload.parent,
        giftCards: action.payload.giftCards,
      };
    },
    getChildSuccess(state, action: PayloadAction<ChildPayload>) {
      return {
        ...state,
        child: action.payload.child,
        giftCards: action.payload.giftCards,
      };
    },
    getParentFailed(state) {
      return { ...state, children: [] };
    },
    getChildFailed(state) {
      return { ...state, child: {}, giftCards: [] };
    },
    setCurrentChild(state, action: PayloadAction<CurrentChildPayload>) {
      return { ...state, currentChild: action.payload.kid };
    },
    updateParentBegin(state) {
      state.parent.isLoading = true;
      return state;
    },
    updateParentComplete(state) {
      state.parent.isLoading = false;
      return state;
    },
    updateChildBegin(state) {
      state.currentChild.isLoading = true;
      return state;
    },
    updateChildComplete(state) {
      state.currentChild.isLoading = false;
      return state;
    },
  },
});

// selector stuff
export const selectChildren = createSelector(
  (state: RootState) => state.homeReducer,
  (homeReducer: HomeState) => homeReducer.children,
);

export const selectGiftCards = createSelector(
  (state: RootState) => state.homeReducer,
  (homeReducer: HomeState) => homeReducer.giftCards,
);

export const selectParent = createSelector(
  (state: RootState) => state.homeReducer,
  (homeReducer: HomeState) => homeReducer.parent,
);

export const selectChild = createSelector(
  (state: RootState) => state.homeReducer,
  (homeReducer: HomeState) => homeReducer.child,
);

export const selectCurrentChild = createSelector(
  (state: RootState) => state.homeReducer,
  (homeReducer: HomeState) => homeReducer.currentChild,
);

export const selectParentGreeting = createSelector(
  (state: RootState) => state.homeReducer,
  (homeReducer: HomeState) => homeReducer.parent.firstName,
);

export const {
  getParentSuccess,
  getParentFailed,
  getChildSuccess,
  getChildFailed,
  setCurrentChild,
  updateParentBegin,
  updateParentComplete,
  updateChildBegin,
  updateChildComplete,
} = homeSlice.actions;

export const getParentProfile = (): AppThunk => async (dispatch) => {
  try {
    dispatch(updateParentBegin());
    const response = await getParentProfileApi();
    dispatch(updateParentComplete());
    dispatch(
      getParentSuccess({
        children: get(response, 'data.children', []),
        parent: get(response, 'data', {}),
        giftCards: get(response, 'data.giftCards', []),
      }),
    );
  } catch (error) {
    dispatch(updateParentComplete());
    showNotification({
      type: 'error',
      message: get(
        error,
        'response.data.error.message',
        Messages.somethingWentWrong,
      ),
    });
    dispatch(getParentFailed());
  }
};

export const getChild = (): AppThunk => async (dispatch) => {
  try {
    dispatch(updateChildBegin());
    const response: any = await getChildProfileApi();
    dispatch(updateChildComplete());
    dispatch(
      getChildSuccess({
        child: get(response, 'data.child', {}),
        giftCards: get(response, 'data.giftCards', []),
      }),
    );
  } catch (error) {
    dispatch(updateChildComplete());
    showNotification({
      type: 'error',
      message: get(
        error,
        'response.data.error.message',
        Messages.somethingWentWrong,
      ),
    });
    dispatch(getChildFailed());
  }
};

export const updateParent = (params: any, history: any): AppThunk => async (
  dispatch,
) => {
  try {
    dispatch(updateParentBegin());
    await updateParentApi(params);
    dispatch(updateParentComplete());
    showNotification({
      type: 'success',
      message: Messages.updatedAccountSuccessfully,
    });
    trackParentEdit();
    history.push('/');
  } catch (error) {
    dispatch(updateParentComplete());
    showNotification({
      type: 'error',
      message: get(
        error,
        'response.data.error.message',
        Messages.somethingWentWrong,
      ),
    });
  }
};

export const updateKid = (
  params: any,
  history: any,
  childId?: string,
  path?: string,
): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(updateChildBegin());
    await updateKidApi(params, childId);
    dispatch(updateChildComplete());
    trackChildEdit();
    showNotification({
      type: 'success',
      message: Messages.updatedAccountSuccessfully,
    });
    const currentChild = getState().homeReducer.currentChild;
    dispatch(setCurrentChild({ kid: { ...currentChild, ...params } }));
    if (path) {
      history.push(path);
    } else {
      history.push(`/my-kids`);
    }
  } catch (error) {
    dispatch(updateChildComplete());
    showNotification({
      type: 'error',
      message: get(
        error,
        'response.data.error.message',
        Messages.somethingWentWrong,
      ),
    });
  }
};

export default homeSlice.reducer;
