import { Image } from '@chakra-ui/react';
import React from 'react';
import {
  NodeContainer,
  NodeContainerSmall,
  NodeHeading,
  NodeHeadingH3,
  NodeList,
  NodeListItem,
  NodeModal,
  NodeText,
  NodeVideoPlayer,
} from '../NodeComponents';
import { NodeModalProps } from './types';

const age = 15;
const nodeNumber = 0;

const ModalA15N1: React.FunctionComponent<NodeModalProps> = ({
  isOpen,
  onClose,
}) => (
  <NodeModal isOpen={isOpen} onClose={onClose}>
    <NodeVideoPlayer
      url="/assets/timelineModalMedia/WWD-Acct-KidTimeline-Age15-NBA-01:23-N1-Hero.mp4"
      isGif
      ageId={age}
      nodeNumber={nodeNumber}
      index={0}
    />
    <NodeContainer>
      <NodeHeading>Economics of the Draft</NodeHeading>
      <NodeText>
        Every year, around the third week in June, the NBA Draft turns a handful
        of teenage hoopers into overnight millionaires. It turns out that even
        people who are great at making money can still be bad at keeping it, so
        now the NBA holds mandatory financial literacy classes for its Rookies.
      </NodeText>
      <NodeText>
        To show you how we can all benefit from learning and practicing the
        basics of finlit, here are some memorable purchases from NBA Rooks:
      </NodeText>
    </NodeContainer>
    <Image
      src="/assets/timelineModalMedia/WWD-Acct-KidTimeline-Age15-NBACats-01:23-N1-1.png"
      alt="Dancing Lions"
    />
    <NodeContainerSmall>
      <NodeHeadingH3>Ben Simmons: Exotic pets</NodeHeadingH3>
      <NodeText>
        The 2016 1st overall pick banked $6 million before playing a single NBA
        game, then dropped $10K of that on a pair of Savannah cats. Simmons was
        forced to give up the designer pets after they became too much of a
        headache. Ironic, since Ben was “given up” a few years later when he
        proved too much of a headache for the Sixers!
      </NodeText>
    </NodeContainerSmall>
    <NodeContainerSmall>
      <NodeHeadingH3>Harrison Barnes: A “really nice bed”</NodeHeadingH3>
      <NodeText>
        Barnes finally made his childhood dream of owning a “nice bed” come true
        after being drafted by the Warriors, saying that mattress shopping felt
        “like Christmas” after years of counting every penny.
      </NodeText>
    </NodeContainerSmall>
    <NodeVideoPlayer
      url="/assets/timelineModalMedia/WWD-Acct-KidTimeline-Age15-Sapphire-01:23-N1-2.mp4"
      isGif
      ageId={age}
      nodeNumber={nodeNumber}
      index={1}
    />
    <NodeContainerSmall>
      <NodeHeadingH3>Jalen Rose: $15K on a phone</NodeHeadingH3>
      <NodeText>
        Yes, you read that right. Rose spent his entire rookie contract,
        including $15,000 on a sapphire crystal phone with 24/7 concierge
        service. It was called “the Rolls-Royce of phones” — and Rose never even
        used it.
      </NodeText>
    </NodeContainerSmall>
    <NodeContainerSmall>
      <NodeHeadingH3>
        Shaquille O&apos;Neal: Millionaire → $80K in debt
      </NodeHeadingH3>
      <NodeText>
        Shaq signed a million-dollar endorsement deal leading up the &apos;92
        draft. By the time Orlando picked him #1 overall, it was all gone.
        Here&apos;s what he bought:
        <NodeList>
          <NodeListItem>
            3 Mercedes-Benz (1 for him, 1 for his dad, and a “little” Benz for
            mom) = $500K
          </NodeListItem>
          <NodeListItem>
            Jewelry, clothes, pager (ask your parents what this is!) and cell
            phone = $100K
          </NodeListItem>
          <NodeListItem>Taxes + agent fees = $400K+</NodeListItem>
        </NodeList>
      </NodeText>
    </NodeContainerSmall>
    <Image
      src="/assets/timelineModalMedia/WWD-Acct-KidTimeline-Age15-PixelDollar-01:23-N1-3.png"
      alt="Dollar Signs"
    />
    <NodeContainerSmall>
      <NodeHeadingH3>Michael Carter-Williams: $0</NodeHeadingH3>
      <NodeText>
        On the advice of his mom/manager, the promising Philly point guard lived
        off of endorsement deals while putting every cent of his rookie contract
        into a trust fund that he couldn&apos;t touch for three years.
      </NodeText>
    </NodeContainerSmall>
    <NodeContainer>
      <NodeText>
        We like this man&apos;s style, but look at YOU! You&apos;re doing
        something EVEN BETTER with your money, by leaving it to grow in your
        Wealthie account.
      </NodeText>
      <NodeText>
        So whether you&apos;re headed for the NBA or not, in our books,
        you&apos;re a baller.
      </NodeText>
    </NodeContainer>
  </NodeModal>
);

export default ModalA15N1;
