import { Box, Link } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import React from 'react';

const PrivacyPolicyLink = (): React.ReactElement => (
  <Box data-testid="privacy-policy-link">
    <Link as={RouterLink} to="/privacy-policy" target="_blank">
      Privacy Policy
    </Link>
  </Box>
);

export default PrivacyPolicyLink;
