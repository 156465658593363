import React from 'react';
import { Image } from '@chakra-ui/react';
import {
  NodeContainer,
  NodeHeading,
  NodeModal,
  NodeText,
  NodeVideoPlayer,
  NodeHashtags,
} from '../NodeComponents';
import { NodeModalProps } from './types';

const age = 8;
const nodeNumber = 0;

const ModalA8N1: React.FunctionComponent<NodeModalProps> = ({
  isOpen,
  onClose,
}) => (
  <NodeModal isOpen={isOpen} onClose={onClose}>
    <NodeVideoPlayer
      url="/assets/timelineModalMedia/WWD-Acct-KidTimeline-Age8-Mochi-01:23-N1-Hero.mp4"
      isGif
      ageId={age}
      nodeNumber={nodeNumber}
      index={0}
    />
    <NodeContainer>
      <NodeHeading>Make It Mochi</NodeHeading>
      <NodeText>
        Who likes ice cream? Okay, maybe a better question is… Who{' '}
        <b>doesn&apos;t</b> like ice cream? Probably nobody, right? Here are
        some more questions for you… Have you ever been to Japan? Have you ever
        had mochi? What is mochi?
      </NodeText>
    </NodeContainer>
    <NodeVideoPlayer
      url="/assets/timelineModalMedia/WWD-Acct-KidTimeline-Age8-Mochi-01:23-N1-1.mp4"
      isGif
      ageId={age}
      nodeNumber={nodeNumber}
      index={1}
    />
    <NodeContainer>
      <NodeText>
        The first thing you should know about mochi is… it&apos;s delicious! The
        next thing you should know is that mochi has been a hit at parties in
        Japan since the 900s. Another thing you should know is that mochi is a
        marshmallowy rice treat that can be eaten on its own, or wrapped around
        balls of ice cream. BALLS OF ICE CREAM!!!
      </NodeText>
    </NodeContainer>
    <Image
      src="/assets/timelineModalMedia/WWD-Acct-KidTimeline-Age8-Mochi-01:23-N1-2.png"
      alt="Make it Mochi"
    />
    <NodeContainer>
      <NodeText>
        Here&apos;s something greater… you can make mochi at home with rice
        flour, sugar, water and cornstarch…a bunch of inexpensive ingredients!
      </NodeText>
      <NodeText>
        As for that ice cream in the middle? Well, that&apos;s where your budget
        comes into play. You can usually buy ice cream pretty inexpensively. Or
        you can get fancy!
      </NodeText>
    </NodeContainer>
    <NodeVideoPlayer
      url="/assets/timelineModalMedia/WWD-Acct-KidTimeline-Age8-Mochi-01:23-N1-3.mp4"
      isGif
      ageId={age}
      nodeNumber={nodeNumber}
      index={2}
    />
    <NodeContainer>
      <NodeText>
        There&apos;s a place near Disneyland that sells a SINGLE serving of{' '}
        <b>24-karat gold</b> leaf ice cream for $14.95. They make it in small
        batches and they use rare and expensive ingredients. If that&apos;s not
        fancy enough, hop on over to Dubai in the United Arab Emirates. At
        Scoopi&apos;s, you can try a scoop of “Black Diamond” ice cream for a
        mere $817. This one also includes gold flakes, Iranian saffron and
        Italian truffles.
      </NodeText>
      <NodeText>
        But really, who needs all that? We think you should save the gold for
        your investment portfolio!
      </NodeText>
      <NodeHashtags>#iloveyousomochi</NodeHashtags>
    </NodeContainer>
  </NodeModal>
);

export default ModalA8N1;
