import React from 'react';
import { Image } from '@chakra-ui/react';

import {
  NodeContainer,
  NodeHeading,
  NodeModal,
  NodeText,
  NodeVideoPlayer,
} from '../NodeComponents';
import { NodeModalProps } from './types';

const age = 14;
const nodeNumber = 0;

const ModalA14N2: React.FunctionComponent<NodeModalProps> = ({
  isOpen,
  onClose,
}) => (
  <NodeModal isOpen={isOpen} onClose={onClose}>
    <NodeVideoPlayer
      url="/assets/timelineModalMedia/WWD-Acct-KidTimeline-Age14-Sneakers-01:23-N2-Hero.mp4"
      isGif
      ageId={age}
      nodeNumber={nodeNumber}
      index={0}
    />
    <NodeContainer>
      <NodeHeading>The Economics of Kicks</NodeHeading>
      <NodeText>
        When it comes to shopping, what you see isn&apos;t always what you get.
        Take Nike, for example... The University of Oregon broke down the actual
        cost of a pair of Nike sneakers. One pair costs $28.50 to produce, but
        it sells for about $100.
      </NodeText>
    </NodeContainer>
    <Image
      src="/assets/timelineModalMedia/WWD-Acct-KidTimeline-Age14-Sneakers-01:23-N2-1.png"
      alt="Sneakers"
    />
    <NodeContainer>
      <NodeText>
        So, what&apos;s the deal?
        <br />
        Some of that extra $71.50 is a brand markup — what you&apos;re willing
        to pay for a sweet pair of Nike kicks! (It&apos;s actually a lot. Back
        in 2014, Psychology Today explained that tweens were directing $150B of
        their parents&apos; money EVERY YEAR into the brands they liked.)
      </NodeText>
    </NodeContainer>
    <NodeVideoPlayer
      url="/assets/timelineModalMedia/WWD-Acct-KidTimeline-Age14-Coins-01:23-N2-2.mp4"
      isGif
      ageId={age}
      nodeNumber={nodeNumber}
      index={1}
    />
    <NodeContainer>
      <NodeText>
        But that extra money also has to cover the initial cost of developing
        and testing the shoe, some profit for the retailer, and other expenses
        such as shipping, marketing, research and development, tax, even
        employees.
      </NodeText>
    </NodeContainer>
    <NodeVideoPlayer
      url="/assets/timelineModalMedia/WWD-Acct-KidTimeline-Age14-Sneakers-01:23-N2-3.mp4"
      isGif
      ageId={age}
      nodeNumber={nodeNumber}
      index={2}
    />
    <NodeContainer>
      <NodeText>
        Celebs play a role in shoe prices, too. Nike&apos;s famous{' '}
        <b>Air Jordan </b>
        high top dropped in 1985. Nike sold $126M worth of Jordans in that first
        year. MJ&apos;s deal with Nike has made him over $1.3B in the years
        since, and the price of a pair of Jordans worn by the man himself went
        for $600K USD at auction in 2020.
      </NodeText>
    </NodeContainer>
  </NodeModal>
);

export default ModalA14N2;
