import React, { useEffect, useState } from 'react';
import { Box, Button, Heading, Image, Text } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';

import ContainerComponent from '../../components/Container';
import Header from '../../layout/Header/Header';
import GreenGiftBox from '../../assets/images/Animated-Gift-Box.gif';
import { trackAssignModalClose } from '../../lib/analytics';
import { ParentType } from '../../store/reducers/homeSlice';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import WealthieHelmet from '../../components/WealthieHelmet';

interface InvestGiftCardScreenProps {}

const InvestGiftCardScreen: React.FunctionComponent<InvestGiftCardScreenProps> = () => {
  const history = useHistory();
  const [isBackGoHome, setIsBackGoHome] = useState(false);

  const parent: ParentType = useSelector((state) =>
    get(state, 'homeReducer.parent'),
  );

  const handleComplete = () => {
    trackAssignModalClose();
    window.open(
      'https://join.cidirectinvesting.com/ci-di-wealthie/',
      'wealthie_invest',
    );
    if (parent.parentGiftCards && parent.parentGiftCards.length > 1) {
      history.push('/assign-gift-cards');
    } else {
      history.push('/home');
    }
  };

  useEffect(() => {
    if (parent.parentGiftCards && parent.parentGiftCards.length > 1) {
      setIsBackGoHome(false);
    } else {
      setIsBackGoHome(true);
    }
  }, [parent.parentGiftCards]);

  return (
    <ContainerComponent>
      <WealthieHelmet title="Invest Card" />
      <Header showNav headerStyle="backArrow" isBackGoHome={isBackGoHome} />
      <Box display="flex" flexDir="column" alignItems="center">
        <Image src={GreenGiftBox} alt="Green Gift Box" maxWidth="274px" />
        <Heading as="h1" mt="10px" fontSize="largeText" lineHeight="title">
          Success!
        </Heading>
        <Box
          mt="10px"
          textAlign="center"
          fontSize="smallTitle"
          fontWeight="smallText"
          lineHeight="medText"
          maxWidth="245px"
        >
          <Text>Your first gift card has been assigned.</Text>
          <br />
          <Text>Up next, setting up your investment account. </Text>
          <br />
          <Text>
            (You&apos;ll only have to do this once, and it&apos;s easy!)
          </Text>
        </Box>
        <Button
          variant="action"
          colorScheme="accent"
          mt="20px"
          onClick={handleComplete}
        >
          INVEST CARD
        </Button>
      </Box>
    </ContainerComponent>
  );
};

export default InvestGiftCardScreen;
