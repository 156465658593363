import React from 'react';
import {
  NodeContainer,
  NodeHeading,
  NodeModal,
  NodeText,
  NodeVideoPlayer,
  NodeHashtags,
} from '../NodeComponents';
import { NodeModalProps } from './types';

const age = 6;
const nodeNumber = 1;

const ModalA6N2: React.FunctionComponent<NodeModalProps> = ({
  isOpen,
  onClose,
}) => (
  <NodeModal isOpen={isOpen} onClose={onClose}>
    <NodeVideoPlayer
      url="/assets/timelineModalMedia/WWD-Acct-KidTimeline-Age6-Gaming.mp4"
      isGif
      ageId={age}
      nodeNumber={nodeNumber}
      index={0}
    />
    <NodeContainer>
      <NodeHeading>Gaming History 101</NodeHeading>
      <NodeText>
        Gamers love to talk about games while they&apos;re gaming. Game. Games.
        Gaming. That&apos;s a weird word.
      </NodeText>
      <NodeText>Here&apos;s a little trivia:</NodeText>
      <NodeText>
        The first ever “video game” was called “Tennis for Two”. It was created
        in 1958 and was basically tennis in its simplest form. Most of you
        probably wouldn&apos;t even call it a video game if you saw it.
      </NodeText>
    </NodeContainer>
    <NodeVideoPlayer
      url="/assets/timelineModalMedia/WWD-Acct-KidTimeline-Age6-GamingHistory-01:23-N2-1.mp4"
      isGif
      ageId={age}
      nodeNumber={nodeNumber}
      index={1}
    />
    <NodeContainer>
      <NodeText>
        Then there was Spacewar &#40;aka Computer Space&#41;, then Pong, and
        then… Well, then Atari &#40;the makers of Pong&#41; created a system
        people could buy for their homes with which they could play numerous
        different games. And, well, you know the rest. Nintendo, Sega,
        Playstation, XBox, computers, smart phones…
      </NodeText>
      <NodeVideoPlayer
        url="/assets/timelineModalMedia/WWD-Acct-KidTimeline-Age6-GamingHistory-01:23-N2-2.mp4"
        isGif
        ageId={age}
        nodeNumber={nodeNumber}
        index={2}
      />
      <NodeText>
        The gaming industry is worth more than <b>$200 billion</b> and growing.
        Minecraft videos alone have hit <b>one trillion views</b> on YouTube.
        That&apos;s the equivalent of everyone in the world watching a Minecraft
        video 130 times. How many minecraft videos do you think your
        grandparents have watched?
      </NodeText>
      <NodeHashtags>#artsandminecrafts</NodeHashtags>
      <NodeVideoPlayer
        url="/assets/timelineModalMedia/WWD-Acct-KidTimeline-Age6-GamingHistory-01:23-N2-3.mp4"
        isGif
        ageId={age}
        nodeNumber={nodeNumber}
        index={3}
      />
    </NodeContainer>
  </NodeModal>
);

export default ModalA6N2;
