import React from 'react';
import { Box, Text, Image } from '@chakra-ui/react';

interface TourTooltipImageProps {
  text: string;
  src: string;
  alt: string;
  width?: string;
}

const TourTooltipImage = ({
  text,
  src,
  alt,
  width,
}: TourTooltipImageProps): React.ReactElement => (
  <Box>
    <Box display="flex" justifyContent="center">
      <Image src={src} alt={alt} marginBottom="10px" width={width} />
    </Box>
    <Text fontSize="subText" fontWeight="smallText" lineHeight="extraSmallText">
      {text}
    </Text>
  </Box>
);

export default TourTooltipImage;
