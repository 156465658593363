import React from 'react';
import { Heading, HeadingProps } from '@chakra-ui/react';

const NodeHeading: React.FunctionComponent<HeadingProps> = ({ children }) => (
  <Heading
    as="h2"
    fontWeight="medText"
    fontSize="subTitleSection"
    lineHeight="medText"
  >
    {children}
  </Heading>
);

export default NodeHeading;
