import React from 'react';
import moment from 'moment';
import { Box, Image } from '@chakra-ui/react';

import { GiftCardType } from '../../store/reducers/homeSlice';
import { boxImage, openMeImage } from './images';

interface Props {
  card: GiftCardType | null;
  onOpenGame?: (childId: number) => void;
}

function GiftCardDetail({ card, onOpenGame }: Props): React.ReactElement {
  if (!card) {
    return <></>;
  }
  const cardAssetSeed = moment(card.transactionTimestamp).unix();
  const handleOnOpenGame = () => {
    if (!onOpenGame) {
      return;
    }
    return onOpenGame(card.childId);
  };

  return (
    <>
      {onOpenGame && (
        <>
          <Box as="a" onClick={handleOnOpenGame} cursor="pointer">
            <Image
              src={boxImage(cardAssetSeed)}
              alt="Gift Box"
              width="130px"
              height="130px"
            />
          </Box>
          <Box alignSelf="start" justifySelf="flex-start">
            <Image src={openMeImage} alt="Start the gift experience" />
          </Box>
        </>
      )}
    </>
  );
}

export default GiftCardDetail;
