import { Image } from '@chakra-ui/react';
import React from 'react';
import {
  NodeContainer,
  NodeContainerSmall,
  NodeHeading,
  NodeHeadingH3,
  NodeModal,
  NodeText,
  NodeVideoPlayer,
} from '../NodeComponents';
import { NodeModalProps } from './types';

const age = 18;
const nodeNumber = 0;

const ModalA18N1: React.FunctionComponent<NodeModalProps> = ({
  isOpen,
  onClose,
}) => (
  <NodeModal isOpen={isOpen} onClose={onClose}>
    <NodeVideoPlayer
      url="/assets/timelineModalMedia/WWD-Acct-KidTimeline-Age18-TimeisMoney-01:23-N1-Hero.mp4"
      isGif
      ageId={age}
      nodeNumber={nodeNumber}
      index={0}
    />
    <NodeContainer>
      <NodeHeading>Time Is Money</NodeHeading>
      <NodeHeadingH3>
        Over time, history has been good to patient investors.
      </NodeHeadingH3>
    </NodeContainer>
    <NodeContainerSmall>
      <NodeHeadingH3>
        Here&apos;s a look back at the historical odds of making money in the US
        markets
      </NodeHeadingH3>
      <NodeText>
        50/50 over one-day periods
        <br />
        68% over one year periods <br />
        88% over 10 year
        <br />
        periods 100% over 20 year periods.
        <br />
      </NodeText>
      <NodeText>(SOURCE: Morgan Housel, The Psychology of Money)</NodeText>
    </NodeContainerSmall>
    <Image
      src="/assets/timelineModalMedia/WWD-Acct-KidTimeline-Age18-NeonGraph-01:23-N1-1.png"
      alt="Neon graph"
    />
    <NodeContainer>
      <NodeText>
        The takeaway: the longer you give your money to grow, the better it
        tends to do (assuming it&apos;s in a diversified portfolio — which, if
        you&apos;re with Wealthie, it IS!).
      </NodeText>
      <NodeText>In a phrase: Time = Money.</NodeText>
      <NodeText>
        This is one of the most valuable lessons you can take with you for life.
        Oh. That, and the one about compound interest…
      </NodeText>
    </NodeContainer>
    <Image
      src="/assets/timelineModalMedia/WWD-Acct-KidTimeline-Age18-NeonCoins-01:23-N1-2.png"
      alt="Neon coins"
    />
    <NodeContainer>
      <NodeText>
        P.S. Compound interest is the interest on your interest. When you invest
        money, your money makes money. That interest joins your principal
        investment, and together, they make more interest. This is how your
        money starts to grow on its own. If it wasn&apos;t math, it would be
        magic. Compound interest acts like a snowball rolling down a mountain.
        It grows as your money does. It&apos;s great when it&apos;s working on
        your savings. It&apos;s terrible when it starts working on your debts.
        It&apos;s why people will tell you to pay down your most expensive debt
        first, and not the biggest.
      </NodeText>
    </NodeContainer>
  </NodeModal>
);

export default ModalA18N1;
