const Heading = {
  variants: {
    siteTitle: (props): any => ({
      bg: props.tail ? 'red.300' : 'blue',
    }),
    title: (): any => ({
      fontFamily: 'subTitle',
      fontSize: '1.875rem',
      fontWeight: '700',
      color: 'brand.primary',
      lineHeight: '120%',
    }),
    // These are likely to be the new title
    // fontSize="largeText"
    // fontWeight="largeText"
    // lineHeight="title"
    subTitle: (): any => ({
      fontFamily: 'subTitle',
      fontSize: 'subTitle',
      fontWeight: '600',
      color: 'brand.primary',
      lineHeight: '32px',
      letterSpacing: '0.17px',
    }),
    subTitleSection: (): any => ({
      fontFamily: 'subTitleSection',
      fontSize: 'subTitleSection',
      fontWeight: '600',
      color: 'brand.primary',
      lineHeight: '32px',
      fontFeatureSettings: "'liga' off",
    }),
    small: (): any => ({
      fontFamily: 'subTitle',
      fontSize: 'smallTitle',
      fontWeight: '600',
      color: 'brand.primary',
    }),
  },
};

export default Heading;
