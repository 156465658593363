import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/reducers';
import { isKidAuthSelector } from '../../store/reducers/authSlice';
import ParentLandingPage from './ParentLandingPage';
import KidLandingPage from './KidHomePage';

function LandingPage(): React.ReactElement {
  const isKid = useSelector((state: RootState) => isKidAuthSelector(state));

  if (isKid) return <KidLandingPage />;
  return <ParentLandingPage />;
}

export default LandingPage;
