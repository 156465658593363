import React from 'react';
import { NodeModal, NodeVideoPlayer } from '../NodeComponents';
import { NodeModalProps } from './types';

const age = 2;
const nodeNumber = 0;

const ModalA2N1: React.FunctionComponent<NodeModalProps> = ({
  isOpen,
  onClose,
}) => (
  <NodeModal isOpen={isOpen} onClose={onClose}>
    <NodeVideoPlayer
      url="/assets/timelineModalMedia/WWD-Acct-KidTimeline-Age2-Peacock-01:23-N1.mp4"
      isGif
      ageId={age}
      nodeNumber={nodeNumber}
      index={0}
    />
  </NodeModal>
);

export default ModalA2N1;
