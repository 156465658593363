import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Text } from '@chakra-ui/react';

const SignUp = (): React.ReactElement => (
  <Container centerContent gap="4px">
    <Text fontWeight="smallText" fontSize="smallTitle" lineHeight="largeText">
      New to Wealthie Works Daily?
    </Text>
    <Text
      data-testid="sign_up_link"
      fontWeight="smallText"
      fontSize="smallTitle"
      lineHeight="largeText"
      as={Link}
      to="/register"
      textDecorationThickness="1px"
    >
      Create An Account
    </Text>
  </Container>
);

export default SignUp;
