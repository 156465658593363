import React from 'react';
import {
  NodeModal,
  NodeVideoPlayer,
  NodeContainer,
  NodeHeading,
} from '../NodeComponents';
import { NodeModalProps } from './types';

const age = 0;
const nodeNumber = 0;

const ModalA0N1: React.FunctionComponent<NodeModalProps> = ({
  isOpen,
  onClose,
}) => (
  <NodeModal isOpen={isOpen} onClose={onClose}>
    <NodeVideoPlayer
      url="/assets/timelineModalMedia/WWD-Acct-KidTimeline-Age0-StationID-01:23-N1.mp4"
      ageId={age}
      nodeNumber={nodeNumber}
      index={0}
    />
    <NodeContainer>
      <NodeHeading>Welcome to Wealthie!</NodeHeading>
    </NodeContainer>
  </NodeModal>
);

export default ModalA0N1;
