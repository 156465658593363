import React from 'react';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { trackAvatarSelect } from '../../lib/analytics';

import { UserType } from '../../store/reducers/homeSlice';
import AvatarIcons from './AvatarIcon';

import styles from './styles.module.scss';

export interface AvatarPickerProps extends UserType {
  sliderClassName?: string;
  onChange?: (index: number) => void;
  avatarId: number;
}

export interface AvatarItemProps {
  index: number;
  onSelect?: (index: number) => void;
  selected: number;
}

function AvatarItem({ index, onSelect, selected }: AvatarItemProps) {
  return (
    <div
      className={`${styles.child_detail_wrapper} avatar_picker`}
      data-testid="avatar_picker"
      onClick={() => onSelect && onSelect(index)}
    >
      {/* {selected === index && <div className={styles.picker_avatar_selected} />} */}
      <img
        src={AvatarIcons[`avatar${index}`]}
        className={`${styles.ava} ${styles.picker_avatar}`}
        alt={`avatar_${index}`}
      />
      {selected === index && (
        <>
          {/* <div className={styles.picker_avatar_selected_item_inner} /> */}
          <div className={styles.picker_avatar_selected_item} />
        </>
      )}
    </div>
  );
}

function AvatarPicker(props: AvatarPickerProps): React.ReactElement {
  const { onChange, avatarId } = props;
  const handleOnSelect = (index) => {
    onChange && onChange(index + 1);
    trackAvatarSelect(index);
  };
  const count = AvatarIcons.length;
  const sliderSettings = {
    infinite: false,
    slidesToShow: 4.75,
    swipeToSlide: true,
    arrows: false,
  };
  const numbers = Array.from(Array(count).keys());
  const listItems = numbers.map((number) => (
    <AvatarItem
      key={number.toString()}
      index={number}
      selected={avatarId - 1}
      onSelect={handleOnSelect}
    />
  ));
  return (
    <Slider
      className={`${styles.sliderContainer}, avatarSlider`}
      {...sliderSettings}
    >
      {listItems}
    </Slider>
  );
}
export default AvatarPicker;
