import * as React from 'react';
import { Text } from '@chakra-ui/react';

const AddChildText = ({
  parentsChildren,
}: {
  parentsChildren: any[];
}): React.ReactElement => {
  const addChildText =
    parentsChildren &&
    Array.isArray(parentsChildren) &&
    parentsChildren.length > 0
      ? 'Create an account for an additional child'
      : 'Create an account for a child';

  return (
    <Text
      w="200px"
      textAlign={'center'}
      fontSize="subText"
      fontWeight="smallText"
      lineHeight="smallText"
    >
      {addChildText}
    </Text>
  );
};

export default AddChildText;
