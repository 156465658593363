import React from 'react';
import {
  Box,
  Center,
  Image,
  SquareProps,
  Text,
  VStack,
} from '@chakra-ui/react';

import AchievementBadgeIcon from './icon';

interface Props {
  message?: string;
}

function resolveImageSrc(name: string) {
  switch (name) {
    case 'welcome':
      return require('../../assets/images/badges/welcome.png').default;
    case 'sun':
      return require('../../assets/images/badges/sun.png').default;
    case 'plant':
      return require('../../assets/images/badges/plant.png').default;
    case 'start_early':
      return require('../../assets/images/badges/start_early.png').default;
    default: {
      return require('../../assets/images/badges/welcome.png').default;
    }
  }
}

const AchievementBadge = ({ message }: Props): React.ReactElement => (
  <VStack>
    <Box pos="relative">
      <AchievementBadgeIcon boxSize={40} color={'brand.primary'} />
      <Box
        pos="absolute"
        top="0"
        left="0"
        bottom="0"
        right="0"
        w="100%"
        h="100%"
      >
        <Center h="100%">
          <Text variant="badgeText">Welcome Badge</Text>
        </Center>
      </Box>
    </Box>
    {message && (
      <Center>
        <Text variant="badgeTextDark">{message}</Text>
      </Center>
    )}
  </VStack>
);

interface ImageBadgeProps extends SquareProps {
  image: string;
  title?: string;
  size?: string;
}

const AchievementBadgeImage = ({
  image,
  title,
  size = '188px',
  ...rest
}: ImageBadgeProps): React.ReactElement => (
  <Box pos="relative" {...rest}>
    <Center>
      <Image src={resolveImageSrc(image)} w={size} h={size} />
    </Center>
    {title && (
      <Center>
        <Text variant="badgeTextDark">{title}</Text>
      </Center>
    )}
  </Box>
);

export default AchievementBadge;
export { AchievementBadgeImage };
