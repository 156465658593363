import React from 'react';

import {
  NodeContainer,
  NodeHeading,
  NodeModal,
  NodeText,
  NodeVideoPlayer,
} from '../NodeComponents';
import { NodeModalProps } from './types';

const age = 16;
const nodeNumber = 0;

const ModalA16N2: React.FunctionComponent<NodeModalProps> = ({
  isOpen,
  onClose,
}) => (
  <NodeModal isOpen={isOpen} onClose={onClose}>
    <NodeVideoPlayer
      url="/assets/timelineModalMedia/WWD-Acct-KidTimeline-Age16-ElectricCar-01:23-N2-Hero.mp4"
      isGif
      ageId={age}
      nodeNumber={nodeNumber}
      index={0}
    />
    <NodeContainer>
      <NodeHeading>Is It Really the Future?</NodeHeading>
      <NodeText>
        The Jetsons promised us flying cars by 2022, and we&apos;re still
        waiting on hovercrafts.
      </NodeText>
      <NodeText>
        So far, the most futuristic thing we&apos;ve seen in transportation is
        the electric car. It&apos;s a start…
        <br />
        You&apos;ve heard of the majors — Tesla, Polestar, the Prius. But did
        you know there was an all-electric vehicle created way back in 1997?
      </NodeText>
    </NodeContainer>
    <NodeVideoPlayer
      url="/assets/timelineModalMedia/WWD-Acct-KidTimeline-Age16-Thunder-01:23-N2-1.mp4"
      isGif
      ageId={age}
      nodeNumber={nodeNumber}
      index={1}
    />
    <NodeContainer>
      <NodeText>
        GM created the EV-1, the first mass-produced electric car available to
        the public. There is an ongoing debate about why GM discontinued the
        model and, in 2003, recalled all of the cars and destroyed all but a
        handful of them.
      </NodeText>
      <NodeText>
        There have been lots of rumours and theories — everything from the
        car&apos;s high production costs, to the strength of the oil and gas
        lobby in Washington. A documentary called “Who Killed the Electric Car”
        dug into the mystery in 2006.
      </NodeText>
      <NodeText>
        But back to 2003 and GM&apos;s mass recall… Two men in California
        watched the destruction of the EV-1 with interest, and on July 1, 2003,
        Martin Eberhard and Marc Tarpenning launched a small electric car
        company, which Eberhard called &quot;a car manufacturer that is also a
        technology company&quot;.
      </NodeText>
    </NodeContainer>
    <NodeVideoPlayer
      url="/assets/timelineModalMedia/WWD-Acct-KidTimeline-Age16-ElectricCar-01:23-N2-2.mp4"
      isGif
      ageId={age}
      nodeNumber={nodeNumber}
      index={2}
    />
    <NodeContainer>
      <NodeText>
        In 2010, that small startup received a $465 million loan from the US
        Department of Energy to create a manufacturing facility in California.
        This loan was paid back in full (with interest) nearly 9 years earlier
        than expected. And now Tesla, Inc, formerly Tesla Motors, is one of the
        most recognized and talked about companies in the world.
      </NodeText>
    </NodeContainer>
  </NodeModal>
);

export default ModalA16N2;
