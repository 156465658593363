import React from 'react';
import Helmet from 'react-helmet';

interface WealthieHelmetProps {
  title: string;
}

const WealthieHelmet: React.FunctionComponent<WealthieHelmetProps> = ({
  title,
}) => (
  <Helmet>
    <title>Wealthie Works | {title}</title>
  </Helmet>
);

export default WealthieHelmet;
