import React from 'react';
import { Image } from '@chakra-ui/react';

import {
  NodeContainer,
  NodeContainerSmall,
  NodeHeading,
  NodeModal,
  NodeText,
  NodeVideoPlayer,
  NodeHeadingH3,
} from '../NodeComponents';
import { NodeModalProps } from './types';

const age = 15;
const nodeNumber = 0;

const ModalA15N2: React.FunctionComponent<NodeModalProps> = ({
  isOpen,
  onClose,
}) => (
  <NodeModal isOpen={isOpen} onClose={onClose}>
    <NodeVideoPlayer
      url="/assets/timelineModalMedia/WWD-Acct-KidTimeline-Age15-JurassicPark-01:23-N2-Hero.mp4"
      isGif
      ageId={age}
      nodeNumber={nodeNumber}
      index={0}
    />
    <NodeContainer>
      <NodeHeading>The Cost of Building Jurassic Park</NodeHeading>
      <NodeText>
        Love pets and looking to side hustle? Here are the straight numbers on a
        dino park of your own:
      </NodeText>
    </NodeContainer>
    <NodeContainerSmall>
      <NodeHeadingH3>$331.2 million: Cost to buy Isla Nublar</NodeHeadingH3>
      <NodeText>
        At 14,080 acres, Isla Nublar &ndash; home to the original JP and, later,
        Jurassic World &ndash; is roughly half the size of Disney World, giving
        dinos plenty of room to stretch their legs and wings.
      </NodeText>
      <NodeText>
        According to OfficialETA&apos;s, “The Price of Paradise” tool, a
        similarly-sized private island off the coast of Costa Rica will run you
        $331.2 million. If you&apos;re willing to downsize, the 800-acre
        “shovel-ready” Golfito Bay can be had for a bargain $17.5 mill.
      </NodeText>
    </NodeContainerSmall>
    <Image
      src="/assets/timelineModalMedia/WWD-Acct-KidTimeline-Age15-JurassicPark-01:23-N2-1.png"
      alt="Cranes (Machine)"
    />
    <NodeContainerSmall>
      <NodeHeadingH3>$1.2 billion: Construction costs</NodeHeadingH3>
      <NodeText>
        John Hammond (aka actor Richard Attenborough) spared no expense building
        the original JP. Neither did Jurassic World&apos;s Simon Masrani (aka
        Irrfan Khan). The new InGen CEO spent an estimated $1.2 billion on
        concrete and materials alone to build his new-and-improved park — about
        what it cost Disney to complete Animal Kingdom. Rawr.
      </NodeText>
    </NodeContainerSmall>
    <NodeContainerSmall>
      <NodeHeadingH3>$2 million (or more): Liability insurance</NodeHeadingH3>
      <NodeText>
        New Jersey&apos;s fantastically dangerous Action Park is the stuff of
        legend. (Look it up!) It got away without liability insurance. Gene
        Mulvihill, “The Walt Disney of New Jersey”, figured it was cheaper to
        pay injury claims out of pocket — his own. That was the &apos;80s, and
        times have changed. In 2020, $100M-worth of coverage cost Six Flags $2
        million in premiums. It&apos;s safe to assume Jurassic Park&apos;s
        payments would be higher. The prior claims history is a determining
        factor for insurance premiums, and there are probably some hefty claims
        (and eatings) dating back to 1993.
      </NodeText>
    </NodeContainerSmall>
    <Image
      src="/assets/timelineModalMedia/WWD-Acct-KidTimeline-Age15-JurassicPark-01:23-N2-2.png"
      alt="Dino footprints"
    />
    <NodeContainerSmall>
      <NodeHeadingH3>$300+ million: Cost of dino food, annually</NodeHeadingH3>
      <NodeText>
        Food. One area where you don&apos;t want to scrimp. The San Diego Zoo,
        one of the world&apos;s largest, spent $281,273,000 in 2021 to feed and
        care for 12,000+ animals across 650+ species. The fictional Jurassic
        World only features 20 species of dinos (cloning isn&apos;t cheap!), but
        we&apos;ve rounded up to be safe.
      </NodeText>
    </NodeContainerSmall>
    <NodeContainer>
      <NodeText>
        Love pets and looking to side hustle? Here are the straight numbers on a
        dino park of your own:
      </NodeText>
    </NodeContainer>
  </NodeModal>
);

export default ModalA15N2;
