const action = (props: any) => {
  let backgroundColor: string;
  let backgroundColorHover: string;
  let textColor: string;

  const disabledBGColor = 'brand.grey100';
  const disabledTextColor = 'brand.grey300';

  switch (props.colorScheme) {
    case 'accent': {
      backgroundColor = 'brand.accent';
      backgroundColorHover = 'brand.secondaryYellow';
      textColor = 'brand.primary';
      break;
    }
    default: {
      backgroundColor = 'brand.primary';
      backgroundColorHover = 'brand.titleHighlight';
      textColor = 'brand.lightText';
      break;
    }
  }

  return {
    border: '1px solid',
    borderColor: 'gray.200',
    color: textColor,
    bg: backgroundColor,
    fontSize: '1.3125rem',
    paddingX: '20px',
    w: 'min',
    height: '60px',
    // width: '100%',
    // my: 1,
    _disabled: {
      bg: disabledBGColor,
      color: disabledTextColor,
      opacity: '1',
    },
    _hover: {
      bg: backgroundColorHover,
      // This section was added as the button would change on hover when disabled
      _disabled: {
        bg: disabledBGColor,
        color: disabledTextColor,
        opacity: '1',
      },
    },
  };
};

const ComponentStyle = {
  // The styles all button have in common
  baseStyle: {
    textTransform: 'none',
    borderRadius: '30px',
  },
  sizes: {
    sm: {
      fontSize: 'sm',
      // px: 4,
      // py: 3,
    },
    md: {
      fontSize: 'md',
      // px: 6,
      // py: 4,
    },
    lg: {
      // fontSize: 'xl',
      py: '30px',
      // px: '20px',
    },
  },
  // Two variants: outline and solid
  variants: {
    outline: {
      border: '2px solid',
      borderColor: 'brand.primary',
      color: 'brand.primary',
      bg: 'brand.lightText',
    },
    actionSmall: (props): any => ({
      ...action(props),
    }),
    action: (props): any => ({
      ...action(props),
    }),
    infoAction: (props): any => ({
      ...action(props),
      // paddingStart: 4,
      // paddingEnd: 4,
    }),
    tertiary: {},

    solid: {
      bg: 'brand.primary',
      color: 'brand.lightText',
      marginTop: '2.25rem',
      marginBottom: '0.6rem',
    },
    text: {
      color: 'brand.primary',
      textDecoration: 'underline',
      fontWeight: '600',
      w: 'min',
      h: 'min',
      p: 0,
    },
  },
  // The default size and variant values
  defaultProps: {
    size: 'lg',
    variant: 'solid',
    colorScheme: 'blue',
  },
};

export default ComponentStyle;
