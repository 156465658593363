import React from 'react';
import {
  Image,
  Modal,
  ModalContent,
  ModalFooter,
  ModalBody,
  ModalOverlay,
} from '@chakra-ui/react';

import close from '../../../assets/images/timeline/modal_close.svg';
import mosaic from '../../../assets/images/mosaic.svg';

interface Props {
  onClose: () => void;
  isOpen: boolean;
  children?: any;
}

function NodeModal({ onClose, isOpen, children }: Props): React.ReactElement {
  const marginTop = '33px';
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered scrollBehavior="inside">
      <ModalOverlay />

      <ModalContent
        bg="white"
        px="0"
        w="100%"
        borderRadius="15px"
        mt={[0, 0, 0]}
        maxWidth="345px"
        paddingTop={marginTop}
        maxHeight="610px"
      >
        <Image
          w="25px"
          mr="33px"
          mb="22px"
          // mt={[marginTop, marginTop, '0']}
          // mt={marginTop} // TODO: Put back the above after
          cursor="pointer"
          // className={styles.giftCardCloseButton}
          onClick={onClose}
          src={close}
          alignSelf="flex-end"
        />
        <ModalBody
          padding="0"
          display="flex"
          flexDir="column"
          gap="20px"
          alignItems="center"
        >
          {children}
        </ModalBody>
        <ModalFooter padding="0" justifyContent="flex-start">
          <Image
            ml="32.5px"
            marginTop="30px"
            // className={styles.giftCardMosaicMarker}
            src={mosaic}
          />
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default NodeModal;
