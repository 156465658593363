import { ComponentStyleConfig } from '@chakra-ui/react';

const FormControl: ComponentStyleConfig = {
  // The styles all button have in common
  // sizes: {
  //   md: {
  //     mx: 0,
  //     marginTop: '20px',
  //   },
  // },
};

export default FormControl;
