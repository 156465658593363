import React from 'react';
import { Heading, HeadingProps } from '@chakra-ui/react';

const NodeHeadingH3: React.FunctionComponent<HeadingProps> = ({ children }) => (
  <Heading as="h3" fontWeight="medText" fontSize="subText" lineHeight="medText">
    {children}
  </Heading>
);

export default NodeHeadingH3;
