import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { Center, Text } from '@chakra-ui/react';

function HeaderTitle({ children }: any): React.ReactElement {
  const history = useHistory();
  return (
    <Center
      onClick={() => {
        history.replace('/');
      }}
    >
      <Text as="span" mt="1" variant="siteTitle">
        WEALTHIE
      </Text>
      <Text as="span" mt="1" variant="siteTitleTail">
        .WORKS
      </Text>
      {children}
    </Center>
  );
}

export default HeaderTitle;
