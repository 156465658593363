import React from 'react';
import { Helmet } from 'react-helmet';
import { Text } from '@chakra-ui/react';

import { GiftCardType } from '../../store/reducers/homeSlice';
import GiftCardPlayer from '../GiftCardPlayer/GiftCardPlayer';

interface Props {
  currentCard: GiftCardType | null;
  cardId: string;
}

const wealthieverseObject = {
  collectionName: 'WEALTHIEVERSE',
  videoWidth: {
    largeScreen: '700px',
    smallScreen: '345px',
  },
  videoHeight: {
    largeScreen: '585px',
    smallScreen: '300px',
  },
};
const artistCardsObject = {
  collectionName: 'The Artist Cards',
  videoWidth: {
    largeScreen: '640px',
    smallScreen: '345px',
  },
  videoHeight: {
    largeScreen: '640px',
    smallScreen: '345px',
  },
};

const giftCardPlayerObject = {
  // Wealthieverse Objects
  theOriginalOne: {
    graphic: '/assets/giftCardPlayer/Wealthieverse/Card1/graphic.png',
    video: '/assets/giftCardPlayer/Wealthieverse/Card1/video.mp4',
    stamp: '/assets/giftCardPlayer/Wealthieverse/Card1/stamp.png',
    cardName: 'Card 1: The Original One',
    ...wealthieverseObject,
  },
  theRobotOverlordOne: {
    graphic: '/assets/giftCardPlayer/Wealthieverse/Card2/graphic.png',
    video: '/assets/giftCardPlayer/Wealthieverse/Card2/video.mp4',
    stamp: '/assets/giftCardPlayer/Wealthieverse/Card2/stamp.png',
    cardName: 'Card 2: The Robot Overlord One',
    ...wealthieverseObject,
  },
  theOneThatWontDecide: {
    graphic: '/assets/giftCardPlayer/Wealthieverse/Card3/graphic.png',
    video: '/assets/giftCardPlayer/Wealthieverse/Card3/video.mp4',
    stamp: '/assets/giftCardPlayer/Wealthieverse/Card3/stamp.png',
    cardName: "Card 3: The One That Won't Decide",
    ...wealthieverseObject,
  },
  // Artist Card Objects
  theSingingOne: {
    graphic: '/assets/giftCardPlayer/TheArtistCards/Card1/graphic.png',
    video: '/assets/giftCardPlayer/TheArtistCards/Card1/video.mp4',
    stamp: '/assets/giftCardPlayer/TheArtistCards/Card1/stamp.png',
    cardName: 'Card 1: The Singing One',
    ...artistCardsObject,
  },
  theDancingOne: {
    graphic: '/assets/giftCardPlayer/TheArtistCards/Card2/graphic.png',
    video: '/assets/giftCardPlayer/TheArtistCards/Card2/video.mp4',
    stamp: '/assets/giftCardPlayer/TheArtistCards/Card2/stamp.png',
    cardName: 'Card 2: The Dancing One',
    ...artistCardsObject,
  },
  thePlayingOne: {
    graphic: '/assets/giftCardPlayer/TheArtistCards/Card3/graphic.png',
    video: '/assets/giftCardPlayer/TheArtistCards/Card3/video.mp4',
    stamp: '/assets/giftCardPlayer/TheArtistCards/Card3/stamp.png',
    cardName: 'Card 3: The Playing One',
    ...artistCardsObject,
  },
};

function GameContainer({ currentCard, cardId }: Props): React.ReactElement {
  const assetBase = currentCard
    ? `${process.env.PUBLIC_URL}/games/${currentCard.giftCardGameName}`
    : '';

  if (!currentCard || !cardId) {
    return <></>;
  }

  if (currentCard) {
    // is this is a supported video card?
    if (
      [
        'theOriginalOne',
        'theRobotOverlordOne',
        'theOneThatWontDecide',
        'theSingingOne',
        'theDancingOne',
        'thePlayingOne',
      ].includes(String(currentCard.giftCardGameName))
    ) {
      return (
        <GiftCardPlayer
          collectionName={
            giftCardPlayerObject[`${currentCard.giftCardGameName}`]
              .collectionName
          }
          videoWidth={
            giftCardPlayerObject[`${currentCard.giftCardGameName}`].videoWidth
          }
          videoHeight={
            giftCardPlayerObject[`${currentCard.giftCardGameName}`].videoHeight
          }
          cardName={
            giftCardPlayerObject[`${currentCard.giftCardGameName}`].cardName
          }
          graphicUrl={
            giftCardPlayerObject[`${currentCard.giftCardGameName}`].graphic
          }
          videoUrl={
            giftCardPlayerObject[`${currentCard.giftCardGameName}`].video
          }
          stampUrl={
            giftCardPlayerObject[`${currentCard.giftCardGameName}`].stamp
          }
          giftCardId={currentCard.giftCardTypeId}
        />
      );
    }
    // is this a supported game card?
    if (
      ![
        'bat-math',
        'dino-egg',
        'super-hero-memory',
        'code-panda',
        'coloring-book',
        'connect-the-roads',
        'dragon-island',
        'giggly-park-adventure',
        'seashore-treasure',
      ].includes(String(currentCard.giftCardGameName))
    ) {
      return (
        <Text>
          Sorry, there was an error loading the game or it is currently not
          supported. Please try again shortly
        </Text>
      );
    }
  }

  const title = `Game ${currentCard ? currentCard.giftCardGameName : ''}`;

  return (
    <div data-testid="child_game_page">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
      </Helmet>
      {currentCard && (
        <>
          <Helmet>
            <link
              rel="stylesheet"
              type="text/css"
              href={`${assetBase}/game.css`}
            />
            <script
              type="text/javascript"
              src={`${assetBase}/game.js`}
            ></script>
          </Helmet>
          <div id="ajaxbar">
            <div id="game">
              <canvas id="canvas"></canvas>
            </div>
            <div id="orientate">
              <img
                src={`${assetBase}/media/graphics/orientate/landscape.jpg`}
                alt="landscape"
              />
            </div>
            <div id="play" className="play">
              <img
                src={`${assetBase}/media/graphics/splash/mobile/cover-start.png`}
                alt="cover start"
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default GameContainer;
