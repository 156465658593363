import { Image } from '@chakra-ui/react';
import React from 'react';
import {
  NodeContainer,
  NodeHeading,
  NodeModal,
  NodeText,
  NodeVideoPlayer,
  NodeHashtags,
} from '../NodeComponents';
import { NodeModalProps } from './types';

const age = 6;
const nodeNumber = 0;

const ModalA6N1: React.FunctionComponent<NodeModalProps> = ({
  isOpen,
  onClose,
}) => (
  <NodeModal isOpen={isOpen} onClose={onClose}>
    <NodeVideoPlayer
      url="/assets/timelineModalMedia/WWD-Acct-KidTimeline-Age6-Canada.mp4"
      isGif
      ageId={age}
      nodeNumber={nodeNumber}
      index={0}
    />
    <NodeContainer>
      <NodeHeading>Cross Continental</NodeHeading>
      <NodeText>
        <b>Canada Geese</b> love to travel, and they&apos;re good at it. Like,
        really good. Geese are so good at commuting that they can fly up to
        1,500 km &#40;932 miles&#41; in a day! And they can travel more than
        4,500 km &#40;2800 miles&#41; in a single migration. It helps that they
        can sleep while they fly — for 10 seconds at a time…with one eye open.
        ONE EYE OPEN! it&apos;s true, look it up.
      </NodeText>
      <NodeText>
        Unfortunately, we humans can&apos;t fly on our own…yet. So, some of us
        drive cars. Well, our parents do. So let&apos;s say our family wanted to
        “migrate” from Sudbury to Miami for the winter. To fuel up that
        gas-powered car, it would cost us anywhere from $400 - $600.
      </NodeText>
    </NodeContainer>
    <Image
      src="/assets/timelineModalMedia/WWD-Acct-KidTimeline-Age6-PixelCar-01:23-N1-1.png"
      alt="Pixel Car"
    />
    <NodeContainer>
      <NodeText>
        So those Canada Geese are saving some sweet cash by using their wings.
        But, they don&apos;t get to stop for donuts and slurpees.
      </NodeText>
      <NodeHashtags>#migration #slurpees</NodeHashtags>
    </NodeContainer>
  </NodeModal>
);

export default ModalA6N1;
