import React from 'react';
import { Image } from '@chakra-ui/react';

import {
  NodeContainer,
  NodeHeading,
  NodeModal,
  NodeText,
  NodeVideoPlayer,
} from '../NodeComponents';
import { NodeModalProps } from './types';

const age = 17;
const nodeNumber = 0;

const ModalA17N2: React.FunctionComponent<NodeModalProps> = ({
  isOpen,
  onClose,
}) => (
  <NodeModal isOpen={isOpen} onClose={onClose}>
    <NodeVideoPlayer
      url="/assets/timelineModalMedia/WWD-Acct-KidTimeline-Age17-MarketSuperstitions-01:23-N2-Hero.mp4"
      isGif
      ageId={age}
      nodeNumber={nodeNumber}
      index={0}
    />
    <NodeContainer>
      <NodeHeading>The October Effect</NodeHeading>
      <NodeText>
        There&apos;s this common idea that <b>October</b> is the worst month for
        the stock market. Some famous economic collapses in history have
        happened during October: <b>1907, 1929 and 1987.</b> But check your
        facts before you bail out of the markets in September! There&apos;s no
        actual evidence to support the idea that October&apos;s any different
        from any other time of year. In fact, it&apos;s just become a sort of
        <b> psychological hiccup.</b> Investors get nervous because of those old
        crashes, and that can make October a volatile month when enough people
        start to freak out. But <b>statistically</b> October&apos;s not a bad
        month at all.
      </NodeText>
    </NodeContainer>
    <Image
      src="/assets/timelineModalMedia/WWD-Acct-KidTimeline-Age17-MarketSuperstitions-01:23-N2-1.png"
      alt="Stars"
    />
    <NodeContainer>
      <NodeText>
        Warren Buffett, the famous nonagenarian investor, has a classic
        philosophy that helps keep him steady while people around him are
        panicking:
      </NodeText>
      <NodeText>
        “Be fearful when others are greedy, and greedy when others are fearful.”
      </NodeText>
      <NodeText>
        <b>Words to live by.</b>
      </NodeText>
    </NodeContainer>
  </NodeModal>
);

export default ModalA17N2;
