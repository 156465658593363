import React, { forwardRef, LegacyRef } from 'react';
import styles from './styles.module.scss';

function getText(age: number) {
  if (age === 0) {
    return 'HEY BABY!';
  } else if (age === 1) {
    return '1 YEAR OLD';
  } else {
    return `${age} YEARS OLD`;
  }
}

interface AgeSignProps {
  age: number;
}

const AgeSign = forwardRef<HTMLSpanElement, AgeSignProps>(function AgeSign(
  { age }: AgeSignProps,
  ref: LegacyRef<HTMLSpanElement>,
) {
  return (
    <span className={`${styles.ageSign} hey-baby`} id={`${age}`} ref={ref}>
      {getText(age)}
    </span>
  );
});

AgeSign.displayName = 'AgeSign';

export default AgeSign;
