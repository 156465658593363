import * as Yup from 'yup';
import { getDaysInMonth, getYear, parse, isValid } from 'date-fns';
import moment from 'moment';

const dobValidator = Yup.string()
  .required('Required')
  .test('len', 'Must be a valid date', (val) => {
    if (val && val.toString().indexOf('/') > -1) {
      if (val.length !== 10) {
        return false;
      }
      const dayValue = val.substring(0, 2);
      const monthValue = val.substring(3, 5);
      const yearValue = val.substring(6, 10);
      const dateToParse = `${monthValue}/${dayValue}/${yearValue}`;
      const parsed = parse(dateToParse, 'P', new Date(), {});
      const currentYear = new Date().getFullYear();
      if (!isValid(parsed)) {
        return false;
      }
      if (parsed.getFullYear() < 1900 || parsed.getFullYear() > currentYear) {
        return false;
      }
      return true;
    } else {
      return val?.toString().length === 8;
    }
  });

const dobFieldsValidation = {
  month: Yup.string()
    .test('month', 'Invalid Month (1)', (value) => {
      if (!value) {
        return true;
      }
      const monthValue = parseInt(value, 10);
      if (monthValue < 1 || monthValue > 12) {
        return false;
      }
      return true;
    })
    .min(1, 'Invalid length min')
    .max(2, 'Invalid length max')
    .required('Required'),
  year: Yup.string()
    .test('year', 'Valid Year required', (value) => {
      if (!value) {
        return true;
      }
      const yearValue = parseInt(value, 10);
      const today = new Date();
      const currentYear = today.getFullYear();
      if (yearValue < 1900 || yearValue > currentYear) {
        return false;
      }
      return true;
    })
    .matches(/^[0-9]+$/, 'Must be only digits')
    .min(4, 'Must be 4 digits')
    .max(4, 'Must be 4 digits')
    .required('Valid Year required'),
  day: Yup.string()
    .test('day', 'Invalid Day (1)', function (day) {
      if (!day) {
        return true;
      }
      const dayValue = parseInt(day, 10);
      const month = parseInt(this.options['parent'].month, 10);
      const year = parseInt(this.options['parent'].year, 10);
      if (Number.isNaN(month) || Number.isNaN(year)) {
        return true;
      }
      // February
      if (month === 2) {
        const isLeapYear =
          year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0);

        if (dayValue > 29 || (dayValue === 29 && !isLeapYear)) {
          return false;
        }
      }
      return true;
    })
    .test('day', 'Invalid Day (2)', function (day) {
      if (!day) {
        return true;
      }
      const dayValue = parseInt(day, 10);
      const month = parseInt(this.options['parent'].month, 10);
      if (Number.isNaN(month)) {
        return true;
      }
      if (
        (month === 4 || month === 6 || month === 9 || month === 11) &&
        dayValue === 31
      ) {
        return false;
      }
      return true;
    })
    .test('day', 'Invalid Day (3)', (day) => {
      if (!day) {
        return true;
      }
      const dayValue = parseInt(day, 10);
      if (dayValue < 1 || dayValue > 31) {
        return false;
      }
      return true;
    })
    .matches(/^[0-9]+$/, 'Digits Only')
    .min(1, 'Invalid Day (min1)')
    .max(2, 'Invalid Day (max2)')
    .required('Required'),
};

const isAllowedDateValues = (
  day: number,
  month: number,
  year: number,
): boolean => {
  const currentYear = new Date().getFullYear();
  if (year > currentYear || year < 1900) {
    return false;
  }
  const dateToParse = `${month}/${day}/${year}`;
  //  console.log('dateToParse', dateToParse);
  const parsed = parse(dateToParse, 'P', new Date(), {});
  //  console.log('isAllowed - parsed', parsed);
  return isValid(parsed);
};

const isAllowedDate = (values: any): boolean => {
  const { value }: { value: string } = values;
  if (!value || !value.length) {
    return true;
  }
  if (value.length >= 2) {
    const dayValue = value.substring(0, 2);
    const day = Number.parseInt(dayValue, 10);
    // console.log('dayValue', dayValue, day);
    if (Number.isNaN(day)) {
      return false;
    }
    if (dayValue.length >= 2 && (day > 31 || day < 1)) {
      return false;
    }
    if (value.length >= 4) {
      const monthValue = value.substring(2, 4);
      const month = Number.parseInt(monthValue, 10);
      // console.log('month', monthValue);
      if (Number.isNaN(month)) {
        return false;
      }
      if (monthValue.length === 2 && (month > 12 || month < 1)) {
        return false;
      }
      if (value.length === 8) {
        const yearValue = value.substring(4, 8);
        // console.log('yearValue', yearValue);

        const year = Number.parseInt(yearValue, 10);
        if (Number.isNaN(year)) {
          return false;
        }
        const currentYear = new Date().getFullYear();
        if (yearValue.length === 4 && (year > currentYear || year < 1900)) {
          return false;
        }
        const dateToParse = `${monthValue}/${dayValue}/${yearValue}`;
        // console.log('dateToParse', dateToParse);
        const parsed = parse(dateToParse, 'P', new Date(), {});
        // console.log('isAllowed - parsed', parsed);
        return isValid(parsed);
      }
    }
  }
  return true;
};

const isAllowedDay = (values: any): boolean => {
  const { value } = values;
  if (!value || !value.length) {
    return true;
  }
  const dayValue = value;
  const day = Number.parseInt(dayValue, 10);
  if (Number.isNaN(day)) {
    return false;
  }
  if (dayValue.length >= 2 && (day > 31 || day < 1)) {
    return false;
  }
  return true;
};

const isAllowedYear = (values: any): boolean => {
  const { value } = values;
  if (!value || !value.length) {
    return true;
  }
  if (value.length >= 4) {
    const yearValue = value;
    const year = Number.parseInt(yearValue, 10);
    if (Number.isNaN(year)) {
      return false;
    }
    const currentYear = new Date().getFullYear();
    if (yearValue.length === 4 && (year > currentYear + 1 || year < 1900)) {
      return false;
    }
  }
  return true;
};

const dobFromFields = (
  day: string,
  month: string,
  year: string,
): string | null => {
  const dateString = `${month}/${day}/${year}`;
  const parsedDob = parse(dateString, 'P', new Date(), {});
  // console.log(parsedDob);
  if (!isValid(parsedDob)) {
    return null;
  }
  return moment(dateString).format('DD/MM/YYYY');
};

const daysForMonth = (month: number): number => {
  if (month < 1) {
    return 0;
  }
  if (month > 12) {
    return 0;
  }
  return getDaysInMonth(new Date(2000, month - 1));
};

const yearsForMonthAndDay = (month: number, day: number): number[] => {
  const currentYear = getYear(new Date());
  if (month !== 2 || day !== 29) {
    return Array.from(Array(100).keys()).map((offset) => currentYear - offset);
  } else {
    const leapYears: number[] = [];
    for (let i = currentYear; i > currentYear - 100; i -= 1) {
      if ((!(i % 4) && i % 100) || !(i % 400)) {
        leapYears.push(currentYear - (currentYear - i));
      }
    }
    return leapYears;
  }
};

const yearList = (): number[] => {
  const currentYear = getYear(new Date());
  return Array.from(Array(100).keys()).map((offset) => currentYear - offset);
};

export {
  dobFromFields,
  dobValidator,
  isAllowedDate,
  isAllowedDay,
  isAllowedYear,
  isAllowedDateValues,
  dobFieldsValidation,
  daysForMonth,
  yearsForMonthAndDay,
  yearList,
};
