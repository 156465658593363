import { mode } from '@chakra-ui/theme-tools';
const styles = {
  global: (props: any): any => ({
    // any html tag can go here
    'html, body': {
      fontFamily: 'body',
      fontSize: '1rem',
      color: mode('brand.primary', 'whiteAlpha.900')(props),
      bg: mode('white', 'gray.800')(props),
    },
    a: {
      textDecoration: 'underline',
      fontWeight: '600',
    },
    label: {
      fontSize: '1rem',
      marginBottom: 0,
    },
    input: {
      fontSize: '1rem',
    },
  }),
};

export default styles;
