import axios, { AxiosResponse } from 'axios';
import * as APIConstant from '../constants/apiUrl';

export const reCaptchaKey = '6Lc8ZnQdAAAAACel4JLZYciIHoty44y__3f6t9dO';

export const getParentProfile = (params: Object): Promise<AxiosResponse<any>> =>
  axios
    .get(APIConstant.GET_CHILDREN_URL, { params })
    .then((response) => response)
    .catch((err) => {
      console.error(err);
      throw err;
    });

export const parentSignupApi = async (
  params: any,
): Promise<AxiosResponse<{ parentId: number }>> => {
  const response = await axios.post(APIConstant.PARENT_SIGN_UP_API, params);
  return response.data;
};

export const childSignupApi = async (
  params: any,
): Promise<AxiosResponse<any>> => {
  const response = await axios.post(`${APIConstant.CHILD_SIGN_UP_API}`, params);
  return response.data;
};

export const validateChildUsernameApi = async (
  params: any,
): Promise<AxiosResponse<any>> => {
  const response = await axios.post(
    `${APIConstant.CHILD_SIGN_UP_USERNAME_API}`,
    params,
  );
  return response.data;
};

export const forgotPasswordApi = async (
  params: any,
): Promise<AxiosResponse<any>> => {
  const response = await axios.post(APIConstant.FORGOT_PASSWORD_API, params);
  return response.data;
};

export const confirmPasswordApi = async (
  params: any,
): Promise<AxiosResponse<any>> => {
  const response = await axios.post(APIConstant.CONFIRM_FGPASSWORD_API, params);
  return response.data;
};

export const getParentProfileApi = async (): Promise<AxiosResponse<any>> => {
  const response = await axios.get(APIConstant.GET_PARENT_API);
  return response.data;
};

export const getChildProfileApi = async (): Promise<AxiosResponse<any>> => {
  const response = await axios.get(APIConstant.GET_CHILD_API);
  return response.data;
};

export const updateParentApi = async (
  params: any,
): Promise<AxiosResponse<any>> => {
  const response = await axios.patch(APIConstant.UPDATE_PARENT_API, params);
  return response.data;
};

export const updateKidApi = async (
  params: any,
  childId?: string,
): Promise<AxiosResponse<any>> => {
  const response = await axios.patch(
    `${APIConstant.UPDATE_KID_API}${childId}`,
    params,
  );
  return response.data;
};

export const acceptInviteApi = async (
  params: any,
): Promise<AxiosResponse<any>> => {
  const response = await axios.put(`${APIConstant.ACCEPT_INVITE_API}`, params);
  return response.data;
};

export const sendTestGiftApi = async (
  params: any,
): Promise<AxiosResponse<any>> => {
  const response = await axios.post(`does-not-exist`, params, {});
  return response.data;
};

export const assignGiftCardApi = async (
  params: any,
): Promise<AxiosResponse<any>> => {
  const response = await axios.put(APIConstant.ASSIGN_GIFT_CARD_API, params);
  return response.data;
};
