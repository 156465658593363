import React from 'react';
import type { ButtonProps, PopoverProps } from '@chakra-ui/react';
import { Box, Button, Circle } from '@chakra-ui/react';

import InfoPopover from '../InfoPopover';

interface Props {
  notificationType?: 'none' | 'number' | 'alert' | 'tooltip';
  numberOfNotifications?: number; // If notification type is number then enter the number of notifications
  popoverText?: string | React.ReactElement; // If notification type is tooltip then type in the text to be displayed on click
  children?: React.ReactNode;
  buttonProps?: ButtonProps; // Props for a regular button
  popoverProps?: PopoverProps;
}

const NotificationButton = (props: Props): React.ReactElement => {
  const NotificationBubble = () => {
    switch (props.notificationType) {
      case 'tooltip': {
        return (
          <InfoPopover
            popoverText={props.popoverText}
            questionMarkSize="md"
            popoverProps={props?.popoverProps}
            analyticsSrc="investments_button"
          />
        );
      }
      case 'alert': {
        return (
          <Circle
            bg="brand.titleHighlight"
            color="brand.grey50"
            textAlign="center"
            size="25px"
          >
            !
          </Circle>
        );
      }
      case 'number': {
        return (
          <Circle
            bg="brand.titleHighlight"
            color="brand.grey50"
            textAlign="center"
            size="25px"
            data-testid="notification-number"
          >
            {props.numberOfNotifications}
          </Circle>
        );
      }
      default: {
        return null;
      }
    }
  };

  return (
    <Box position="relative">
      {/* TODO: fix the positioning for smaller buttons */}
      <Box
        position={['absolute']}
        right="2px"
        top="-12px"
        zIndex="1"
        width="25px"
        height="25px"
      >
        <NotificationBubble />
      </Box>
      <Button {...props.buttonProps}>{props.children}</Button>
    </Box>
  );
};

export default NotificationButton;
