import React, { useRef } from 'react';
import Slider, { Settings } from 'react-slick';

import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { AchievementBadgeImage } from '../../components/AchievementBadge';
import { trackBadgeSwipe } from '../../lib/analytics';
import styles from './styles.module.scss';

interface WelcomeProps {
  isLogin?: boolean;
  className?: string;
  autoPlay?: boolean;
}

// TODO: swap this out for https://swiperjs.com/react

function WelcomeSlider({
  isLogin = false,
  className = '',
  autoPlay = false,
}: WelcomeProps): React.ReactElement {
  const sliderRef = useRef<Slider>(null);
  const sliderSettings: Settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    dotsClass: isLogin ? styles.dotClassWhite : styles.dotClass,
    className,
    onSwipe(swipeDirection: 'left' | 'right') {
      trackBadgeSwipe(swipeDirection);
    },
  };

  if (autoPlay) {
    sliderSettings.autoplay = true;
    sliderSettings.autoplaySpeed = 3000;
    sliderSettings.infinite = true;
  }

  const size = '130px';

  return (
    <Slider {...sliderSettings} ref={sliderRef}>
      <AchievementBadgeImage image="sun" size={size} />
      <AchievementBadgeImage image="start_early" size={size} />
      <AchievementBadgeImage image="plant" size={size} />
    </Slider>
  );
}

WelcomeSlider.displayName = 'WelcomeSlider';

export default WelcomeSlider;
