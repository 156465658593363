const AUTH_API = process.env.REACT_APP_WEALTHIE_AUTH_API_SERVER;
const USER_API = process.env.REACT_APP_WEALTHIE_USER_API_SERVER;
export const GET_CHILDREN_URL = '/get-children';

/**
 * Auth endpoints
 */

// post
export const PARENT_SIGN_UP_API = `${AUTH_API}/parent`;
export const FORGOT_PASSWORD_API = `${AUTH_API}/parent/forgot-password`;
export const CONFIRM_FGPASSWORD_API = `${AUTH_API}/parent/confirm-forgot-password`;

/**
 * User endpoints
 */

// get
export const CHILD_SIGN_UP_API = `${USER_API}/parent/child`;
export const CHILD_SIGN_UP_USERNAME_API = `${USER_API}/parent/child-username`;
export const GET_PARENT_API = `${USER_API}/parent`;
export const GET_CHILD_API = `${USER_API}/child`;
// patch
export const UPDATE_PARENT_API = `${USER_API}/parent`;
export const UPDATE_KID_API = `${USER_API}/child/`;
export const ACCEPT_INVITE_API = `${USER_API}/parent/accept-invite`;
export const ASSIGN_GIFT_CARD_API = `${USER_API}/parent/assign-giftcard`;
