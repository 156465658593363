import * as React from 'react';
import { createIcon } from '@chakra-ui/icons';

const AchievementBadgeIcon = createIcon({
  displayName: 'AchievementBadgeIcon',
  viewBox: '0 0 162.2 154.89',
  // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
  path: (
    <path
      fill="currentColor"
      d="M162.2,77.45c0,8-14.78,13.57-17.12,20.79-2.43,7.48,6.17,20.65,1.63,26.88s-19.79,2.16-26.08,6.74-7,20.3-14.46,22.73c-7.22,2.35-17.07-9.9-25.06-9.9S63.26,156.93,56,154.59c-7.48-2.44-8.23-18.21-14.45-22.74s-21.51-.45-26.09-6.73,4.07-19.4,1.64-26.89C14.78,91,0,85.45,0,77.45s14.78-13.58,17.12-20.8C19.55,49.17,11,36,15.49,29.77S35.28,27.61,41.57,23,48.55,2.73,56,.3C63.25-2,73.1,10.2,81.09,10.2S98.94-2,106.16.31c7.48,2.43,8.23,18.2,14.46,22.73s21.5.45,26.08,6.73-4.07,19.4-1.64,26.89C147.42,63.87,162.2,69.45,162.2,77.45Z"
    />
  ),
});

export default AchievementBadgeIcon;
