import React from 'react';
import {
  NodeContainer,
  NodeHeading,
  NodeModal,
  NodeText,
  NodeVideoPlayer,
} from '../NodeComponents';
import { NodeModalProps } from './types';

const age = 16;
const nodeNumber = 0;

const ModalA16N1: React.FunctionComponent<NodeModalProps> = ({
  isOpen,
  onClose,
}) => (
  <NodeModal isOpen={isOpen} onClose={onClose}>
    <NodeVideoPlayer
      url="/assets/timelineModalMedia/WWD-Acct-KidTimeline-Age16-Sportscar-01:23-N1-Hero.mp4"
      isGif
      ageId={age}
      nodeNumber={nodeNumber}
      index={0}
    />
    <NodeContainer>
      <NodeHeading>The Need for Speed</NodeHeading>
      <NodeText>
        You may have heard that a new car loses half its value, the second you
        drive it off the lot. No way, right? Well… depreciation is definitely a
        thing when it comes to cars. According to the <b>Canadian Black Book</b>{' '}
        (the long-time guide on car sales), in their first year, cars lose{' '}
        <b>30-40 percent</b> of their purchase price on average.
      </NodeText>
      <NodeText>
        Let&apos;s take the <b>Lamborghini Aventador,</b> for example. It sells
        for about <b>$517,000</b> (whoa), and after a year, with 4,800 km (3,000
        miles) on it, it&apos;s worth about <b>$100k less.</b>
      </NodeText>
    </NodeContainer>
    <NodeVideoPlayer
      url="/assets/timelineModalMedia/WWD-Acct-KidTimeline-Age16-PixelGlasses-01:23-N1-1.mp4"
      isGif
      ageId={age}
      nodeNumber={nodeNumber}
      index={1}
    />
    <NodeContainer>
      <NodeText>
        But wait! Some cars break all the rules. If you want to invest in an
        exclusive <b>McLaren Senna,</b> its production numbers are so limited
        that although the price tag is steep (it sells for around{' '}
        <b>a million dollars</b>), it tends to gain value the longer you have
        it. Too bad it doesn&apos;t come with a kitchen, a few bedrooms and, you
        know, a trunk with $1 million in it.
      </NodeText>
    </NodeContainer>
  </NodeModal>
);

export default ModalA16N1;
