import React from 'react';
import { createIcon } from '@chakra-ui/icons';

// using `path`
export const XIcon = createIcon({
  displayName: 'XIcon',
  viewBox: '0 0 26 25',
  defaultProps: {
    width: '26',
    height: '25',
    fill: 'none',
    xmlns: 'http://www.w3.org/2000/svg',
  },
  // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.0001 14.6217L3.06079 24.561L0.939453 22.4397L10.8788 12.5004L0.939788 2.56137L3.06111 0.440052L13.0001 10.3791L22.9392 0.439941L25.0605 2.56126L15.1214 12.5004L25.0608 22.4398L22.9395 24.5611L13.0001 14.6217Z"
      fill="#00324D"
    />
  ),
});
