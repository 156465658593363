import { Image } from '@chakra-ui/react';
import React from 'react';
import {
  NodeContainer,
  NodeHashtags,
  NodeHeading,
  NodeModal,
  NodeText,
  NodeVideoPlayer,
} from '../NodeComponents';
import { NodeModalProps } from './types';

const age = 12;
const nodeNumber = 0;

const ModalA12N1: React.FunctionComponent<NodeModalProps> = ({
  isOpen,
  onClose,
}) => (
  <NodeModal isOpen={isOpen} onClose={onClose}>
    <NodeVideoPlayer
      url="/assets/timelineModalMedia/WWD-Acct-KidTimeline-Age12-Avocado-01:23-N1-Hero.mp4"
      isGif
      ageId={age}
      nodeNumber={nodeNumber}
      index={0}
    />
    <NodeContainer>
      <NodeHeading>Avocado Accounting</NodeHeading>
      <NodeText>
        <b>Super Bowl Sunday</b> is a big day for American football. It&apos;s
        an even bigger day for avocados. Some sources estimate that on Super
        Bowl Sunday, Americans eat almost <b>200 million pounds</b> of avocado.
        Holy Guacamole!!
      </NodeText>
      <NodeText>
        Archaeologists think we&apos;ve been eating avocados since{' '}
        <b>750 BC.</b> Their pits have been found buried with Incan mummies!
      </NodeText>
    </NodeContainer>
    <Image
      src="/assets/timelineModalMedia/WWD-Acct-KidTimeline-Age12-AvocadoPits-01:23-N1-1.png"
      alt="Avocado Mummies"
    />
    <NodeContainer>
      <NodeText>
        Our fascination with the avocado is pretty historic. Over 💯 years ago,
        the New Yorker Magazine called avocados “the aristocrat of salad fruit”.
        It sure is demanding! To grow, an avocado needs fertilizer, pruning, and
        protection from pests and too much sun. They also need water. A LOT of
        water.
      </NodeText>
      <NodeText>
        It takes about 50 gallons of water (about 227 litres) to produce one
        8-ounce avocado.
      </NodeText>
    </NodeContainer>
    <Image
      src="/assets/timelineModalMedia/WWD-Acct-KidTimeline-Age12-AvocadoPits-01:23-N1-2.png"
      alt="Avocado Splash"
    />
    <NodeContainer>
      <NodeText>
        With all of the labour and care required to grow avocados, it makes
        sense that their prices might go up, right? Well, at one point a few
        years ago, avocado prices went up almost 130%. That made it harder for
        people to afford avocados, so fewer people bought them.
      </NodeText>
      <NodeText>(It was a sad time for guacamole!)</NodeText>
      <NodeText>
        But, you know that old saying, what goes up, must come down? That works
        with price, too. Eventually, there were lots of avocados for sale and
        not enough people willing to pay for them. So prices came down, and
        guess what? People bought them again.
      </NodeText>
      <NodeText>
        In economics, this is called the law of supply and demand. Demand is how
        many people want to buy something, and supply is how much of that
        something there is to buy.
      </NodeText>
    </NodeContainer>
    <Image
      src="/assets/timelineModalMedia/WWD-Acct-KidTimeline-Age12-Guacamole-01:23-N1-3.png"
      alt="Guacamole"
    />
    <NodeContainer>
      <NodeText>
        So, back to Super Bowl Sunday. Every February, as people plan their
        menus for the big game, they head out to buy avocados for their
        guacamole and their seven layer dips.
      </NodeText>
      <NodeText>
        If you know about the law of supply and demand, then you probably know
        what happens next… As demand goes up, it (usually) pulls the price of
        200 million pounds of avocados up, too!
      </NodeText>
      <NodeText>
        And voila! You&apos;ve just mastered one of the most important laws of
        economics.
      </NodeText>
    </NodeContainer>
    <Image
      src="/assets/timelineModalMedia/WWD-Acct-KidTimeline-Age12-Guacamole-01:23-N1-4.png"
      alt="Football Avocado"
    />
    <NodeContainer>
      <NodeHashtags>#economics #avocados #demand #supply</NodeHashtags>
      <NodeText>SOURCES: businessinsider.com and cnn.com</NodeText>
    </NodeContainer>
  </NodeModal>
);

export default ModalA12N1;
