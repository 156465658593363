import * as React from 'react';
import { createIcon } from '@chakra-ui/icons';

const BackIcon = createIcon({
  displayName: 'BackIcon',
  viewBox: '0 0 17 30',
  defaultProps: {
    w: '17px',
    h: '30px',
    mr: '0.25rem',
    color: 'brand.primary',
    fill: 'none',
  },
  path: (
    <>
      <path d="M15 2L3 15L15 28" stroke="#00324E" strokeWidth="3" />
    </>
  ),
});

export default BackIcon;
