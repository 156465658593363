import { ComponentStyleConfig } from '@chakra-ui/react';

const Select: ComponentStyleConfig = {
  baseStyle: {
    borderRadius: '8px',
    borderColor: 'brand.grey200',
    border: '1px solid',
    textColor: 'brand.primary',
  },
  // sizes: {
  //   lg: {
  //     field: {
  //       fontSize: 'sm',
  //     },
  //   },
  // },
  // variants: {
  //   default: {
  //     borderColor: 'brand.grey200',
  //     border: '1px solid',
  //   },
  //   enabled: {
  //     borderColor: 'brand.grey200',
  //     border: '1px solid',
  //   },
  //   error: {
  //     borderColor: 'brand.grey200',
  //     border: '1px solid',
  //   },
  // },
  defaultProps: {
    // size: 'lg',
    focusBorderColor: 'brand.titleHighlight',
    errorBorderColor: 'brand.error',
  },
};

export default Select;
