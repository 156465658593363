import React from 'react';
import Plx from 'react-plx';

import bubbleImage from '../../assets/images/timeline/bit_bubbles@3x.png';
import smallCloud from '../../assets/images/timeline/cloud@3x.png';
import cloudImage from '../../assets/images/timeline/cloud_rockets@3x.png';
import fox from '../../assets/images/timeline/sm_fox.gif';
import skyCity from '../../assets/images/timeline/sky_city@3x.png';
import bearImage from '../../assets/images/timeline/sm_polar_bear.gif';
import fishes from '../../assets/images/timeline/Fish_School_6.gif';
import jellyfish from '../../assets/images/timeline/sm_jelly_fish.gif';
import leaf from '../../assets/images/timeline/WLTH_Leaves_Loop_v001.gif';
import flipflops from '../../assets/images/timeline/flipflops@3x.png';
import sunglasses from '../../assets/images/timeline/sunglasses.png';
import flower from '../../assets/images/timeline/flower.png';
import ball from '../../assets/images/timeline/ball@3x.png';
import sun from '../../assets/images/timeline/sun@3x.png';
import rollerCoaster from '../../assets/images/timeline/RollerCoaster_AlongTrack_v001_2.gif';
import lolly1 from '../../assets/images/timeline/sm_popsicle.gif';
import discoBall from '../../assets/images/timeline/DiscoBall_Loop_600px_v001.gif';
import spaceMan from '../../assets/images/timeline/spaceman_and_planet.png';
import astroTina from '../../assets/images/timeline/Astro_Tina_TU.gif';
import neonTreat from '../../assets/images/timeline/NeonFood_Croissant.gif';
import neonMilkshake from '../../assets/images/timeline/NeonFood_Fries.gif';
import neonDonut from '../../assets/images/timeline/NeonFood_donut_v002_4.gif';
import neonCoffee from '../../assets/images/timeline/NeonFood_Coffee_v001.gif';
import skateShadow from '../../assets/images/timeline/skate_shadow@3x.png';
import skateboard from '../../assets/images/timeline/Skater_forAE.gif';
import stamp from '../../assets/images/timeline/travel_stamps.png';
import suitcase from '../../assets/images/timeline/suitcase@3x.png';
import polaroid from '../../assets/images/timeline/Polaroid_Loop_v001_1.gif';
import taxis from '../../assets/images/timeline/WLTH_Taxis_Loop_v001.gif';
import trafficLights from '../../assets/images/timeline/sm_traffic_lights.gif';
import sprayPaint from '../../assets/images/timeline/spray_paint@3x.png';
import pinkRobot from '../../assets/images/timeline/sm_pink_bot.gif';
import greenRobot from '../../assets/images/timeline/sm_green_bot.gif';
import gaming from '../../assets/images/timeline/14_gaming.png';
import tickets from '../../assets/images/timeline/tickets.png';
import headphones from '../../assets/images/timeline/headphones.png';
import cloudDreams from '../../assets/images/timeline/cloud_dreams.png';
import lightening1 from '../../assets/images/timeline/lightening1.png';
import lightening2 from '../../assets/images/timeline/lightening2.png';
import battery from '../../assets/images/timeline/battery.png';
import hats from '../../assets/images/timeline/graduation_hat@3x.png';
import cta from '../../assets/images/timeline/plus_icon.png';

import styles from './styles.module.scss';

// const plusSign = '/assets/timeline/plus@3x.png';

function buildData({ level }) {
  const data = [
    {
      start: 'self',
      duration: 2000,
      properties: [
        {
          startValue: 0,
          endValue: 0 - 100 * level * 3,
          property: 'translateY',
        },
      ],
    },
  ];
  return data;
}

function getClassName(age: number, level: number) {
  return `backgroundImageContainer_${age}_${level} backgroundImageContainer_age_${age} backgroundImageContainer_level_${level}`;
}

function backgroundImage(
  image: any,
  level: number,
  className: string,
  custom: string = '',
) {
  return (
    <img
      className={`${className} ${`backgroundImage${level}`} ${
        custom ? `${custom} ${custom}${level}` : ''
      }`}
      alt={''}
      src={image}
    />
  );
}

function ctaButton(
  level: number,
  age: number,
  index: number,
  className: string,
  onCta: (age: number, index: number, node: number) => void,
  node: number,
) {
  return (
    <img
      onClick={() => onCta(age, index, node)}
      className={`${className} plusIcon plusIcon${level} plusIcon${age}${index} ${
        index === 0 ? 'first-plus-node' : ''
      }`}
      alt={'More Information'}
      src={cta}
    />
  );
}

// function backgroundText(
//   title: string,
//   body: string,
//   level: number,
//   className: string,
// ) {
//   return (
//     <
//       className={[
//         styles.backgroundText,
//         className,
//         `backgroundText_${level}`,
//       ].join(' ')}
//     >
//       <h1>{title}</h1>
//       <p>{body}</p>
//     </>
//   );
// }

function getBackground(
  age: number,
  level: number,
  onCta: (age: number, index: number, node: number) => void,
) {
  const plusButtonCalcLevel = 0;
  let calcLevel = level;
  let backgrounds;
  let isPositionAbsolute = false;
  if (age === 0) {
    if (level === 1) {
      backgrounds = backgroundImage(
        bubbleImage,
        level,
        styles.backgroundImageBitBubble,
      );
    }
    if (level === 2) {
      backgrounds = backgroundImage(
        smallCloud,
        level,
        styles.backgroundImageSmallCloud,
      );
    }
    if (level === 3) {
      calcLevel = 0.1;
      backgrounds = backgroundImage(fox, level, styles.backgroundImageFox);
    }
    if (level === 4) {
      calcLevel = plusButtonCalcLevel;
      isPositionAbsolute = true;
      backgrounds = ctaButton(
        level,
        age,
        0,
        `${styles.plus} ${styles.plus0_1}`,
        onCta,
        1,
      );
    }
  }
  if (age === 1) {
    if (level === 1) {
      calcLevel = 1.3;
      backgrounds = backgroundImage(
        fishes,
        level,
        styles.backgroundImageFishes,
      );
    }
    if (level === 2) {
      calcLevel = 0.5;
      backgrounds = backgroundImage(
        jellyfish,
        level,
        styles.backgroundImageJellyfishes,
      );
    }
    if (level === 3) {
      calcLevel = plusButtonCalcLevel;
      isPositionAbsolute = true;
      backgrounds = (
        <>
          {ctaButton(
            level,
            age,
            0,
            `${styles.plus} ${styles.plus1_1}`,
            onCta,
            1,
          )}
          {ctaButton(
            level,
            age,
            0,
            `${styles.plus} ${styles.plus1_2}`,
            onCta,
            2,
          )}
        </>
      );
    }
  }
  if (age === 2) {
    if (level === 1) {
      backgrounds = backgroundImage(leaf, level, styles.backgroundImageLeaf);
    }
    if (level === 2) {
      calcLevel = 0.3;
      backgrounds = (
        <>
          {backgroundImage(flipflops, level, styles.backgroundImageFlipFlops)}
          {backgroundImage(sunglasses, level, styles.backgroundImageSunglasses)}
          {backgroundImage(flower, level, styles.backgroundImageFlower)}
          {backgroundImage(ball, level, styles.backgroundImageBall)}
        </>
      );
    }
    if (level === 3) {
      calcLevel = plusButtonCalcLevel;
      isPositionAbsolute = true;
      backgrounds = (
        <>
          {ctaButton(
            level,
            age,
            0,
            `${styles.plus} ${styles.plus2_1}`,
            onCta,
            1,
          )}
          {ctaButton(
            level,
            age,
            0,
            `${styles.plus} ${styles.plus2_2}`,
            onCta,
            2,
          )}
        </>
      );
    }
  }
  if (age === 3) {
    if (level === 1) {
      calcLevel = 0.1;
      backgrounds = backgroundImage(
        bearImage,
        level,
        styles.backgroundImagePolarBear,
      );
    }
    if (level === 3) {
      calcLevel = plusButtonCalcLevel;
      isPositionAbsolute = true;
      backgrounds = ctaButton(
        level,
        age,
        0,
        `${styles.plus} ${styles.plus3_1}`,
        onCta,
        1,
      );
    }
  }
  if (age === 4) {
    if (level === 1) {
      calcLevel = 0.5;
      backgrounds = backgroundImage(sun, level, styles.backgroundImageSun);
    }
    if (level === 2) {
      calcLevel = 0.6;
      backgrounds = backgroundImage(
        rollerCoaster,
        level,
        styles.backgroundImageRollerCoaster,
      );
    }
    if (level === 3) {
      calcLevel = plusButtonCalcLevel;
      isPositionAbsolute = true;
      backgrounds = ctaButton(
        level,
        age,
        0,
        `${styles.plus} ${styles.plus4_1}`,
        onCta,
        1,
      );
    }
  }

  if (age === 5) {
    if (level === 1) {
      calcLevel = 1;
      backgrounds = backgroundImage(
        lolly1,
        level,
        styles.backgroundImageLolly1,
      );
    }
    if (level === 2) {
      calcLevel = 1;
      backgrounds = backgroundImage(
        lolly1,
        level,
        styles.backgroundImageLolly2,
      );
    }
    if (level === 3) {
      calcLevel = 1;
      backgrounds = backgroundImage(
        lolly1,
        level,
        styles.backgroundImageLolly3,
      );
    }
    if (level === 4) {
      calcLevel = plusButtonCalcLevel;
      isPositionAbsolute = true;
      backgrounds = (
        <>
          {ctaButton(
            level,
            age,
            0,
            `${styles.plus} ${styles.plus5_1}`,
            onCta,
            1,
          )}
          {ctaButton(
            level,
            age,
            0,
            `${styles.plus} ${styles.plus5_2}`,
            onCta,
            2,
          )}
        </>
      );
    }
  }
  if (age === 6) {
    if (level === 1) {
      calcLevel = 0.5;
      backgrounds = backgroundImage(
        discoBall,
        level,
        styles.backgroundImageDiscoBall,
      );
    }
    if (level === 2) {
      calcLevel = 1.0;
      backgrounds = (
        <>
          {backgroundImage(pinkRobot, level, styles.backgroundImagePinkRobot)}
          {backgroundImage(greenRobot, level, styles.backgroundImageGreenRobot)}
        </>
      );
    }
    if (level === 3) {
      calcLevel = plusButtonCalcLevel;
      isPositionAbsolute = true;
      backgrounds = (
        <>
          {ctaButton(
            level,
            age,
            0,
            `${styles.plus} ${styles.plus6_1}`,
            onCta,
            1,
          )}
          {ctaButton(
            level,
            age,
            0,
            `${styles.plus} ${styles.plus6_2}`,
            onCta,
            2,
          )}
        </>
      );
    }
  }

  if (age === 7) {
    if (level === 1) {
      calcLevel = 1.2;
      backgrounds = backgroundImage(
        spaceMan,
        level,
        styles.backgroundImageSpaceMan,
      );
    }
    if (level === 2) {
      calcLevel = 1.2;
      backgrounds = backgroundImage(
        astroTina,
        level,
        styles.backgroundImageAstroTina,
      );
    }
    if (level === 3) {
      calcLevel = plusButtonCalcLevel;
      isPositionAbsolute = true;
      backgrounds = (
        <>
          {ctaButton(
            level,
            age,
            0,
            `${styles.plus} ${styles.plus7_1}`,
            onCta,
            1,
          )}
          {ctaButton(
            level,
            age,
            0,
            `${styles.plus} ${styles.plus7_2}`,
            onCta,
            2,
          )}
        </>
      );
    }
  }

  if (age === 8) {
    if (level === 1) {
      calcLevel = 1.3;
      backgrounds = backgroundImage(
        neonCoffee,
        level,
        styles.backgroundImageNeonCoffee,
      );
    }
    if (level === 2) {
      calcLevel = 0.7;
      backgrounds = backgroundImage(
        neonTreat,
        level,
        styles.backgroundImageNeonTreat,
      );
    }
    if (level === 3) {
      calcLevel = 1.0;
      backgrounds = backgroundImage(
        neonDonut,
        level,
        styles.backgroundImageNeonDonut,
      );
    }
    if (level === 4) {
      calcLevel = 0.5;
      backgrounds = backgroundImage(
        neonMilkshake,
        level,
        styles.backgroundImageNeonMilkshake,
      );
    }
    if (level === 5) {
      calcLevel = plusButtonCalcLevel;
      isPositionAbsolute = true;
      backgrounds = (
        <>
          {ctaButton(
            level,
            age,
            0,
            `${styles.plus} ${styles.plus8_1}`,
            onCta,
            1,
          )}
          {ctaButton(
            level,
            age,
            0,
            `${styles.plus} ${styles.plus8_2}`,
            onCta,
            2,
          )}
        </>
      );
    }
  }
  if (age === 9) {
    if (level === 1) {
      calcLevel = 1.5;
      backgrounds = backgroundImage(
        skateShadow,
        level,
        styles.backgroundImageSkateShadow,
      );
    }
    if (level === 2) {
      calcLevel = 0.5;
      backgrounds = backgroundImage(
        skateboard,
        level,
        styles.backgroundImageSkateboard,
      );
    }
    if (level === 3) {
      calcLevel = plusButtonCalcLevel;
      isPositionAbsolute = true;
      backgrounds = (
        <>
          {ctaButton(
            level,
            age,
            0,
            `${styles.plus} ${styles.plus9_1}`,
            onCta,
            1,
          )}
          {ctaButton(
            level,
            age,
            0,
            `${styles.plus} ${styles.plus9_2}`,
            onCta,
            2,
          )}
        </>
      );
    }
  }

  if (age === 10) {
    if (level === 1) {
      calcLevel = 1.5;
      backgrounds = backgroundImage(stamp, level, styles.backgroundImageStamp);
    }
    // if (level === 2) {
    //   calcLevel = 1.5;
    //   backgrounds = backgroundImage(
    //     stamp2,
    //     level,
    //     styles.backgroundImageStamp2,
    //   );
    // }
    if (level === 3) {
      calcLevel = 1.5;
      backgrounds = backgroundImage(
        suitcase,
        level,
        styles.backgroundImageSuitcase,
      );
    }
    if (level === 4) {
      calcLevel = plusButtonCalcLevel;
      isPositionAbsolute = true;
      backgrounds = (
        <>
          {ctaButton(
            level,
            age,
            0,
            `${styles.plus} ${styles.plus10_1}`,
            onCta,
            1,
          )}
          {ctaButton(
            level,
            age,
            0,
            `${styles.plus} ${styles.plus10_2}`,
            onCta,
            2,
          )}
        </>
      );
    }
  }

  if (age === 11) {
    if (level === 1) {
      calcLevel = 0.5;
      backgrounds = backgroundImage(
        cloudImage,
        level,
        styles.backgroundImageCloud,
      );
    }
    if (level === 2) {
      calcLevel = 2.0;
      backgrounds = backgroundImage(
        skyCity,
        level,
        styles.backgroundImageSkyCity,
      );
    }
    if (level === 3) {
      calcLevel = plusButtonCalcLevel;
      isPositionAbsolute = true;
      backgrounds = (
        <>
          {ctaButton(
            level,
            age,
            0,
            `${styles.plus} ${styles.plus11_1}`,
            onCta,
            1,
          )}
          {ctaButton(
            level,
            age,
            0,
            `${styles.plus} ${styles.plus11_2}`,
            onCta,
            2,
          )}
        </>
      );
    }
  }

  if (age === 12) {
    // if (level === 1) {
    //   calcLevel = 1.0;
    //   backgrounds = backgroundImage(
    //     lighteningBolt,
    //     level,
    //     styles.backgroundImageLighteningBolt,
    //   );
    // }
    if (level === 2) {
      calcLevel = 0.5;
      backgrounds = backgroundImage(
        trafficLights,
        level,
        styles.backgroundImageTrafficLights,
      );
    }
    if (level === 3) {
      calcLevel = 0.5;
      backgrounds = backgroundImage(taxis, level, styles.backgroundImageTaxis);
    }
    if (level === 4) {
      calcLevel = plusButtonCalcLevel;
      isPositionAbsolute = true;
      backgrounds = ctaButton(
        level,
        age,
        0,
        `${styles.plus} ${styles.plus12_1}`,
        onCta,
        1,
      );
    }
  }

  if (age === 13) {
    // if (level === 2) {
    //   calcLevel = 1.0;
    //   backgrounds = backgroundText(
    //     'The Title Text Section',
    //     'The body text that is longer than the title. The body text than the title. The body text that is longer longer than longer than than the title.',
    //     level,
    //     styles.backgroundImageLighteningBolt,
    //   );
    // }
    if (level === 1) {
      calcLevel = 0.5;
      backgrounds = backgroundImage(
        polaroid,
        level,
        styles.backgroundImagePolaroid,
      );
    }
    if (level === 2) {
      calcLevel = plusButtonCalcLevel;
      isPositionAbsolute = true;
      backgrounds = ctaButton(
        level,
        age,
        0,
        `${styles.plus} ${styles.plus13_1}`,
        onCta,
        1,
      );
    }
  }

  if (age === 14) {
    if (level === 1) {
      calcLevel = 0.5;
      backgrounds = backgroundImage(
        gaming,
        level,
        styles.backgroundImageGaming,
      );
    }
    if (level === 2) {
      calcLevel = plusButtonCalcLevel;
      isPositionAbsolute = true;
      backgrounds = (
        <>
          {ctaButton(
            level,
            age,
            0,
            `${styles.plus} ${styles.plus14_1}`,
            onCta,
            1,
          )}
          {ctaButton(
            level,
            age,
            0,
            `${styles.plus} ${styles.plus14_2}`,
            onCta,
            2,
          )}
        </>
      );
    }
  }

  if (age === 15) {
    if (level === 1) {
      calcLevel = 0.5;
      backgrounds = backgroundImage(
        tickets,
        level,
        styles.backgroundImageTickets,
      );
    }
    if (level === 2) {
      calcLevel = 0.1;
      backgrounds = backgroundImage(
        headphones,
        level,
        styles.backgroundImageHeadphones,
      );
    }
    if (level === 3) {
      calcLevel = plusButtonCalcLevel;
      isPositionAbsolute = true;
      backgrounds = (
        <>
          {ctaButton(
            level,
            age,
            0,
            `${styles.plus} ${styles.plus15_1}`,
            onCta,
            1,
          )}
          {ctaButton(
            level,
            age,
            0,
            `${styles.plus} ${styles.plus15_2}`,
            onCta,
            2,
          )}
        </>
      );
    }

    // if (level === 2) {
    //   calcLevel = 1.0;
    //   backgrounds = backgroundText(
    //     'The Title Text Section (Age 15)',
    //     'The body text that is longer than the title. The body text than the title.',
    //     level,
    //     styles.backgroundImageLighteningBolt,
    //   );
    // }
    // if (level === 1) {
    //   calcLevel = 0.1;
    //   backgrounds = backgroundImage(
    //     lighteningBolt,
    //     level,
    //     styles.backgroundImageLighteningBoltLarge,
    //   );
    // }
  }

  if (age === 16) {
    // if (level === 2) {
    //   calcLevel = 1.0;
    //   backgrounds = backgroundText(
    //     'The Title Text Section (Age 16)',
    //     'The body text that is longer than the title. The body text than the title.',
    //     level,
    //     styles.backgroundImageLighteningBolt,
    //   );
    // }
    if (level === 1) {
      calcLevel = 1.2;
      backgrounds = backgroundImage(
        cloudDreams,
        level,
        styles.backgroundImageCloudDreams,
      );
    }
    if (level === 2) {
      calcLevel = 1.5;
      backgrounds = backgroundImage(
        sprayPaint,
        level,
        styles.backgroundImageSprayPaint,
      );
    }
    if (level === 3) {
      calcLevel = plusButtonCalcLevel;
      isPositionAbsolute = true;
      backgrounds = (
        <>
          {ctaButton(
            level,
            age,
            0,
            `${styles.plus} ${styles.plus16_1}`,
            onCta,
            1,
          )}
          {ctaButton(
            level,
            age,
            0,
            `${styles.plus} ${styles.plus16_2}`,
            onCta,
            2,
          )}
        </>
      );
    }
  }

  if (age === 17) {
    // if (level === 2) {
    //   calcLevel = 1.0;
    //   backgrounds = backgroundText(
    //     'The Title Text Section (Age 17)',
    //     'The body text that is longer than the title. The body text than the title.',
    //     level,
    //     styles.backgroundImageLighteningBolt,
    //   );
    // }
    if (level === 1) {
      calcLevel = 1.0;
      backgrounds = backgroundImage(
        lightening1,
        level,
        styles.backgroundImageLighteningBolt1,
      );
    }
    if (level === 2) {
      calcLevel = 0.75;
      backgrounds = backgroundImage(
        lightening2,
        level,
        styles.backgroundImageLighteningBolt2,
      );
    }
    if (level === 3) {
      calcLevel = 0.5;
      backgrounds = backgroundImage(
        battery,
        level,
        styles.backgroundImageBattery,
      );
    }
    if (level === 4) {
      calcLevel = plusButtonCalcLevel;
      isPositionAbsolute = true;
      backgrounds = (
        <>
          {ctaButton(
            level,
            age,
            0,
            `${styles.plus} ${styles.plus17_1}`,
            onCta,
            1,
          )}
          {ctaButton(
            level,
            age,
            0,
            `${styles.plus} ${styles.plus17_2}`,
            onCta,
            2,
          )}
        </>
      );
    }
  }

  if (age === 18) {
    // if (level === 2) {
    //   calcLevel = 1.0;
    //   backgrounds = backgroundText(
    //     'The Title Text Section (Age 18)',
    //     'The body text that is longer than the title. The body text than the title.',
    //     level,
    //     styles.backgroundImageLighteningBolt,
    //   );
    // }
    if (level === 1) {
      calcLevel = 0.1;
      backgrounds = backgroundImage(hats, level, styles.backgroundImageHats);
    }
    if (level === 2) {
      calcLevel = plusButtonCalcLevel;
      isPositionAbsolute = true;
      backgrounds = (
        <>
          {ctaButton(
            level,
            age,
            0,
            `${styles.plus} ${styles.plus18_1}`,
            onCta,
            1,
          )}
          {ctaButton(
            level,
            age,
            0,
            `${styles.plus} ${styles.plus18_2}`,
            onCta,
            2,
          )}
        </>
      );
    }
  }

  if (backgrounds) {
    return (
      <Plx
        className={`${
          isPositionAbsolute
            ? styles.backgroundImagePlxPlus
            : styles.backgroundImagePlx
        } ${getClassName(age, level)}`}
        parallaxData={buildData({ level: calcLevel })}
      >
        {backgrounds}
      </Plx>
    );
  }
  return null;
}

function BackgroundLayer({
  age,
  level,
  onCta,
}: {
  age: number;
  level: number;
  onCta: (age: number, index: number, node: number) => void;
}): React.ReactElement {
  return <>{getBackground(age, level, onCta)}</>;
}

export default BackgroundLayer;
