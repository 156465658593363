import React, { useEffect, useState } from 'react';
import { Image } from '@chakra-ui/react';

interface GiftCardImageProps {
  giftCardImageSrc: string;
  size: 'lg' | 'sm' | 'custom';
  width?: string;
  height?: string;
  radius?: string;
  borderPixels?: string;
  isRotated?: boolean;
}

const GiftCardImage = ({
  giftCardImageSrc,
  size,
  width,
  height,
  radius,
  borderPixels,
  isRotated,
}: GiftCardImageProps): React.ReactElement => {
  const [defaultWidth, setDefaultWidth] = useState<string>('');
  const [defaultHeight, setDefaultHeight] = useState<string>('');
  const [defaultRadius, setDefaultRadius] = useState<string>('');
  const [defaultBorderPixels, setDefaultBorderPixels] = useState<string>('');

  useEffect(() => {
    switch (size) {
      case 'lg': {
        setDefaultWidth('280px');
        setDefaultHeight('176.09px');
        setDefaultRadius('26px');
        setDefaultBorderPixels('4px');
        break;
      }
      case 'sm': {
        setDefaultWidth('112px');
        setDefaultHeight('71px');
        setDefaultRadius('13px');
        setDefaultBorderPixels('2.5px');
        break;
      }
      default: {
        if (width && height && radius && borderPixels) {
          setDefaultWidth(width);
          setDefaultHeight(height);
          setDefaultRadius(radius);
          setDefaultBorderPixels(borderPixels);
        }
        break;
      }
    }
  }, [width, height, radius, borderPixels, size]);

  return (
    <Image
      src={giftCardImageSrc}
      alt="Gift Card"
      w={defaultWidth}
      h={defaultHeight}
      borderRadius={defaultRadius}
      transform={isRotated ? 'rotate(-2.93deg)' : 'none'}
      boxShadow=" 0px 2.59px 2.16228px 1.29737px var(--chakra-colors-brand-shadow)"
      border={`${defaultBorderPixels} solid var(--chakra-colors-brand-primaryBackground)`}
      willChange="transform"
      // boxSizing="border-box"
    />
  );
};

export default GiftCardImage;
