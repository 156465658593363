import React from 'react';
import { Image } from '@chakra-ui/react';

import {
  NodeContainer,
  NodeHeading,
  NodeModal,
  NodeText,
  NodeVideoPlayer,
} from '../NodeComponents';
import { NodeModalProps } from './types';

const age = 18;
const nodeNumber = 0;

const ModalA18N2: React.FunctionComponent<NodeModalProps> = ({
  isOpen,
  onClose,
}) => (
  <NodeModal isOpen={isOpen} onClose={onClose}>
    <NodeVideoPlayer
      url="/assets/timelineModalMedia/WWD-Acct-KidTimeline-Age18-RuleOf72-01:23-N2-Hero.mp4"
      isGif
      ageId={age}
      nodeNumber={nodeNumber}
      index={0}
    />
    <NodeContainer>
      <NodeHeading>Rule of 72</NodeHeading>
      <NodeText>
        Okay. If you&apos;ve been saving your money since you launched your
        first lemonade stand, we seriously applaud you. But we also want to help
        you get serious, so check this: saving money is a good way to grow your
        savings, and a terrible way to grow your money.
      </NodeText>
    </NodeContainer>
    <Image
      src="/assets/timelineModalMedia/WWD-Acct-KidTimeline-Age18-PixelPig-01:23-N2-1.png"
      alt="Pixel Piggy Bank"
    />
    <NodeContainer>
      <NodeText>
        Here&apos;s what we mean. Saving money helps you keep what you have. But
        over time, the purchasing power of your money declines, because
        inflation pushes prices up. So your dollar today buys a little less
        tomorrow (or, really, a year from now).
      </NodeText>
    </NodeContainer>
    <Image
      src="/assets/timelineModalMedia/WWD-Acct-KidTimeline-Age18-PixelCoin-01:23-N2-2.png"
      alt="Pixel Coins"
    />
    <NodeContainer>
      <NodeText>
        No problem. If you want your money to keep pace, you&apos;re going to
        have to grow it, and one of the fastest ways to do that over time is by
        investing. So, what can investing really do for you? Well, let&apos;s
        start by doubling your money.
      </NodeText>
      <NodeText>
        Drumroll, pls. The Rule of 72 is a pretty simple way to figure out how
        long it will take your money to double. Here&apos;s the equation:
      </NodeText>
      <NodeText>72 / annual interest rate</NodeText>
      <NodeText>
        Start with 72 and divide by your anticipated annual interest rate. Over
        the last 30+ years, the average annualized return for the markets has
        been about 7.3%.
      </NodeText>
      <NodeText>So, 72/7.3 = 9.86 years.</NodeText>
    </NodeContainer>
    <Image
      src="/assets/timelineModalMedia/WWD-Acct-KidTimeline-Age18-PixelWatch-01:23-N2-3.png"
      alt="Pixel Watches"
    />
    <NodeContainer>
      <NodeText>
        It should take less than 10 years for your money to double, so if
        you&apos;ve been thinking about cashing out your money, spend some time
        thinking about yourself 10 years ago first. What are some of the things
        that Future You would like to do with double your money?
      </NodeText>
    </NodeContainer>
  </NodeModal>
);

export default ModalA18N2;
