import { Image } from '@chakra-ui/react';
import React from 'react';
import {
  NodeContainer,
  NodeHashtags,
  NodeHeading,
  NodeModal,
  NodeText,
  NodeVideoPlayer,
} from '../NodeComponents';
import { NodeModalProps } from './types';

const age = 11;
const nodeNumber = 0;

const ModalA11N1: React.FunctionComponent<NodeModalProps> = ({
  isOpen,
  onClose,
}) => (
  <NodeModal isOpen={isOpen} onClose={onClose}>
    <Image
      src="/assets/timelineModalMedia/WWD-Acct-KidTimeline-Age11-MunchingMars-01:23-N1-Hero.png"
      alt="Munching on Mars"
    />
    <NodeContainer>
      <NodeHeading>Mars Bars</NodeHeading>
      <NodeText>
        There&apos;s been a lot of talk lately about flying to Mars. Sounds good
        to us! NASA says getting there will take about <b>three years</b>.
        Imagine how much food has to be packed for <b>four astronauts</b> for
        three years. The space shuttle allows just under{' '}
        <b>2 kg (just under 4 lbs) </b>
        of food per astronaut per day.
      </NodeText>
      <NodeText>
        That&apos;s <b>8,760 kg (over 19,000 lbs)</b> for our astros. We&apos;re
        gonna need a bigger ship.
      </NodeText>
    </NodeContainer>
    <Image
      src="/assets/timelineModalMedia/WWD-Acct-KidTimeline-Age11-MunchingMars-01:23-N1-1.png"
      alt="Rocket Ships"
    />
    <NodeContainer>
      <NodeText>
        How much will this cost? When you factor in everything from the special
        packaging and the processing needed to make food space ready, estimates
        range from about $4,000 to $10,000 PER POUND OF FOOD!!!
      </NodeText>
      <NodeText>
        Whoa. Okay. Using an average of $7000 per pound, this rocket restaurant
        bill sits at <b>$133 MILLION.</b>
      </NodeText>
      <NodeText>
        Now double it. We don&apos;t hear much about the Whole Foods on Mars,
        and those astronauts will need food for the ride home!
      </NodeText>
    </NodeContainer>
    <NodeVideoPlayer
      url="/assets/timelineModalMedia/WWD-Acct-KidTimeline-Age11-MunchingMars-01:23-N1-2.mp4"
      isGif
      ageId={age}
      nodeNumber={nodeNumber}
      index={0}
    />
    <NodeContainer>
      <NodeHashtags>#spacebucks</NodeHashtags>
    </NodeContainer>
  </NodeModal>
);

export default ModalA11N1;
